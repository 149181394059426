import React from 'react';
import { CModal, CModalBody, CModalHeader, CModalFooter } from '@coreui/react-pro';
import { I18n } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';
import PropTypes from 'prop-types';

import UiLoading from './UiLoading';
import UiButton from './UiButton';
import UiCheckbox from './UiCheckbox';
import { useState, useRef, useEffect } from 'react';


const propTypes = {
  valid: PropTypes.bool,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  creation: PropTypes.bool,
  onCancel: PropTypes.func,
  koLabel: PropTypes.string,
  okLabel: PropTypes.string,
  ignoreEnter: PropTypes.bool,
  className: PropTypes.string,
  loadingModal: PropTypes.bool,
  errorsModal: PropTypes.array,
  checkLeaveOpen: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

function UiModal(props) {

  const [valid, setValid] = useState();
  const [modalId, setModalId] = useState(`${Date.now()}-${Math.floor(Math.random() * 1000)}`);

  let modalBodyRef = useRef();

  useEffect(() => {
    if (modalBodyRef.current) modalBodyRef.current.scrollTop = 0;
    setValid(props.valid);
  }, [props])

  function handleSubmit(event) {
    if (props.onSubmit) {
      props.onSubmit(event);
    }
  }

  function onKeyUp(event) {
    switch (event.code) {
      case 'Enter':
        if (!disabled && !props.ignoreEnter) {
          // setState({ disabled: true });
          setDisabled(true);
          props.onSubmit(event);
        }
        break;
      default:
        break;
    }
  }

  function checkLeaveOpen() {
    if (props.checkLeaveOpen) {
      return props.checkLeaveOpen;
    }
  }

  function handleOnCancel() {
    if (props.onCancel) {
      props.onCancel();
    }
  }

  return (
    <I18n ns="translations">
      {t => (
        <div className="animated fadeIn" onKeyUp={onKeyUp.bind(this)}>
          <CModal className={`UiModal_Id-${modalId} ${props.className}`} visible={props.isOpen} onClose={() => props.isOpen ? props.onCancel() : null}>
            <CModalHeader tag="h5">
              {props.title ? t(props.title) : t('Common.add_item')}
            </CModalHeader>
            <CModalBody>
              {props.loadingModal && <UiLoading />}
              {!props.loadingModal && (
                <div ref={modalBodyRef}>
                  {!!props.errorsModal && map(props.errorsModal, (key, index) => (
                    <div className="bg-danger theme-color textAlignCenter" key={`modal-${props.name}-${index}`}>
                      {key}
                    </div>
                  ))}
                  {props.children}
                </div>
              )}
            </CModalBody>
            <CModalFooter>
              <div className="flexComponent">
                {props.checkLeaveOpen && (
                  <UiCheckbox name="leaveOpen" label={t('Common.leave_open')}
                    value={props.leaveOpen} onChange={checkLeaveOpen.bind(this)} />
                )}
              </div>
              <div className="flexComponentNoGrow">
                {!props.loadingModal && (
                  <div className="ButtonsContainer">
                    <UiButton color="primary" icon="cis-check" label={props.okLabel ? t(props.okLabel) : props.creation ? t('Common.create') : t('Common.ok')}
                      disabled={props.valid === false}
                      onClick={handleSubmit.bind(this)} />
                    {props.onCancel && (
                      <UiButton color="primary" icon="cis-x" label={props.koLabel ? t(props.koLabel) : t('Common.cancel')}
                        onClick={handleOnCancel.bind(this)} />
                    )}
                  </div>
                )}
              </div>
            </CModalFooter>
          </CModal>
        </div>
      )}
    </I18n>
  );

}

UiModal.propTypes = propTypes;
export default UiModal;
