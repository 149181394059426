import AbstractService from './abstractService';
import camelCase from 'lodash/camelCase';
import kebabCase from 'lodash/kebabCase';
import snakeCase from 'lodash/snakeCase';
import ConstantsService from './constantsService';

export default class BeijeService extends AbstractService {

  constructor(store, baseUrl, listName, itemName) {
    super(store, baseUrl);
    this.listName = (listName) ? listName : 'defaults';
    this.urlPath = (process.env.API_URL_FORMAT === 'kebabCase') ? kebabCase(this.listName) : (process.env.API_URL_FORMAT === 'snakeCase') ? snakeCase(this.listName) : (process.env.API_URL_FORMAT === 'camelCase') ? camelCase(this.listName) : this.listName;
    this.itemName = (itemName) ? itemName : 'default';
  }

  init(itemName) {
    super.initApiActions((itemName) ? itemName : this.itemName);
  }

  initList(listName) {
    super.initListApiActions((listName) ? listName : this.listName);
  }

  getList(obj, lang, token, okCallBack, koCallBack) {
    return super.get(this.listName, this.urlPath, lang, token, obj, okCallBack, koCallBack);
  }
  
  getRelatedList(obj, lang, token, okCallBack, koCallBack) {
    let moduleName = (obj.reducer_name) ? obj.reducer_name : this.listName + '_related';
    return super.get(moduleName, this.urlPath, lang, token, obj, okCallBack, koCallBack);
  }

  getFirstItemFromList(obj, defaultDBIdentifier, lang, token, okCallBack, koCallBack) {
    const okCbc = (res) => {
      this.getItem(res.data[0][defaultDBIdentifier], lang, token, okCallBack, koCallBack);
    };
    return super.getList(obj, lang, token, okCbc, koCallBack);
  }

  getItem(contentId, lang, token, okCallBack, koCallBack, firstError) {
    return super.get(this.itemName, this.urlPath + '/' + contentId, lang, token, null, okCallBack, koCallBack, firstError);
  }

  addItem(obj, lang, token, okCallBack, koCallBack) {
    return super.post(this.itemName, this.urlPath, lang, token, obj, okCallBack, koCallBack);
  }

  updateItem(obj, lang, token, okCallBack, koCallBack) {
    function updateKoCallBack(error) {
      this.getItem(obj[ConstantsService.defaultDBIdentifier], lang, token, koCallBack, koCallBack, error);
    }
    return super.put(this.itemName, this.urlPath + '/' + obj[ConstantsService.defaultDBIdentifier], lang, token, obj, okCallBack, updateKoCallBack.bind(this));
  }

  updateItemCustomId(contentId, obj, lang, token, okCallBack, koCallBack) {
    function updateKoCallBack(error) {
      this.getItem(contentId, lang, token, koCallBack, koCallBack, error);
    }
    return super.put(this.itemName, this.urlPath + '/' + contentId, lang, token, obj, okCallBack, updateKoCallBack.bind(this));
  }

  deleteItem(contentId, lang, token, okCallBack, koCallBack) {
    function deleteKoCallBack(error) {
      this.getItem(contentId, lang, token, koCallBack, koCallBack, error);
    }
    return super.delete(this.itemName, this.urlPath + '/' + contentId, lang, token, okCallBack, deleteKoCallBack.bind(this));
  }

  getRelation(contentId, filter, relationName, lang, token, okCallBack, koCallBack) {
    return super.get(this.itemName + '-' + relationName, this.urlPath + '/' + contentId + '/' + relationName + '/relation', lang, token, filter, okCallBack, koCallBack);
  }

  getSingleRelation(contentId, relationName, relationId, lang, token, okCallBack, koCallBack) {
    return super.get(this.itemName + '-' + relationName, this.urlPath + '/' + contentId + '/' + relationName + '/' + relationId, lang, token, null, okCallBack, koCallBack);
  }

  addSingleRelation(contentId, relationName, relationId, obj, lang, token, okCallBack, koCallBack) {
    return super.post(this.itemName + '-' + relationName, this.urlPath + '/' + contentId + '/' + relationName + '/' + relationId, lang, token, obj, okCallBack, koCallBack);
  }

  updateSingleRelation(contentId, relationName, relationId, body, lang, token, okCallBack, koCallBack) {
    return super.put(this.itemName + '-' + relationName, this.urlPath + '/' + contentId + '/' + relationName + '/' + relationId, lang, token, body, okCallBack, koCallBack);
  }

  deleteSingleRelation(contentId, relationName, relationId, lang, token, okCallBack, koCallBack) {
    return super.delete(this.itemName + '-' + relationName, this.urlPath + '/' + contentId + '/' + relationName + '/' + relationId, lang, token, okCallBack, koCallBack);
  }

  addItemWithFile(obj, singlefileContainerName, lang, token, okCallBack, koCallBack, progressCallback) {
    return super.postMultipart(this.itemName, this.urlPath, lang, token, obj, singlefileContainerName, okCallBack, koCallBack, progressCallback);
  }

  updateItemWithFile(contentId, obj, singlefileContainerName, lang, token, okCallBack, koCallBack, progressCallback) {
    return super.postMultipart(this.itemName, this.urlPath + '/' + contentId, lang, token, obj, singlefileContainerName, okCallBack, koCallBack, progressCallback);
  }

  export(obj, lang, token, okCallBack, koCallBack) {
    return super.post('export', '/export', lang, token, obj, okCallBack, koCallBack);
  }

  import(obj, lang, token, okCallBack, koCallBack) {
    return super.postMultipart('import', obj.target + '/import_xls', lang, token, obj, 'import_file', okCallBack, koCallBack);
  }

  getImportTemplate(lang, token, okCallBack, koCallBack) {
    return super.get('import_templates_links', '/import_templates_links', lang, token, null, okCallBack, koCallBack);
  }
}