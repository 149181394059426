import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18next';

//UI
import { CBadge, CDropdown, CProgress, CDropdownItem, CDropdownMenu, CDropdownToggle, CDropdownHeader, CAvatar } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';

//COMPONENTS
import UILanguageDropdown from '../UILanguageDropdown';

//SERVICES
import ConstantsService from '../../../services/constantsService';

//TYPES
import PropTypes from 'prop-types';
import { userType } from '../../../types';

const propTypes = {
  notif: PropTypes.bool,
  langs: PropTypes.bool,
  accnt: PropTypes.bool,
  currentUser: userType,
};

const defaultProps = {
  notif: false,
  langs: false,
  accnt: false,
  tasks: false,
  mssgs: false,
};

function DefaultHeaderDropdown(props) {

  const { notif, accnt, langs } = props;
  const [username, setUsername] = useState()
  const [avatar, setAvatar] = useState()

  //this useEffect simulate getDerivedStateFromProps
  useEffect(() => {
    setUsername(props.currentUser.username);
    setAvatar(props.currentUser.userImagePath);
  }, [props])

  function dropNotif() {
    const itemsCount = 5;
    return (
      <CDropdown variant="nav-item" className="mx-2">
        <CDropdownToggle className="header-nav-link" caret={false}>
          <CIcon icon="cis-bell" />
          <CBadge shape="pill" color="danger">
            {itemsCount}
          </CBadge>
        </CDropdownToggle>

        <CDropdownMenu placement="bottom-end" className="pt-0">
          <CDropdownHeader tag="div" className="text-center" color="light">
            <strong>You have {itemsCount} notifications</strong>
          </CDropdownHeader>
          <CDropdownItem>
            <CIcon icon="cis-user-follow" className="me-2 text-success" /> New user registered
          </CDropdownItem>
          <CDropdownItem>
            <CIcon icon="cis-user-unfollow" className="me-2 text-danger" /> User deleted
          </CDropdownItem>
          <CDropdownItem>
            <CIcon icon="cis-chart-pie" className="me-2 text-info" /> Sales report is ready
          </CDropdownItem>
          <CDropdownItem>
            <CIcon icon="cis-basket" className="me-2 text-primary" /> New client
          </CDropdownItem>
          <CDropdownItem>
            <CIcon icon="cis-speedometer" className="me-2 text-warning" /> Server overloaded
          </CDropdownItem>
          <CDropdownHeader tag="div" color="light">
            <strong>Server</strong>
          </CDropdownHeader>
          <CDropdownItem className="d-block">
            <div className="text-uppercase mb-1">
              <small>
                <b>CPU Usage</b>
              </small>
            </div>
            <CProgress size="xs" color="info" value={25} />
            <small className="text-muted">348 Processes. 1/4 Cores.</small>
          </CDropdownItem>
          <CDropdownItem className="d-block">
            <div className="text-uppercase mb-1">
              <small>
                <b>Memory Usage</b>
              </small>
            </div>
            <CProgress size="xs" color="warning" value={70} />
            <small className="text-muted">11444GB/16384MB</small>
          </CDropdownItem>
          <CDropdownItem className="d-block">
            <div className="text-uppercase mb-1">
              <small>
                <b>SSD 1 Usage</b>
              </small>
            </div>
            <CProgress size="xs" color="danger" value={90} />
            <small className="text-muted">243GB/256GB</small>
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    );
  }

  function dropLangs() {
    return (
      <UILanguageDropdown languages={ConstantsService.iso6933LangList} currentLanguage={props.currentLanguage} changeLanguageCallback={props.changeLanguage} />
    );
  }


  function dropAccnt() {
    return (
      <CDropdown variant="nav-item">
        <CDropdownToggle caret={false} component="span" className="me-2">
          {
            !!avatar ?
              <CAvatar className="me-2" src={process.env.API_PATH + avatar} />
              :
              <>
                <CIcon icon="cis-user" className="me-2" /> {username}
              </>
          }
        </CDropdownToggle>
        <CDropdownMenu>
          <CDropdownItem disabled={props.isBlocking} component={Link} to={`/user/auth`} >
            <CIcon icon="cis-user" className="me-2" /> {i18n.t('Common.profile')}
          </CDropdownItem>
          <CDropdownItem disabled={props.isBlocking} component={Link} to="/login?logout=true">
            <CIcon icon="cis-account-logout" className="me-2" /> {i18n.t('Login.logout')}
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    );
  }

  return notif ? dropNotif() : accnt ? dropAccnt() : langs ? dropLangs() : null;
}

DefaultHeaderDropdown.propTypes = propTypes;
DefaultHeaderDropdown.defaultProps = defaultProps;

export default DefaultHeaderDropdown;
