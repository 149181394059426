import BaseService from './baseService';

export default class UserProjectsService extends BaseService {
  static instance = null;

  static getInstance(store) {
    if (!UserProjectsService.instance) {
      UserProjectsService.instance = new UserProjectsService(store);
    }
    return UserProjectsService.instance;
  }

  constructor(store) {
    const baseUrl = process.env.API_PATH;
    super(store, baseUrl, 'user_projects', 'user_project');
    this.urlPath = "projects";
  }

  getList(obj, lang, token, okCallBack, koCallBack) {
    return super.get(this.listName, this.urlPath + "/auth/user", lang, token, obj, okCallBack, koCallBack);
  }
}