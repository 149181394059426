import BaseService from './baseService';

export default class RolesService extends BaseService {
    static instance = null;

    static getInstance(store) {
        if (!RolesService.instance) {
            RolesService.instance = new RolesService(store);
        }
        return RolesService.instance;
    }

    constructor(store) {
        const baseUrl = process.env.API_PATH;
        super(store, baseUrl, 'roles', 'role');

        this.urlPath = "user-roles";
    }
}
