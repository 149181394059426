import BaseService from './baseService';

export default class ProductsService extends BaseService {
  static instance = null;

  static getInstance(store) {
    if (!ProductsService.instance) {
      ProductsService.instance = new ProductsService(store);
    }
    return ProductsService.instance;
  }

  constructor(store) {
    const baseUrl = process.env.API_PATH;
    super(store, baseUrl, 'products', 'product');
  }
}
