import React, { useState, useEffect } from 'react';
import { I18n } from 'react-i18next';
import i18n from 'i18next';
import moment from 'moment';
import { CDatePicker } from '@coreui/react-pro';


//UI
import { CFormLabel } from '@coreui/react-pro';

//TYPES
import PropTypes from 'prop-types';

//STYLE
import "react-datepicker/dist/react-datepicker.css";

const propTypes = {
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  isOutsideRange: PropTypes.bool,
  size: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  minDate: PropTypes.string,
  disabled: PropTypes.bool,
  cleaner: PropTypes.bool
};

function UiDatePicker(props) {
  const [invalid, setInvalid] = useState(false);
  const [today, setToday] = useState();
  const [date, setDate] = useState(!!props.value ? props.value.split('T')[0] : undefined);
  const [disabled, setDisabled] = useState();
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    const newDate = !!props.value ? props.value.split('T')[0] : undefined;
    if (newDate !== date) {
      setDate(newDate);
    }

    setToday(moment.utc().toDate());
    setDisabled(!!props.disabled && (`${props.disabled}` === 'true'));
  }, [props])

  function handleOnChange(date) {
    if (!disabled) {
      const invalid = props.required ? (focused ? false : (!date)) : undefined;
      const event = {
        target: {
          name: props.name,
          value: (date && moment(date).isValid) ? moment.utc(date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()).toISOString() : null,
        },
      };
      setInvalid(invalid);

      if (props.onChange) props.onChange(event);

      if (props.onBlur) {
        setTimeout(() => {
          props.onBlur(event);
        }, 300);
      }
    }
  }

  function handleOnFocus(evt) {
    if (!disabled) {
      if (props.onFocus) props.onFocus(evt);
    }
  }

  useEffect(() => { moment.locale(i18n.language) }, [props])

  return (
    <I18n ns="translations">
      {t => (
        <div className={`UiDatePicker${invalid ? ' is-invalid' : ''}`}>
          {props.label ? (
            <CFormLabel htmlFor={props.name} className="UiMainLabel">
              {t(props.label)} {props.required ? '*' : ''}
            </CFormLabel>
          ) : (
            ''
          )}
          <div id={props.name} className={`form-control UiInputGroup input-group mutipleInputContainer ${focused ? 'UiInput_is_focused' : ''}`}>
            <CDatePicker size={!!props.size ? props.size : "sm"} style={{ width: '100%' }} placeholder={t('Common.select_date')}
              disabled={disabled} date={(date) ? date : (props.default) ? today : undefined} direction='center'
              onFocus={handleOnFocus.bind(this)} onDateChange={handleOnChange.bind(this)} minDate={props.minDate} cleaner={props.cleaner} />
          </div>
          {invalid && (
            <div className="invalid-feedback">{t('Common.field_invalid')}</div>
          )}
        </div>
      )}
    </I18n>
  );
}

UiDatePicker.propTypes = propTypes;

export default UiDatePicker;
