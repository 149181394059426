import AdvancedSearchBeijeService from './advancedSearchBeijeService';
import ObjectsUtils from '../utils/objectsUtils';
import cloneDeep from 'lodash/cloneDeep';

export default class BaseService extends AdvancedSearchBeijeService {
  
  updateItemCustomId(contentId, obj, lang, token, okCallBack, koCallBack) {
    let body = cloneDeep(obj);
    
    if (body && Object.keys(body).length > 0){
      for(const [key, value] of Object.entries(body)){
        if (ObjectsUtils.isArrayValid(value) && value.find(obj => obj.fakeId === true)){
          for (let subvalue of value){
            if(subvalue.fakeId === true){
              delete(subvalue.child_id);
            }
            delete(subvalue.fakeId);
          }
        }
      }
    }
    
    return super.updateItemCustomId(contentId, body, lang, token, okCallBack, koCallBack);
  }
  
  uploadMedia(contentId, newMedia, lang, token, okCallBack, koCallBack, progressCallback) {
    if (newMedia.child_id){
      return super.postMultipart(this.itemName, this.urlPath+'/'+contentId+'/multimedia/'+newMedia.child_id, lang, token, newMedia, 'logo', okCallBack, koCallBack, progressCallback);
    }
    return super.postMultipart(this.itemName, this.urlPath+'/'+contentId+'/multimedia', lang, token, newMedia, 'logo', okCallBack, koCallBack, progressCallback);
  }

  uploadFiles(contentId, newMedia, lang, token, okCallBack, koCallBack, progressCallback) {
    if (newMedia.child_id){
      return super.postMultipart(this.itemName, 'material', lang, token, newMedia, 'file', okCallBack, koCallBack, progressCallback);
    }
    return super.postMultipart(this.itemName, 'material', lang, token, newMedia, 'file', okCallBack, koCallBack, progressCallback);
  }

  updateMediaData(contentId, media, lang, token, okCallBack, koCallBack) {
    return super.put(this.itemName+'_multimedia', this.urlPath+'/'+contentId+'/multimedia/'+media.child_id, lang, token, media, okCallBack, koCallBack);
  }

  deleteMedia(contentId, mediaId, lang, token, okCallBack, koCallBack) {
    return super.delete(this.itemName+'_multimedia', this.urlPath+'/'+contentId+'/multimedia', lang, token, okCallBack, koCallBack);
  }

  getImages(contentId, obj, lang, token, okCallBack, koCallBack) {
    return super.get(this.itemName+'_images', `${this.urlPath}/${contentId}/site-images`, lang, token, obj, okCallBack, koCallBack);
  }

  addImage(contentId, obj, image, lang, token, okCallBack, koCallBack, progressCallback) {
    const newImage = {
      name: image.image_name,
      id: contentId,
      file: image.image,
      description: image.image_name,
      type: obj.type,
    };
    return super.postMultipart(this.itemName+'_images', `${this.urlPath}/${contentId}/site-images-s3`, lang, token, newImage, 'image_content', okCallBack, koCallBack, progressCallback);
  }

  updateImage(contentId, obj, imageId, lang, token, okCallBack, koCallBack) {
    return super.put(this.itemName+'_images', `${this.urlPath}/${contentId}/site-images/${imageId}`, lang, token, obj, okCallBack, koCallBack);
  }

  deleteImage(contentId, imageId, lang, token, okCallBack, koCallBack) {
    return super.delete(this.itemName+'_images', `${this.urlPath}/${contentId}/site-images/${imageId}`, lang, token, okCallBack, koCallBack);
  }
}