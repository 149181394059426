import React from 'react';
import { CButton, CPopover, CTooltip } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { I18n } from 'react-i18next';

const UiButton = props => {
  const { tag, type, icon, className, classNameContainer, tooltipMsg } = props;
  return (
    <I18n ns="translations">
      {t => (
        <>
          { !props.popover ? 
            <div className={`UiButton_container ${classNameContainer || 'primaryColor'}${tooltipMsg ? ' UiButton_container_tooltip' : ''}`}>
              <CButton tag={tag || (type || 'button')} className={`UiButton ${className || ''} `}
                size={props.size} style={props.style} block={props.block} shape={props.shape} color={props.color || 'primary'}
                active={props.active} pressed={props.pressed} variant={props.variant} disabled={props.disabled}
                onClick={props.onClick}
              >
                {icon ? (
                  <span className="me-2">
                    <CIcon icon={icon} />
                  </span>
                ) : (
                  ''
                  )}
                <span className={`${icon ? '' : 'w100'}`} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                  {t(props.label)}
                </span>
              </CButton>
            </div>
            :
            <CPopover
              content={t(props.popoverContent)}
              placement={props.popoverPlacement}
              trigger={props.popoverTrigger}
            >
              <div className={`UiButton_container ${classNameContainer || 'primaryColor'}${tooltipMsg ? ' UiButton_container_tooltip' : ''}`}>
                <CButton tag={tag || (type || 'button')} className={`UiButton ${className || ''} `}
                  size={props.size} style={props.style} block={props.block} shape={props.shape} color={props.color || 'primary'}
                  active={props.active} pressed={props.pressed} variant={props.variant} disabled={props.disabled}
                  onClick={props.onClick}
                >
                  {icon ? (
                    <span className="me-2">
                      <CIcon icon={icon} />
                    </span>
                  ) : (
                    ''
                    )}
                  <span className={`${icon ? '' : 'w100'}`} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {t(props.label)}
                  </span>
                </CButton>
              </div>
            </CPopover>
          }
          {tooltipMsg && (
            <span className="UiButton_container_tooltip">
              <CTooltip placement="top" content={t(tooltipMsg)}>
                <CIcon icon="cis-info-circle" size="sm" />
              </CTooltip>
            </span>
          )}
        </>
      )}
    </I18n>
  );
};

export default UiButton;
