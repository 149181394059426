import BaseService from './baseService';

export default class ProjectProductsService extends BaseService {
  static instance = null;

  static getInstance(store) {
    if (!ProjectProductsService.instance) {
      ProjectProductsService.instance = new ProjectProductsService(store);
    }
    return ProjectProductsService.instance;
  }

  constructor(store) {
    const baseUrl = process.env.API_PATH;
    super(store, baseUrl, 'projects', 'project');
  }

  getList(obj, lang, token, okCallBack, koCallBack) {
    return super.get("project_products", this.urlPath + "/" + obj.project_id + "/products", lang, token, obj, okCallBack, koCallBack);
  }

  /* getProductsList(projectId, lang, token, okCallBack, koCallBack) {
    return super.get(this.listName + "_products", this.urlPath + "/" + projectId + "/products", lang, token, null, okCallBack, koCallBack);
  } */
}
