import { CFormLabel } from '@coreui/react-pro';
import React, { useState, useEffect, useRef } from 'react';
import { ChromePicker } from 'react-color';
import { I18n } from 'react-i18next';

function UiColorPicker(props) {
  const colors = props.colors || [
    'rgb(0,0,0)',
    'rgb(74,74,74)',
    'rgb(155,155,155)',
    'rgb(255,255,255)',
  ];

  const firstViewRef = useRef(true);
  const [currentColorRGB, setCurrentColorRGB] = useState(props.color);
  const [currentColor, setCurrentColor] = useState(props.color);
  const [showColorPicker, setShowColorPicker] = useState(false);

  useEffect(() => {
    setCurrentColorRGB(props.color);
  }, [props.color]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (props.onColorChange && firstViewRef.current === false) {
        props.onColorChange(currentColor);
      }

      firstViewRef.current = false;
    }, 300);

    return () => clearTimeout(timeout);
  }, [currentColor]);

  function handleColorChange(color) {
    if (typeof color === 'string') {
      const rgb = color.split('(')[1].split(',');
      rgb[2] = rgb[2].replace(-1, '');

      color = {
        rgb: {
          r: rgb[0],
          g: rgb[1],
          b: rgb[2].replace(')', ''),
        },
      };
    }
    setCurrentColor(color);
    setCurrentColorRGB(`rgb(${color.rgb.r},${color.rgb.g},${color.rgb.b})`);
  }

  function togglePickerVisibility() {
    setShowColorPicker(!showColorPicker);
  }

  function mapCircles(item, index) {
    return (
      <div
        key={index}
        onClick={() => handleColorChange(item)}
        className={`UiColorPicker__circle${
          item === currentColorRGB ? ' UiColorPicker__circle--active' : ''
        }`}
      >
        <div style={{ backgroundColor: item }}></div>
      </div>
    );
  }

  return (
    <>
      <I18n ns="translations">
        {(t) => (
          <div
            className={`UiColorPicker${
              props.inline ? ' UiColorPicker--inline' : ''
            }`}
          >
            <CFormLabel className="UiMainLabel">{t(props.label)}</CFormLabel>
            {!props.type && (
              <div className="UiColorPicker__toggler">
                <div
                  className="UiColorPicker__current"
                  style={{ backgroundColor: currentColorRGB }}
                  onClick={togglePickerVisibility}
                ></div>
                {showColorPicker && (
                  <div className={`UiColorPicker__picker${props.left ? " UiColorPicker__picker--left" : ""}${props.bottom ? " UiColorPicker__picker--bottom" : ""}`}>
                    <ChromePicker
                      color={currentColorRGB}
                      onChange={handleColorChange}
                    />
                  </div>
                )}
              </div>
            )}

            {props.type === 'circle' && (
              <div className="UiColorPicker__circles">
                {colors.map(mapCircles)}
              </div>
            )}
          </div>
        )}
      </I18n>
    </>
  );
}

export default UiColorPicker;
