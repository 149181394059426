import React, { useState, useEffect } from 'react';
import { CRow, CCol, CTooltip, CCard, CCardBody, CCardHeader, CCollapse } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { I18n } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';

// UTILS
import ComponentsUtils from '../../utils/componentsUtils';

// COMPONENTS
import UiButton from './UiButton';

let localFilterGlobal = null;

function UiCard(props) {
  const [filter, setFilter] = useState({});
  const [isNewFilter, setIsNewFilter] = useState(true);
  const [collapsible, setCollapsible] = useState((props.collapsible !== null && props.collapsible !== undefined) ? props.collapsible : true);
  const [cardIsOpen, setCardIsOpen] = useState((props.cardIsOpen !== null && props.cardIsOpen !== undefined) ? (props.collapsible === false) ? true : props.cardIsOpen : false);
  const [propsCardIsOpen, setPropsCardIsOpen] = useState(props.cardIsOpen);
  const [localFilter, setLocalFilter] = useState();

  useEffect(() => {
    setCollapsible((props.collapsible !== null && props.collapsible !== undefined) ? props.collapsible : collapsible);
    if (collapsible !== false) {
      const oldVal = (propsCardIsOpen !== null && propsCardIsOpen !== undefined) ? propsCardIsOpen : null;
      const newVal = (props.cardIsOpen !== null && props.cardIsOpen !== undefined) ? props.cardIsOpen : null;
      setCardIsOpen((oldVal !== newVal) ? (props.cardIsOpen) ? props.cardIsOpen : false : cardIsOpen);
      setPropsCardIsOpen(props.cardIsOpen);
    }
    if (props.localFilter) {
      let cloneLocalFilter = props.localFilter;
      cloneLocalFilter.mandatory = false;
      if (props.localFilter.reducer && props.apiReducer && props.apiReducer[props.localFilter.reducer]) {
        cloneLocalFilter.values = props.apiReducer[props.localFilter.reducer].data;
      }
      if (isNewFilter && (cloneLocalFilter.type === 'checkbox-group' || cloneLocalFilter.type === 'chip' || cloneLocalFilter.type === 'multiselect')) {
        const values = (cloneLocalFilter.values) ? cloneLocalFilter.values : [];
        setFilter({ [cloneLocalFilter.name]: values });
        setIsNewFilter(false);
      }
      setLocalFilter(cloneLocalFilter);
    }
  }, [props])

  useEffect(() => {
    localFilterGlobal = cloneDeep(localFilter);
    if (localFilterGlobal && localFilterGlobal.type === 'checkbox-group') {
      localFilterGlobal.inline = true;
    }
  }, [filter, isNewFilter, collapsible, cardIsOpen, propsCardIsOpen, localFilter])

  function toggleCollapse() {
    if (props.onToggleCard !== undefined && props.onToggleCard !== null && props.cardId !== undefined && props.cardId !== null) {
      props.onToggleCard(props.cardId);
    } else {
      setCardIsOpen(!cardIsOpen);
    }
  }

  function onLocalFilter(event) {
    setFilter({
      [event.target.name]: event.target.value
    })

    props.onLocalFilter(event);
  }

  function onClickHeaderButtons(callback) {
    if (!cardIsOpen) {
      toggleCollapse();
    }
    if (props[callback]) {
      eval(`props.${callback}()`);
    }
  }
  return (
    <I18n ns="translations">
      {t => (
        <div className={props.className}>
          <CCard tag={props.tag} className={`${props.relationBox ? "UiRelationBox" : ""} ${props.cardClassName}`}>
            <CCardHeader className={`${props.headerClassName ? props.headerClassName : ""}${props.warning ? ' UiSectionHeader_withWarning' : ''}`}>
              <CRow>
                <CCol>
                  <CRow>
                    {props.icon && (
                      <CCol xs="auto" className='me-2'>
                        <CIcon className={collapsible ? 'toggle-collapse' : ''} onClick={collapsible ? toggleCollapse.bind(this) : null} icon={props.icon} />
                      </CCol>
                    )}
                    <CCol xs="auto">
                      <span style={{marginRight: 5}} role="presentation" className={collapsible ? 'toggle-collapse' : ''} onClick={collapsible ? toggleCollapse.bind(this) : null}>
                        <strong>{t(props.title)}</strong>
                      </span>
                      {props.tooltipMsg && (
                        <span>
                          <CTooltip placement="top" content={t(props.tooltipMsg)}>
                            <CIcon icon="cis-info-circle" size="sm" />
                          </CTooltip>
                        </span>
                      )}
                      <br />
                      {t(props.subtitle)}
                    </CCol>      
                  </CRow>
                </CCol>
                <CCol xs="auto" className="ms-auto">
                  {/* <div className="UiCard__header__content"> */}
                    {localFilter && (localFilter.type !== 'checkbox-group') && (
                      <div className="UiCard__header__content__filter">
                        {ComponentsUtils.renderByType(false, false, true, localFilter, filter, onLocalFilter.bind(this))}
                      </div>
                    )}
                    <div className="UiCard__header__content__actions">
                      {props.exportable && (
                        <UiButton icon="cis-cloud-download" label={t('Common.export')} onClick={onClickHeaderButtons.bind(this, 'onExport')} />
                      )}
                      {props.insertable && (
                        <CRow>
                          <CCol>
                            <UiButton icon="cis-plus" label={t('Common.add')} disabled={props.disabled} onClick={onClickHeaderButtons.bind(this, 'onClick')} />
                          </CCol>
                        </CRow>
                      )}
                      {props.deletable && (
                        <UiButton icon="cis-trash" label={t('Common.delete')} disabled={props.disabled} onClick={onClickHeaderButtons.bind(this, 'onDelete')} />
                      )}
                    </div>
                  {/* </div> */}
                </CCol>
                {collapsible && (
                  <CCol xs="auto" className="ms-4">
                      <span className="UiCard__header__toggle toggle-collapse">
                        <CIcon icon="cis-chevron-bottom" role="presentation" className={`ml-3 collapsible-angle-icon${cardIsOpen ? ' opened' : ''}`} onClick={toggleCollapse.bind(this)} />
                      </span>
                  </CCol>
                )}
                {localFilter && (localFilter.type === 'checkbox-group') && (
                  <CCol>
                      <div className="flexComponentNoGrow">
                        {ComponentsUtils.renderByType(false, false, true, localFilter, filter, onLocalFilter.bind(this))}
                      </div>
                  </CCol>
                )}
              </CRow>
            </CCardHeader>
            <CCollapse visible={collapsible ? cardIsOpen : true}>
              <CCardBody className={props.cardBodyClassName}>{props.children}</CCardBody>
            </CCollapse>
          </CCard>
        </div>
      )}
    </I18n>
  );
}

export default UiCard;
