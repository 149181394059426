import BaseService from './baseService';

export default class ProjectUsersService extends BaseService {
  static instance = null;

  static getInstance(store) {
    if (!ProjectUsersService.instance) {
      ProjectUsersService.instance = new ProjectUsersService(store);
    }
    return ProjectUsersService.instance;
  }

  constructor(store) {
    const baseUrl = process.env.API_PATH;
    super(store, baseUrl, 'projects', 'project');
  }

  addUserToProject(projectId, userId, lang, token, okCallBack, koCallBack) {
    return super.post(this.itemName, `${this.urlPath}/${projectId}/users/${userId}`, lang, token, null, okCallBack, koCallBack);
  }

  deleteUserFromProject(projectId, userId, lang, token, okCallBack, koCallBack) {
    return super.delete(this.itemName, `${this.urlPath}/${projectId}/users/${userId}`, lang, token, okCallBack, koCallBack);
  }
}