import { createSlice } from '@reduxjs/toolkit';

export const GLOBAL_SERVICE_ACTIONS = {
    LOADING: 'LOADING',
    SESSION_CHANGED: 'SESSION_CHANGED',
    SESSION_TAB_CHANGED: 'SESSION_TAB_CHANGED',
    LANGUAGE_CHANGED: 'LANGUAGE_CHANGED',
    USER_LOGIN: 'USER_LOGIN',
    USER_RESUME: 'USER_RESUME',
    USER_LOGOUT: 'USER_LOGOUT',
    EXPORT_INVOKED: 'EXPORT_INVOKED',
    IMPORT_INVOKED: 'IMPORT_INVOKED',
    QUEUE_CHANGED: 'QUEUE_CHANGED',
    WEBSOCKET_RECEIVED: 'WEBSOCKET_RECEIVED',
    SET_BREAKPOINT: 'SET_BREAKPOINT'
}

export const GLOBAL_SERVICE_STATUS = {
    LOADING: 'LOADING',
    SESSION_READY: 'SESSION_READY'
}

//action creator

export const init = (lang) => dispatch => {
    try {
        return dispatch(initAction(lang));
    } catch (e) {
        return console.error(e.message);
    }
}

//action creator
export const currentSessionChanged = (value) => dispatch => {
    try {
        return dispatch(currentSessionChangedAction(value));
    } catch (e) {
        return console.error(e.message);
    }
}

export const currentSessionTabChanged = (value) => dispatch => {
    try {
        return dispatch(currentSessionTabChangedAction(value));
    } catch (e) {
        return console.error(e.message);
    }
}

export const currentLanguageChanged = (value) => dispatch => {
    try {
        return dispatch(currentLanguageChangedAction(value));
    } catch (e) {
        return console.error(e.message);
    }
}

export const userLogin = (user) => dispatch => {
    try {
        return dispatch(userLoginAction(user));
    } catch (e) {
        return console.error(e.message);
    }
}

export const userResume = (user) => dispatch => {
    try {
        return dispatch(userLoginAction(user));
    } catch (e) {
        return console.error(e.message);
    }
}

export const userLogout = () => dispatch => {
    try {
        return dispatch(userLogoutAction());
    } catch (e) {
        return console.error(e.message);
    }
}

export const invokeExport = (time) => dispatch => {
    try {
        return dispatch(invokeExportAction(time));
    } catch (e) {
        return console.error(e.message);
    }
}

export const invokeImport = (time) => dispatch => {
    try {
        return dispatch(invokeImportAction(time));
    } catch (e) {
        return console.error(e.message);
    }
}

export const queueChanged = (queue) => dispatch => {
    try {
        return dispatch(queueChangedAction(queue));
    } catch (e) {
        return console.error(e.message)
    }
}

export const websocketReceived = (message) => dispatch => {
    try {
        return dispatch(websocketReceivedAction(message));
    } catch (e) {
        return console.error(e.message)
    }
}

export const setCurrentBreakpoint = (breakpoint) => dispatch => {
    try {
        return dispatch(setCurrentBreakpointAction(breakpoint));
    } catch (e) {
        return console.error(e.message)
    }
}

// Slice
const globalServiceDuck = createSlice(
    {
        name: 'globalServiceDuck',
        initialState: {
            status: GLOBAL_SERVICE_STATUS.LOADING,
            currentLanguage: 'it',
            currentUser: undefined,
            currentBreakpoint: undefined,
            currentSection: undefined,
            currentTab: undefined,
            exportTime: undefined,
            importTime: undefined,
            documentQueue: undefined,
            websocketLastMessage: undefined
        },
        reducers: {
            initAction: (state, action) => {
                state.currentLanguage = action.payload
            },
            currentSessionChangedAction: (state, action) => {
                state.status = GLOBAL_SERVICE_STATUS.SESSION_READY,
                    state.currentSection = (({ sectionComponent, sectionService, ...o }) => o)(action.payload)

            },
            currentSessionTabChangedAction: (state, action) => {
                state.status = GLOBAL_SERVICE_STATUS.SESSION_READY,
                    state.currentTab = action.payload
            },
            currentLanguageChangedAction: (state, action) => {
                state.currentLanguage = action.payload
            },
            userLoginAction: (state, action) => {
                state.currentUser = action.payload
            },
            userLogoutAction: (state) => {
                state.currentUser = undefined;
            },
            invokeExportAction: (state, action) => {
                state.exportTime = action.payload
            },
            invokeImportAction: (state, action) => {
                state.importTime = action.payload
            },
            queueChangedAction: (state, action) => {
                state.queue = action.payload ? action.payload : []
            },
            websocketReceivedAction: (state, action) => {
                state.websocketLastMessage = action.payload
            },
            setCurrentBreakpointAction: (state, action) => {
                state.currentBreakpoint = action.payload
            }

        },
    }
);

export default globalServiceDuck.reducer;

export const {
    userLoginAction,
    userLogoutAction,
    currentSessionChangedAction,
    currentSessionTabChangedAction,
    currentLanguageChangedAction,
    invokeExportAction,
    invokeImportAction,
    queueChangedAction,
    websocketReceivedAction,
    initAction,
    setCurrentBreakpointAction
} = globalServiceDuck.actions

