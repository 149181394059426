//GLOBAL
import React from 'react'
import { connect } from 'react-redux';
import { I18n } from 'react-i18next';
import i18n from 'i18next';
import ApplicationStore from '../../redux/store';
import cloneDeep from 'lodash/cloneDeep';

//UI
import { CBadge, CCard, CCardBody, CCol, CContainer, CFormLabel, CRow } from '@coreui/react-pro';

//UTILS
import withRouter from '../../utils/withNavigation';
import ConstantsService from '../../services/constantsService';

// SERVICES

//COMPONENTS
import { mapStateToProps } from './AbstractComponent';
import AbstractDetail from './AbstractDetail';

class Contract extends AbstractDetail {
  constructor(props) {
    super(props);
  }

  renderChildren(t) {

    return (
      <div>
        Contratti
      </div>
    );
  }
}

export default connect(mapStateToProps)(withRouter(Contract));