import { unstable_useBlocker as useBlocker } from 'react-router-dom';
import React from 'react';

function BlockerComponent(props) {

    function handleBlocker(tx) {
        if (props.isDirty) {
            if (!window.confirm(props.message)) {
                return true
            }
        }else{
            return false
        }
    }
    useBlocker(handleBlocker, [props.isDirty]);



    return (
        <>

        </>
    );
}

export default BlockerComponent;

//This component use an unstale hook "unstable_useBlocker"
//The unstable_useBlocker hook is called "unstable" because it is still under development and its behavior or API may change in the future.
// However, it can still be used in real applications, but it is important to pay attention to possible future modifications.
