import React from 'react';
import PropTypes from 'prop-types';
import { CRow, CCol, CFormLabel, CFormInput, CInputGroup, CInputGroupText } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { I18n } from 'react-i18next';

//COMPONENTS
import UiInput from './UiInput';
import UiModal from './UiModal';

const UiChangePasswordField = props => {
  const {
    name,
    value,
    label,
    required,
    disabled,
    maxLength,
    onChange,
    hideLabel,
    placeholder,
  } = props;

  const [error, setError] = React.useState(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [password, setPassword] = React.useState(null);
  const [repeatPassword, setRepeatPassword] = React.useState(null);

  /**
   * checks whether the input is disabled
   * if not, shows the modal with the form
   */
  const openPasswordModal = () => {
    if (disabled) return null;
    setError(null);
    setIsModalOpen(true);
  };

  /**
   * clears the errors
   * validate the form
   * handles change in the main form
   * @param {*} t
   */
  const changePassword = t => {
    setError(null);
    if (!password || !repeatPassword) {
      setError(t('Validation.password_fields_missing'));
      return;
    } else if (password !== repeatPassword) {
      setError(t('Validation.passwords_do_not_match'));
      return;
    }

    const event = {
      password: password,
    }

    //calls callback
    onChange(event);
    setIsModalOpen(false);
  };

  /**
   * closes the modal
   * clears the internal form state
   */
  const closeAndClear = () => {
    setIsModalOpen(false);
    setPassword(null);
    setRepeatPassword(null);
    setError(null);
  }

  return (
    <I18n ns="translations">
      {t => (
        <>
          <div className="UIInput">
            <CFormLabel htmlFor={name} className="UiMainLabel">
              {t(label)} {required && '*'}
            </CFormLabel>
            <CInputGroup className={`multipleInputContainer`}>
              <CFormInput
                name={name}
                value={value}
                type="password"
                required={required}
                disabled={true}
                onChange={null}
                maxLength={maxLength}
                placeholder={!hideLabel ? t(placeholder) ? t(placeholder) : "**********" : t(label)}
                onBlur={null}
              />
              <CInputGroupText className="bg-primary text-white clickable hover-primary border-1" onClick={openPasswordModal.bind(this)} >
                <CIcon icon="cis-pencil" />
              </CInputGroupText>
            </CInputGroup>
          </div>

          <UiModal title={t('Common.change_password')} loadingModal={false} isOpen={isModalOpen}
            onSubmit={() => changePassword(t)} onCancel={() => { closeAndClear(); }}>
            <CRow>
              <CCol md="6" className="px-3">
                <UiInput type="password" name="password" label={t('Login.password')} required
                  value={password} maxLength={maxLength} defaultPassword={value}

                  onChange={e => setPassword(e.target.value)} />
              </CCol>
              <CCol md="6" className="px-3">
                <UiInput type="password" name="repeatPassword" label={t('Login.confirm_password')} required
                  maxLength={maxLength} value={repeatPassword}
                  onChange={e => setRepeatPassword(e.target.value)} />
              </CCol>
              {error && <div className="text-danger">{error}</div>}
            </CRow>
          </UiModal>
        </>
      )}
    </I18n>
  );
};

const propTypes = {
  obj: PropTypes.object,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  suffix: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  hideLabel: PropTypes.bool,
  maxLength: PropTypes.number,
  tooltipMsg: PropTypes.string,
  placeholder: PropTypes.string,
};

UiChangePasswordField.propTypes = propTypes;

export default UiChangePasswordField;
