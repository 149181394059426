import React, { useEffect, useState, useRef } from 'react';
import { CRow, CCol, CFormInput, CFormLabel, CTooltip, CInputGroup, CInputGroupText } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { I18n } from 'react-i18next';
import PropTypes from 'prop-types';

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  title: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  display: PropTypes.string,
  plainText: PropTypes.bool,
  inline: PropTypes.bool,
  className: PropTypes.string,
  tooltipMsg: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.number,
  max: PropTypes.number,
  type: PropTypes.oneOf(['tel', 'text', 'email', 'password', 'number', 'currency']),
};

const defaultProps = { type: 'text' };

function UiInput(props) {

  const [touched, setTouched] = useState(false);
  const [invalid, setInvalid] = useState(props.required ? false : null);
  const [isChanging, setIsChanging] = useState(false);

  let focusTarget = useRef();

  useEffect(() => {
    if (props.required && !props.value) {
      setInvalid(true);
    } else if (props.hasToBeEquals && props.value !== props.hasToBeEquals) {
      setInvalid(true);
    } else if (props.max && Number.parseFloat(props.value) > Number.parseFloat(props.max)) {
      setInvalid(true);
    } else if (props.min && Number.parseFloat(props.value) < Number.parseFloat(props.min)) {
      setInvalid(true);
    } else {
      setInvalid(false);
    }
  }, [props])

  function handleOnFocus(evt) {
    if (!props.disabled) {
      if (props.onFocus) {
        props.onFocus(evt);
      }
      setInvalid(invalid);
      setIsChanging(true);
    }
  }

  function handleOnBlur() {
    if (!props.disabled) {
      let { value } = props;
      if (props.type === 'currency') {
        value = (value && value.length > 0) ? parseFloat(Math.round(value * 100) / 100).toFixed(2) : null;
      } else if (props.type === 'number') {
        value = (value && value.length > 0) ? parseFloat(value) : null;
      }

      const event = {
        target: {
          name: props.name,
          value,
        },
      };

      if (props.onChange && props.type === 'currency') props.onChange(event);
      if (props.onBlur) props.onBlur(event);
      if (!touched) {
        setTouched(true);
        setIsChanging(false);
      }
    }
  }

  function handleOnChange(evt) {
    if (!props.disabled) {
      const { value } = evt.target;

      if (props.required && !value) setInvalid(true);

      if (props.maxLength && value.length < props.maxLength) setInvalid(true);

      if (props.onChange) props.onChange(evt);

      setInvalid(false);
      setTouched(true);
    }
  }

  function handleOnChangeNumber(evt) {
    if (!props.disabled) {
      let { value } = evt.target;
      let tmpInvalid = false;
      if (value) {
        value = value.replace(/[^0-9,]+/g, '').replace(',', '.').replace(/,/g, '');
      }

      const event = {
        target: {
          name: evt.target.name,
          value,
        },
      };

      if (props.required && !value || Number.isNaN(value))
        tmpInvalid = true;
      if (props.max && value > Number.parseFloat(props.max)) {
        tmpInvalid = true;
      }
      if (props.min && value < Number.parseFloat(props.min)) {
        tmpInvalid = true;
      }

      setInvalid(tmpInvalid);

      if (!tmpInvalid) {
        if (props.onChange) props.onChange(event);
      }

    }
  }

  function renderCurrency(t) {
    let value = (!!props.value || props.value === 0) ? `${props.value}` : '';
    if (props.disabled || !isChanging) {
      value = (value && value.length > 0) ? `${parseFloat(Math.round(value * 100) / 100).toFixed(2)}` : '';
    }
    value = value.replace('.', ',');
    return (
      <>
        <CFormInput ref={(Input) => focusTarget = Input}
          type="text" id={props.name} className="multipleInputContent currencyValue" name={props.name} disabled={props.disabled}
          title={props.title} placeholder={props.disabled ? '' : props.placeholder ? t(props.placeholder) : `${t('Common.write')}...`}
          required={props.required} invalid={invalid} value={value} readOnly={props.readOnly} plainText={props.plainText}
          onFocus={handleOnFocus.bind(this)} onChange={handleOnChangeNumber.bind(this)} onBlur={handleOnBlur.bind(this)} />
        {props.suffix && <CInputGroupText>{props.suffix}</CInputGroupText>}
      </>
    );
  }

  function renderNumber(t) {
    const value = (!!props.value) ? (`${props.value}`).replace('.', ',') : '';
    return (
      <>
        <CFormInput ref={(Input) => focusTarget = Input}
          type="text" id={props.name} className="multipleInputContent" name={props.name} disabled={props.disabled}
          title={props.title} placeholder={props.disabled ? '' : props.placeholder ? t(props.placeholder) : `${t('Common.write')}...`}
          required={props.required} invalid={invalid} value={value} readOnly={props.readOnly} plainText={props.plainText}
          onFocus={handleOnFocus.bind(this)} onChange={handleOnChangeNumber.bind(this)} onBlur={handleOnBlur.bind(this)} />
        {props.suffix && (
          <CInputGroupText>{props.suffix}</CInputGroupText>
        )}
      </>
    );
  }

  function renderText(t) {
    return (
      <>
        <CFormInput ref={(Input) => focusTarget = Input}
          type="text" id={props.name} className="multipleInputContent" name={props.name} disabled={props.disabled}
          title={props.title} placeholder={props.disabled ? '' : props.placeholder ? t(props.placeholder) : `${t('Common.write')}...`}
          required={props.required} feedbackInvalid={props.feedbackInvalid}
          value={props.value ? props.value : ''} readOnly={props.readOnly} plainText={props.plainText} maxLength={props.maxLength || null}
          onFocus={handleOnFocus.bind(this)} onChange={handleOnChange.bind(this)} onBlur={handleOnBlur.bind(this)} invalid={props.invalid} />
        {props.suffix && (
          <CInputGroupText>{props.suffix}</CInputGroupText>
        )}
      </>
    );
  }

  function renderPassword(t) {
    return (
      <>
        <CFormInput type="password" id={props.name} className="multipleInputContent" name={props.name} disabled={props.disabled}
          maxLength={props.maxLength || null} title={props.title} placeholder={props.disabled ? '' : props.placeholder ? t(props.placeholder) : `${t('Common.write')}...`}
          required={props.required} value={props.value ? props.value : ''} readOnly={props.readOnly} plainText={props.plainText}
          onFocus={handleOnFocus.bind(this)} onChange={handleOnChange.bind(this)} onBlur={handleOnBlur.bind(this)} invalid={props.invalid} feedbackInvalid={props.feedbackInvalid} />
        {props.suffix && (
          <CInputGroupText>{props.suffix}</CInputGroupText>
        )}
      </>
    );
  }

  return (
    <I18n ns="translations">
      {t => (
        <div className={`UiInput ${props.className ? props.className : ''} ${invalid && touched ? 'is-invalid' : ''}`}>
          {props.inline ? (
            <CRow>
              <CCol>
                {props.label && (
                  <CFormLabel htmlFor={props.name} className="UiMainLabel">
                    {t(props.label)} {props.required ? '*' : ''}
                    {(props.type === 'currency' || props.tooltipMsg) && (
                      <span>
                        <CTooltip placement="top" content={props.tooltipMsg ? t(props.tooltipMsg) : t('Tooltips.currency_format_info')}>
                          <CIcon icon="cil-info-circle" size="sm" />
                        </CTooltip>
                      </span>
                    )}
                  </CFormLabel>
                )}
              </CCol>
              <CCol>
                <CInputGroup className={`multipleInputContainer ${invalid && touched ? ' is-invalid was-validated' : ''}`}>
                  {props.prefix && props.display !== 'text' && props.display !== 'password' && (
                    <CInputGroupText className='form-control'>{props.prefix}</CInputGroupText>
                  )}
                  {props.type === 'currency' && renderCurrency(t)}
                  {props.type === 'number' && renderNumber(t)}
                  {props.type === 'password' && renderPassword(t)}
                  {props.type !== 'currency' && props.type !== 'number' && props.type !== 'password' && renderText(t)}
                </CInputGroup>

                {invalid && touched && (
                  <div className="invalid-feedback">{t('Common.field_invalid')}</div>
                )}
              </CCol>
            </CRow>
          ) : (
            <>
              {props.label && (
                <CFormLabel htmlFor={props.name} className="UiMainLabel">
                  {t(props.label)} {props.required ? '*' : ''}
                  {(props.type === 'currency' || props.tooltipMsg) && (
                    <span>
                      <CTooltip placement="top" content={props.tooltipMsg ? t(props.tooltipMsg) : t('Tooltips.currency_format_info')}>
                        <CIcon icon="cil-info-circle" size="sm" />
                      </CTooltip>
                    </span>
                  )}
                </CFormLabel>
              )}

              <CInputGroup className={`multipleInputContainer ${invalid && touched ? ' is-invalid was-validated' : ''}`}>
                {props.prefix && props.display !== 'text' && props.display !== 'password' && (
                  <CInputGroupText className='form-control'>{props.prefix}</CInputGroupText>
                )}
                {props.type === 'currency' && renderCurrency(t)}
                {props.type === 'number' && renderNumber(t)}
                {props.type === 'password' && renderPassword(t)}
                {props.type !== 'currency' && props.type !== 'number' && props.type !== 'password' && renderText(t)}
              </CInputGroup>

              {invalid && touched && (
                <div className="invalid-feedback">{t('Common.field_invalid')}</div>
              )}
            </>
          )}
        </div>
      )}
    </I18n>
  );

}

UiInput.propTypes = propTypes;
UiInput.defaultProps = defaultProps;

export default UiInput;
