import React from 'react';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import store from './redux/store';

import DefaultLayout from './components/views/DefaultLayout';
import Login from './components/views/Login';
import ForgotPassword from './components/views/ForgotPassword';
import Register from './components/views/Register';
import Page404 from './components/views/Page404';
import Page500 from './components/views/Page500';

export default function App() {

  const router = createBrowserRouter([
    { path: "/login", element: <Login /> },
    { path: "/forgot_password", element: <ForgotPassword /> },
    { path: "/register", element: <Register /> },
    { path: "/404", element: <Page404 /> },
    { path: "/500", element: <Page500 /> },
    { path: "*", element: <DefaultLayout /> }
  ], { basename: process.env.BASENAME, });


  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
}
