// GLOBAL
import React from 'react';
import { connect } from 'react-redux';
import { CContainer, CRow } from '@coreui/react-pro';
import { I18n } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import 'react-toastify/dist/ReactToastify.css';

// STORE
import ApplicationStore from '../../redux/store';

// UTILS
import withRouter from '../../utils/withNavigation';

// COMPONENTS
import AbstractComponent, { mapStateToProps } from './AbstractComponent';
import UiCard from '../ui/UiCard';
import UiMap from '../ui/UiMap';
import UiButton from '../ui/UiButton';

//TYPES
import { userType } from '../../types';


class Dashboard extends AbstractComponent {
  constructor(props) {
    super(props);

    this.state = {
      exportData: {},
      importData: {},
    };
  }

  populateServices() {
    if (!this.globalService.currentUser || !(this.globalService.currentUser.userId !== 0)) {
      return;
    }

    super.populateServices();
  }

  render() {
    return (
      <I18n ns="translations">
        {t => (
          <div className="animated fadeIn">
            {this.renderToast()}
            <CContainer fluid>
              {/* <CRow>
                <UiCard className="UiDashboardCard" icon="cis-bell" title="MainTitles.notifications" collapsible={true} isOpen={true}>
                </UiCard>
                  <UiMap currentUser={this.globalService.currentUser} apiReducer={this.props.apiReducer}
                    handleOnForcedChange={this.handleOnForcedChange} handleOnSearchCityChange={this.handleOnSearchCityChange}
                  // handleOnFocus={this.handleOnFocus} handleOnChange={this.handleOnChange} handleOnBlur={this.handleOnBlur}
                  />
              </CRow> */}
            </CContainer>
            {this.renderExportModal(t)}
            {this.renderImportModal(t)}
          </div>
        )}
      </I18n>
    );
  }
}

Dashboard.propTypes = { currentUser: userType };

export default connect(mapStateToProps)(withRouter(Dashboard));
