// GLOBAL
import React from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ApplicationStore from "../../redux/store";
import i18n from 'i18next';

// UI
import { CCard, CCardBody, CCol, CFormCheck, CRow, CBadge, CFormSwitch } from '@coreui/react-pro';

// SERVICE
import ConstantsService from '../../services/constantsService';
import JWTService from '../../services/jwtService';

// UTILS
import withRouter from '../../utils/withNavigation';
import cloneDeep from 'lodash/cloneDeep';

// COMPONENTS
import { mapStateToProps } from './AbstractComponent';
import AbstractDetail from './AbstractDetail';
import UiCard from '../ui/UiCard';
import UiInput from '../ui/UiInput';
import UiPictureUpload from '../ui/UiPictureUpload';
import UiCheckbox from '../ui/UiCheckbox';
import UiAutocomplete from '../ui/UiAutocomplete';
import UiChangePasswordField from '../ui/UiChangePasswordField';
import UiButton from '../ui/UiButton';
import UiModal from '../ui/UiModal';

class User extends AbstractDetail {

  constructor(props) {
    super(props);

    this.oauthService = JWTService.getInstance(ApplicationStore, process.env.LOGIN_PATH, process.env.CLIENT_ID, process.env.CLIENT_SECRET, localStorage, process.env.BASENAME);
  }

  updateModel() {
    if (!this.computedItemId && !this.itemId) {
      return;
    }
    super.updateModel();
    // this.getData();
  }

  buildHeaderSubtitle(t) {
    if (this.state.model) {
      const subtitle = super.buildHeaderSubtitle();
      
      const badgeLabel = !!this.state.model?.active ? "active" : "not_active",
      badgeColor = !!this.state.model?.active ? "success" : "danger";
      
      return <span className='flex'>{subtitle} <CBadge style={{marginLeft: 10}} color={badgeColor}>{t('Table.' + badgeLabel)}</CBadge></span>;
    }
  }

  buildHeaderButtons(t) {
    if (this.props.location.pathname !== "/user/auth" && this.state.model?.id != this.globalService.currentUser?.userId) {
      const buttonLabel = !!this.state.model.active ? "deactivate" : "activate";
      
      return (
        <>
          <CFormSwitch type='checkbox' checked={this.state.model.active} onClick={this.toggleModal.bind(this, "modalUserStatus")} size='xl' />
  
          <UiModal title={t(`Common.handle_user_status`)} okLabel={t('Common.yes')} koLabel={t('Common.no')}
            isOpen={this.state.modalUserStatus}
            onSubmit={this.handleOnActiveChange.bind(this, "modalUserStatus")} onCancel={this.toggleModal.bind(this, "modalUserStatus")}
            >
            <p>{t(`Common.confirm_${buttonLabel}_user`)}</p>
          </UiModal>
        </>
      );
    }
  }

  handleProjectsChange(parameter, evt) {
    const model = parameter === "all" ? this.toggleProjects() : this.handleSingleProject(evt.target);
    this.setState({ model, changing: true }, () => { this.props.onSaveButtonEnabled(this.state.changing) });
  }

  handleSingleProject(target){
    const { name: id, value: checked } = target;
    const model = cloneDeep(this.state.model);

    if (checked) model.projects.push(Number(id));

    if (!checked) {
      const index = model.projects.findIndex(project => project === Number(id));
      model.projects.splice(index, 1);
    }

    return model;
  }

  toggleProjects(){
    const model = cloneDeep(this.state.model);

    if (model.projects.length < this.props.apiReducer.projects.data.length){
      model.projects = this.buildProjectsArray();
    } else {
      model.projects = [];
    }

    return model;
  }

  buildProjectsArray(){
    return this.props.apiReducer.projects.data.map(project => project.id);
  }

  renderProjects() {
    return this.props.apiReducer?.projects?.data.map((project, key) => {
      return (
        <CCol key={key} className={key % 2 === 0 ? '' : 'rightCol'} sm="12" md="6" lg="3" xl="3">
          <UiCheckbox name={project.id} label={project.project_name}
            disabled={!this.state.editable}
            value={this.state.model.projects.includes(project.id)}
            onChange={this.handleProjectsChange.bind(this, "single")}
          />
        </CCol>
      )
    })
  }

  submitPassword(obj){
    const currentUser = this.props.location?.pathname.includes("/user/auth") ? "auth" : this.state.model[ConstantsService.defaultDBIdentifier];
    this.sectionService.updateUserPassword(currentUser, obj, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this))
  }

  resetPassword(){
    const toastId = toast(`${i18n.t('Common.uploading')}...`, { autoClose: false });

    function resetOkCallBack(response) {
      toast.update(toastId, {
        render: i18n.t('Common.success'),
        type: 'success',
        className: 'rotateY animated',
        autoclose: 1500,
      });
    }

    function resetKoCallBack(response) {
      toast.update(toastId, {
        render: `${i18n.t('Common.error')}: ${response?.data?.message}`,
        type: 'error',
        className: 'rotateY animated',
        autoClose: 5000,
      });
    }

    this.oauthService.resetPassword(this.state.model.email, resetOkCallBack.bind(this), resetKoCallBack.bind(this));
  }

  renderChildren(t) {
    // Autocomplete del tenant viene mostrata solo quando il superadmin sta editando un utente che ha un tenant associato
    let viewTenant = false;
    let roleIsEditable = false;
    let passwordIsResettable = false;
    let imagePath = this.state.model.user_image_path ? process.env.API_PATH + this.state.model.user_image_path : null;
    imagePath = this.state.model.image_path ? process.env.API_PATH + this.state.model.image_path : imagePath;

    if (this.globalService?.currentUser?.policies.includes("tenant_view") && !!this.state.model?.tenant_id) {
        viewTenant = true;
    }

    // Il ruolo è editabile quando l'utente non sta visionando il suo profilo personale
    if (this.props.location.pathname !== "/user/auth" && this.state.model?.id != this.globalService.currentUser?.userId) {
        roleIsEditable = true;

        // Se l'utente ha i permessi per modificare l'user, vedrà il bottone per resettare la password
        if(this.globalService?.currentUser?.policies.includes("user_edit")){
          passwordIsResettable = true;
        }
    }

    return (
      <>
        <CCard className="mb-4">
          <CCardBody>
            <CRow>
              <CCol className='mb-3' sm="12" md="6" lg="6" xl="6">
                <UiInput type="text" name="first_name" label="Table.name"
                  disabled={!this.state.editable}
                  required={this.state.mandatoryFields.indexOf('first_name') >= 0}
                  value={this.state.model.first_name}
                  onFocus={this.handleOnFocus.bind(this)}
                  onChange={this.handleOnChange.bind(this)}
                  onBlur={this.handleOnBlur.bind(this)}
                />
              </CCol>
              <CCol sm="12" md="6" lg="6" xl="6" className="mb-3 rightCol">
                <UiInput type="text" name="last_name" label="Table.last_name"
                  disabled={!this.state.editable}
                  required={this.state.mandatoryFields.indexOf('last_name') >= 0}
                  value={this.state.model.last_name}
                  onFocus={this.handleOnFocus.bind(this)}
                  onChange={this.handleOnChange.bind(this)}
                  onBlur={this.handleOnBlur.bind(this)}
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol className='mb-3' sm="12" md={viewTenant ? "6" : "12"}>
                <UiInput type="text" name="email" label="Table.email"
                  disabled={!this.state.editable}
                  required={this.state.mandatoryFields.indexOf('email') >= 0}
                  value={this.state.model.email}
                  onFocus={this.handleOnFocus.bind(this)}
                  onChange={this.handleOnChange.bind(this)}
                  onBlur={this.handleOnBlur.bind(this)}
                />
              </CCol>
              {viewTenant &&
                <CCol sm="12" md="6" lg="6" xl="6" className="mb-3 rightCol">
                  <UiAutocomplete type="text" name="tenant_id" reducer="tenants" label="Table.tenant_name" disabled={!this.state.editable}
                    value={this.state.model.tenant_id} required={this.state.mandatoryFields.indexOf('tenant_id') >= 0} apiReducer={this.props.apiReducer}
                    values={this.props.apiReducer.tenants?.data} fieldDisplayed="business_name" fieldReturned={ConstantsService.defaultDBIdentifier}
                    onFocus={this.handleOnFocus.bind(this)} onChange={this.handleOnChange.bind(this)} onBlur={this.handleOnBlur.bind(this)}
                    onValueChanged={this.handleOnForcedChange.bind(this)} onSearchChange={this.handleOnSearchChange.bind(this)}
                  />
                </CCol>
              }
            </CRow>

            <CRow>
              <CCol sm="12" md="6" lg="6" xl="6">
                {
                  roleIsEditable ?
                  <UiAutocomplete type="text" name="role_id" reducer="role_related" label="Table.role" disabled={!this.state.editable}
                    value={this.state.model.role_id} required={this.state.mandatoryFields.indexOf('role_id') >= 0} apiReducer={this.props.apiReducer}
                    values={this.props.apiReducer.role_related?.data} bindValue="tenant_id" model={this.state.model} fieldDisplayed="role_name" fieldReturned={ConstantsService.defaultDBIdentifier}
                    onFocus={this.handleOnFocus.bind(this)} onChange={this.handleOnChange.bind(this)} onBlur={this.handleOnBlur.bind(this)}
                    onValueChanged={this.handleOnForcedChange.bind(this)} onSearchChange={this.handleOnSearchChange.bind(this)}
                    />
                  :
                  <UiInput type="text" name="role_id" label="Table.role"
                  disabled={true}
                  required={this.state.mandatoryFields.indexOf('role_id') >= 0}
                  value={this.state.model.role_name}
                  />
                }
              </CCol>
              <CCol sm="12" md="6" lg="6" xl="6" className="rightCol">
                { !!passwordIsResettable ? 
                  <UiButton label={t('Common.reset_password')}
                  className="justify-content-center" classNameContainer="ButtonsContainerEnd ms-0 mt-2 mt-sm-0"
                  onClick={this.resetPassword.bind(this)} />
                  :
                  <UiChangePasswordField
                  label="Table.change_password"
                  onChange={this.submitPassword.bind(this)}
                  name={"password"}
                  />
                }
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

        {/* { !!this.state.model && !!this.state.model.projects &&
          <UiCard className="mt-4 colored-header" icon="cis-key" title={t('Table.project')} collapsible={false}>
            <CRow style={{ justifyContent: 'end' }}>
              <UiCheckbox name={'select_all'} label={this.state.allSelected ? 'Common.deselect_all' : 'Common.select_all'}
                disabled={!this.state.editable}
                value={this.state.model?.projects.length === this.props.apiReducer?.projects?.data.length}
                onFocus={this.handleOnFocus.bind(this)}
                onChange={this.handleProjectsChange.bind(this, 'all')}
              />
            </CRow>
            <CRow>
              {this.renderProjects()}
            </CRow>
          </UiCard>
        } */}

        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="12" md="12" lg="12" xl="12">
                <UiPictureUpload name='pic_url'
                  disabled={!this.state.editable}
                  value={imagePath}
                  onChange={this.onNewImageChange.bind(this, this.state.model, 'pic_url')}
                  onDelete={this.onImageDelete.bind(this, this.itemId)}
                  deletable fullScreenEnabled
                />
              </CCol>
            </CRow>
            </CCardBody>
        </CCard>
      </>
    );
  }
}

export default connect(mapStateToProps)(withRouter(User));
