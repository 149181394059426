//GLOBAL
import React from 'react'
import { connect } from 'react-redux';
import ApplicationStore from '../../redux/store';
import cloneDeep from 'lodash/cloneDeep';

//UI
import { CCard, CCardBody, CCol, CRow, CBadge, CContainer, CPopover } from '@coreui/react-pro';

//UTILS
import withRouter from '../../utils/withNavigation';
import ConstantsService from '../../services/constantsService';

// SERVICES
import LambdaService from '../../services/lambdaService';

//COMPONENTS
import { mapStateToProps } from './AbstractComponent';
import AbstractDetail from './AbstractDetail';
import UiCard from '../ui/UiCard';
import UiInput from '../ui/UiInput';
import UiSingleFileUpload from '../ui/UiSingleFileUpload';
import UiAutocomplete from '../ui/UiAutocomplete';
import UiProductShowcase from '../ui/UiProductShowcase';
import UiButton from '../ui/UiButton';
import UiModal from '../ui/UiModal';

class Product extends AbstractDetail {
  constructor(props) {
    super(props);

    this.lambdaService = LambdaService.getInstance(ApplicationStore);
    this.selectedStatus = null;
    this.state.isSceneInitialized = false;
  }

  componentDidUpdate(){
    if (this.state.model && (this.state.model.material === null || this.state.model.rotation === null)){
      this.productDetails();
    }

    if (this.state.model && !this.state.model.tenant_id && this.state.model.tenant.id){
      const model = cloneDeep(this.state.model);
      model.tenant_id = model.tenant.id;
      this.setState({model});
    }
  }

  handleOnChange(event) {
    var model = cloneDeep(this.state.model);
    eval(`model.${event.target.name} = event.target.value`);
    this.setState({ model, changing: true }, () => { this.props.onSaveButtonEnabled(this.state.changing); });
  }

  setHotspots(e){
    if(e.eventId === "CAMERA_TRANSFORM" && e.eventData){
      let newModel = cloneDeep(this.state.model)
      newModel.hotspot.push(e.eventData)
      this.sectionService.updateItem(newModel, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this))
    }
  }

  deleteHotspot(hotspots){
    let newModel = cloneDeep(this.state.model)
    newModel.hotspot = hotspots
    this.sectionService.updateItem(newModel, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this))
  }

  setMaterial(){
    this.sectionService.updateItem(this.state.model, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this))
  }

  setRotation(){
    this.sectionService.updateItem(this.state.model, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this))
  }

  handleDomeColorChange(colorObj){
    const model = cloneDeep(this.state.model);
    const { topColor, bottomColor } = colorObj;
    model.colors.bottom_dome_color = bottomColor;
    model.colors.top_dome_color = topColor;
    this.sectionService.updateItem(model, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this));
  }

  productDetails(){
    let newModel = cloneDeep(this.state.model)
    // VERRANNO PRESI DA MODELLO CON LA GET
    if(newModel.material === null){
      newModel.material = {
        metallic: 1,
        roughness: 1
      }
    }
    if(newModel.rotation === null){
      newModel.rotation = {
        pitch: 0,
        yaw: 0,
        roll: 0
      }
    } 

    this.setState({model: newModel})
  }

  buildHeaderSubtitle(t) {
    if (this.state.model) {
      const subtitle = super.buildHeaderSubtitle();
      let badgeColor;
      switch (this.state.model?.status.toLowerCase()){
        case ("draft"):
          badgeColor = "secondary";
          break;
        case ("completed"):
          badgeColor = "warning";
          break;
        case ("approved"):
          badgeColor = "success";
          break;
        case ("published"):
          badgeColor = "primary";
          break;
      }
      return <span className='flex'>{subtitle} <CBadge style={{marginLeft: 10}} color={badgeColor}>{t('SelectValues.' + this.state.model?.status)}</CBadge></span>;
    }
  }

  initializeScene(){
    this.setState({
      isSceneInitialized: true,
    })
  }

  destroyScene(){
    this.setState({
      isSceneInitialized: false,
    })
  }

  buildHeaderButtons(t) {
    let statusValues = [],
      userCanApprove = this.globalService.currentUser?.policies.includes("product_edit_approve"),
      userCanPublish = this.globalService.currentUser?.policies.includes("product_edit_publish");

    if (!userCanApprove && !userCanPublish) return;

    switch (this.state.model.status.toLowerCase()){
      case "draft":
        statusValues = [ "completed" ];
        break;
      case "completed":
        if (userCanApprove){
          statusValues = [ "draft", "approved" ];
        } else {
          statusValues = [ "draft" ];
        }
        break;
      case "approved":
        if (userCanPublish){
          statusValues = [ "completed", "published" ];
        } else {
          statusValues = [];
        }
        break;
      case "published":
        if (userCanPublish){
          statusValues = [ "approved" ];
        } else {
          statusValues = [];
        }
        break;
    }

    return (
      statusValues.map((item, idx) => {
        return <UiButton
                key={idx + Math.random()}
                label={'SelectValues.product_status_to_' + item}
                onClick={this.setSelectedStatus.bind(this, item)}
                popover
                popoverContent={'SelectValues.product_' + item}
                popoverPlacement="bottom"
                popoverTrigger={['hover', 'focus']}
              />
      })
    )
  }

  setSelectedStatus(selectedStatus){
    this.setState({selectedStatus, modalProductStatus: true});
  }

  handleOnStatusChange(event) {
    var model = cloneDeep(this.state.model);
    eval(`model.${event.target.name} = event.target.value`);
    this.setState({ model, changing: true }, () => { this.props.onSaveButtonEnabled(this.state.changing); });
  }

  onPivotOffsetChange(value){
    const model = cloneDeep(this.state.model);
    model.pivot_off_set = value;
    this.sectionService.updateItem(model, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this));
  }

  onFovRangeChange(value){
    const model = cloneDeep(this.state.model);
    model.fov_range = value;
    this.sectionService.updateItem(model, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this));
  }

  renderChildren(t) {
    let hasSingleProject = false;
    let canViewProjects = false;
    let buttonDisabled = false;

    if (this.globalService.currentUser.policies.includes("project_view")){
      canViewProjects = true;
    }

    if (this.globalService.currentUser?.projects && this.globalService.currentUser.projects.length === 1){
      hasSingleProject = true;
    }

    if (!this.globalService.currentUser.appApiKey || !this.globalService.currentUser.appEnvId || !this.state.model.model_id){
      buttonDisabled = true;
    }

    return (
      <>
        <div key='product_info'>
          <UiModal title={t(`Common.handle_product_status`)} okLabel={t('Common.yes')} koLabel={t('Common.no')}
            isOpen={!!this.state.modalProductStatus}
            onSubmit={this.handleOnProductStatusChange.bind(this, this.state.selectedStatus)} onCancel={this.toggleModal.bind(this, "modalProductStatus")}
            >
            <p>{t(`Common.confirm_${this.state.model?.status.toLowerCase()}_to_${this.state.selectedStatus}_product`)}</p>
          </UiModal>

          <CCard>
            <CCardBody>
              <CRow>
                <CCol className='mb-3' sm="12" md="6">
                  <UiInput type="text" name="name" label="Table.name"
                    disabled={!this.state.editable}
                    required={this.state.mandatoryFields.indexOf('name') >= 0}
                    value={this.state.model.name}
                    onFocus={this.handleOnFocus.bind(this)}
                    onChange={this.handleOnChange.bind(this)}
                    onBlur={this.handleOnBlur.bind(this)}
                  /> 
                </CCol>
                <CCol sm="12" md="6" className='mb-3 rightCol'>
                  <UiInput type="text" name="sku_number" label="Table.sku_number"
                    disabled={!this.state.editable}
                    required={this.state.mandatoryFields.indexOf('sku_number') >= 0}
                    value={this.state.model.sku_number}
                    onFocus={this.handleOnFocus.bind(this)}
                    onChange={this.handleOnChange.bind(this)}
                    onBlur={this.handleOnBlur.bind(this)}
                    />
                </CCol>
                <CCol sm="12" md="6">
                  { (!hasSingleProject && canViewProjects && this.state.model.tenant_id) &&
                    <UiAutocomplete type="text" name="project_id" reducer="projects" label="Table.project_name" disabled={!this.state.editable}
                      value={this.state.model.project_id} required={this.state.mandatoryFields.indexOf('project_id') >= 0} apiReducer={this.props.apiReducer}
                      values={this.props.apiReducer.projects?.data} bindValue="tenant_id" model={this.state.model} fieldDisplayed="project_name" fieldReturned={ConstantsService.defaultDBIdentifier}
                      onFocus={this.handleOnFocus.bind(this)} onChange={this.handleOnChange.bind(this)} onBlur={this.handleOnBlur.bind(this)}
                      onValueChanged={this.handleOnForcedChange.bind(this)} onSearchChange={this.handleOnSearchChange.bind(this)} />
                  }

                  { (!hasSingleProject && !canViewProjects) &&
                    <UiAutocomplete type="text" name="project_id" reducer="user_projects" label="Table.project_name" disabled={!this.state.editable}
                    value={this.state.model.project_id} required={this.state.mandatoryFields.indexOf('project_id') >= 0} apiReducer={this.props.apiReducer}
                    values={this.props.apiReducer.user_projects?.data} fieldDisplayed="project_name" fieldReturned={ConstantsService.defaultDBIdentifier}
                    onFocus={this.handleOnFocus.bind(this)} onChange={this.handleOnChange.bind(this)} onBlur={this.handleOnBlur.bind(this)}
                    onValueChanged={this.handleOnForcedChange.bind(this)} onSearchChange={this.handleOnSearchChange.bind(this)} />
                  }
                </CCol>
              </CRow>
            </CCardBody>
            
          </CCard>
        </div>
        
        <div key="3d_upload">
          <CCard>
            <CCardBody>
              <CRow>
                <CCol sm="12">
                  <UiSingleFileUpload
                    name="product_prd"
                    multiple={false}
                    filename={this.state.model.model_id}
                    label="Common.import_prd"
                    description="Common.import_prd_des"
                    onChange={this.onUploadFileLambda.bind(this, ".prd")}
                    tooltipMsg="import_prd"
                    onDelete={this.onDeleteFileLambda.bind(this, ".prd")}
                    />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </div>
        
        <div key='3d_preview'>
          {
            !this.state.isSceneInitialized &&
            <UiButton
              classNameContainer="UiButton_fit_content UiButton_margin_auto"
              aria-expanded={""}
              aria-label={t('')}
              onClick={this.initializeScene.bind(this)}
              icon={''}
              disabled={buttonDisabled}
              label={t('Common.generate_scene')}
            />
          }
          { 
            this.state.isSceneInitialized &&
              <CContainer>
                <UiProductShowcase
                  onDeleteScene={this.destroyScene.bind(this)}
                  onChange={this.handleOnChange.bind(this)}
                  onFocus={this.handleOnFocus.bind(this)}
                  onBlur={this.handleOnBlur.bind(this)}
                  model={this.state.model}
                  hotspots={this.setHotspots.bind(this)}
                  material={this.setMaterial.bind(this)}
                  rotation={this.setRotation.bind(this)}
                  onPivotOffsetChange={this.onPivotOffsetChange.bind(this)}
                  onColorChange={this.handleDomeColorChange.bind(this)}
                  onHotspotDelete={this.deleteHotspot.bind(this)}
                  onFovRangeChange={this.onFovRangeChange.bind(this)}
                  required={this.state.mandatoryFields.indexOf('name') >= 0}
                  disabled={!this.state.editable}
                  cmsView={true}
                  currentUser={this.globalService.currentUser}
                />
              </CContainer>
          }
        </div>

      </>
    )
  }
}

export default connect(mapStateToProps)(withRouter(Product))