import React, { useState, useEffect, useRef, createpo } from 'react';
import { createPortal } from 'react-dom';
import UiCard from "./UiCard";
import { CRow, CCol, CTooltip, CCard, CCardBody, CCardHeader, CCollapse, CFormRange, CDropdownDivider } from '@coreui/react-pro';
import { I18n } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import UiButton from './UiButton';
import UiInput from './UiInput';
import UiRange from './UiRange';
import { CButton} from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';

// chat boutique library imports
import {
    initialize,
    loadProduct,
    previousHotspotView,
    nextHotspotView,
    returnToFreeView,
    // NEED CREATE HOTSPOT MARKER FUNC TO SET ICON IN REAL TIME
    // NEED REMOVE HOTSPOT MARKER AS WELL
    changeDomeColors,
    changeLightSet,
    stopStreaming,
    getCameraTransform,
    resetCameraView,
    setHotspotView,
    toggleCMSCamera,
    setShaderValues,
    setProductRotation,
    setCameraTransform,
    createHotspotMarker,
    setBufferDimension,
    loadEnvironment,
    handleSendCommands,
    removeHotspotMarker,
    toggleEnvironment
  } from "chatboutique_els";
import UiColorPicker from './UiColorPicker';
import ObjectsUtils from '../../utils/objectsUtils';



function UiProductShowcase(props){
    const newHotspotName = useRef("");
    const cameraTransformGoal = useRef("");
    const selectedFov = useRef("");
    const streamingRef = useRef(null);
    const showEnvRef = useRef(false);

    const [state, setState] = useState({
        hotspotName: "",
        isSettingsOpen: false,
        fullScreen: true
    })

    const webRTCInitData = {
        divContainerId: "streaming-container",
        appEnvId: props.currentUser?.appEnvId,
        apiKey: props.currentUser?.appApiKey,
        platform: props.model?.tenant?.platform_id,
        environment: props.model?.tenant?.environment_id,
        cms: props?.cmsView,
        autoHideEnvironment: true,
        hotspotIcon: props.model?.tenant?.tenant_icon_hotspot_id,
        defaultProduct: {
            configuration: {
                fovRange: {
                    min: props.model?.fov_range?.min,
                    max: props.model?.fov_range?.max
                }
            },
            pivotOffset: props.model?.pivot_off_set,
            s3Key: props.model?.model_id,
            hotspots: props.model?.hotspot,
            material: props.model?.material >= 0 ? props.model.material : 1,
            productRotation: props.model?.rotation
        },
        getResponse: (res) => getResponse(res)
    }

    const currentBottomDomeColor = props.model?.colors?.bottom_dome_color ? `rgb(${ObjectsUtils.hexToRgb(props.model?.colors?.bottom_dome_color)})` : "transparent";
    const currentTopDomeColor = props.model?.colors?.top_dome_color ? `rgb(${ObjectsUtils.hexToRgb(props.model?.colors?.top_dome_color)})` : "transparent";

    useEffect(() => {
        let correctInit = cloneDeep(webRTCInitData);

        if (!!props.cmsView){
            correctInit.defaultProduct.hotspots.map((hotspot) => {
                hotspot.targetPosition = hotspot.target_position
                delete hotspot.target_position
            })
        }

        initializeSceneFunc(correctInit);
        
        return () => destroyScene();
    },[])

    useEffect(() => {
        if (document.getElementById("stream")?.srcObject) {
            setBufferDimension(
                streamingRef.current.offsetWidth,
                streamingRef.current.offsetHeight
            );
        }
      
    },[state.fullScreen])
    
    useEffect(() => {
        if (props.model?.model_id && (!props.cmsView)){
            const changeProductRender = {
                s3Key: props.model.model_id,
                hotspots: props.model.hotspot,
                material: props.model.material,
                configuration: {
                    fovRange: {
                        min: props.model?.fov_range?.min,
                        max: props.model?.fov_range?.max
                    }
                },
                pivotOffset: props.model?.pivot_off_set,
                productRotation: props.model.rotation
            }
            loadProduct(changeProductRender);
        }

        if (props.model?.model_id !== 0 && !props.model?.model_id){
            destroyScene();
        }
    }, [props.model?.model_id])

    function toggleFullScreen(){
        setState(prev => (
            {
                ...prev,
                fullScreen: !prev.fullScreen
            }
        ))
    }

    function initializeSceneFunc(webRTCInitData){
        console.log("ISTANZA PARTITA");
        initialize(webRTCInitData);
    }

    // TAKES VALUE OF NEW HOTSPOTNAME
    const getHotspotName = (e) => {
        newHotspotName.current = e.target.value
        setState({
            ...state,
            hotspotName: e.target.value
        })
    }

    // CONTROLS THE RESPONSES FROM 3D LIBRARY EVENTS
    const getResponse = (response) => {
        if (response.eventId === "CAMERA_TRANSFORM"){
            switch (cameraTransformGoal.current){
                case ("ADD_HOTSPOT"):
                    // createHotspotMarker(response) NEED FUNCTION IN LIBRARY ALSO TO REMOVE MARKER
                    response.eventData.name = newHotspotName.current
                    addHotspot(response)
                    break;
                case ("SET_FOV"):
                    setFovRange(response);
                    break;
            }
        }

        if (response.eventId !== "LOG_HISTORY"){
            console.log("GET RESPONSE", response);
        } else {
            console.log("LOG HISTORY", response)
        }
    }

    function getHistory(){
        handleSendCommands({
            eventId: "HISTORY",
        })
    }

    // RENDERS HOTSPOTS IN DB
    const renderHotspots = (hotspot, index) => {
        let initHotspot = cloneDeep(hotspot);

        if (props.cmsView){
            initHotspot.targetPosition = initHotspot?.target_position
            delete initHotspot?.target_position
        }

        return (
            <CRow key={index} className='mb-2'>
                <CCol xs="auto">
                    <UiInput type="text" name="hotspot_name"
                        readOnly
                        disabled={props.disabled}
                        required={props.required}
                        value={initHotspot.name}
                        onFocus={props.onFocus}
                        onBlur={props.onBlur}
                    /> 
                </CCol>

                <br />

                <CCol>
                    <CRow>
                        <CCol xs={props.cmsView ? "6" : "12"} className='flex justify-content-end'>
                            <CButton onClick={goToHotspot(initHotspot, index)}>
                                <CIcon icon="cis-eye" />
                            </CButton>
                        </CCol>
                        {   
                            props.cmsView && (
                            <CCol xs="6" className='flex justify-content-end'>
                                <CButton onClick={deleteHotspost(index)}>
                                    <CIcon icon="cis-trash" />
                                </CButton>
                            </CCol>
                        )}
                    </CRow>

                </CCol>
            </CRow>
        )
    }

    const goToHotspot = (hotspot, index) => () => {
        if (!props.cmsView){
            setHotspotView(index)
        }
        if (props.cmsView){
            let viewHotspot = cloneDeep(hotspot)
            delete viewHotspot.name
            console.log("HOTSPOT: ", viewHotspot);
            setCameraTransform(viewHotspot)
        }
    }

    function handleAddHotspot(){
        cameraTransformGoal.current = "ADD_HOTSPOT";
        getCameraTransform();
    }

    const addHotspot = (response) => {
        props.hotspots(response)
        createHotspotMarker(response.eventData)
        setState({
            ...state,
            hotspotName: "",
            isSettingsOpen: true
        })
        newHotspotName.current = "";
        cameraTransformGoal.current = "";
    }

    const deleteHotspost = (index) => () => {
        let hotspotList = cloneDeep(props.model.hotspot);
        hotspotList.splice(index, 1);
        props.onHotspotDelete(hotspotList);
        removeHotspotMarker(index);
    }

    // FUNCTIONS TO GET VALUES OF SHADERS
    const getMetallic = (e) => {
        props.onChange(e)
    }

    const getRoughness = (e) => {
        props.onChange(e)
    }

    // FUNCTION TO UPDATE SHADERS IN DB AND SEE THE RESULT INSTANTLY
    const setShaders = () => {
        let material = cloneDeep(props.model.material);
        setShaderValues(material);
        props.material();
    }

    // FUNCTIONS TO GET VALUES OF ROTATION
    const getPitch = (e) => {
        props.onChange(e);
    }

    const getYaw = (e) => {
        props.onChange(e);
    }

    const getRoll = (e) => {
        props.onChange(e);
    }

    const setFovRange = (response) => {
        const { targetPosition, lat, lon } = response.eventData;

        handleSendCommands({
            eventId: "SET_CAMERA_TRANSFORM",
            eventData: {
                fov: selectedFov.current,
                targetPosition,
                lat,
                lon
            }
        })

        cameraTransformGoal.current = "";
        selectedFov.current = "";
    }

    const handleFovRangeChange = (e) => {
        cameraTransformGoal.current = "SET_FOV";
        selectedFov.current = e.target.value;
        getCameraTransform();

        const fovRange = {...props.model.fov_range};
        fovRange[e.target.name] = e.target.value;

        props.onFovRangeChange(fovRange);
    }

    // FUNCTION TO UPDATE ROTATION IN DB AND SEE THE RESULT INSTANTLY
    const setRotation = () => {
        let rotation = cloneDeep(props.model.rotation)
        setProductRotation(rotation)
        console.log(rotation)
        props.rotation()
    }

    function changeTopDomeColor(event){
        const { hex } = event;
        const colorObj = { topColor: hex, bottomColor: props.model?.colors?.bottom_dome_color, power: props.model?.colors?.power };
        console.log(colorObj)
        if (props.onColorChange && typeof props.onColorChange === "function"){
            props.onColorChange(colorObj);
        }
        changeDomeColors(colorObj);
    }
    
    function changeBottomDomeColor(event){
        const { hex } = event;
        const colorObj = { topColor: props.model?.colors?.top_dome_color, bottomColor: hex, power: props.model?.colors?.power };
        console.log(colorObj)
        if (props.onColorChange && typeof props.onColorChange === "function"){
            props.onColorChange(colorObj);
        }
        changeDomeColors(colorObj);
    }

    function resetDomeColor(){
        const colorObj = { topColor: "#FFFFFF", bottomColor: "#FFFFFF", power: props.model?.colors?.power };
        if (props.onColorChange && typeof props.onColorChange === "function"){
            props.onColorChange(colorObj);
        }
        changeDomeColors(colorObj);
    }

    function destroyScene(){
        console.log("ISTANZA DISTRUTTA");
        stopStreaming();
    }

    const toggleEnv = () => {
        toggleEnvironment(!showEnvRef.current);
        showEnvRef.current = !showEnvRef.current;
    }

    const freeView = () => {
        returnToFreeView()
    }

    /* const highRes = () => {
        setBufferDimension(2048, 1080)
    }

    const lowRes = () => {
        setBufferDimension(800, 600)
    } */

    const toggleSettings = () => {
        setState(prevState => ({
            ...state,
            isSettingsOpen: !prevState.isSettingsOpen
        }))
    }

    function handleOnPivotOffsetChange(e){
        handleSendCommands({
            eventId: "SET_PRODUCT_PIVOT_OFFSET",
            eventData: {
                offset: e.target.value,
            }
        })

        if (props.onPivotOffsetChange && typeof props.onPivotOffsetChange === "function" && !!props.cmsView){
            props.onPivotOffsetChange(e.target.value);
        }
    }

    const handleChangeLightSet = (direction) => () => {
        handleSendCommands({
            eventId: "CHANGE_LIGHT_SET",
            eventData: {
                set: direction,
            }
        })
    }
    
    return (
        <CRow>
            <CCol sm="12" md="12" lg="12" xl="12">
                <div id='streaming-container' ref={streamingRef} className={`UiProductShowCase${!!state.fullScreen ? " full-screen" : ""}`}>
                    <I18n ns="translations">
                        {t => (
                            <>
                                <CCol sm="auto" style={{position: "absolute", left: "10px", top: "10px", display: "flex", gap: 10, zIndex: 1000}}>
                                    <CButton onClick={toggleFullScreen}>
                                        <CIcon icon={!!state.fullScreen ? "cis-fullscreen-exit" : "cis-fullscreen"}/>
                                    </CButton>
                                    <CButton  onClick={getHistory}>
                                        <CIcon icon={"cib-instapaper"}/>
                                    </CButton>
                                </CCol>
                                <CCol sm="auto" style={{position: "absolute", right: "10px", top: "10px", zIndex: 1000}}>
                                    <CButton onClick={toggleSettings}>
                                        <CIcon icon="cis-menu"/>
                                    </CButton>
                                </CCol>
                                {state.isSettingsOpen && (
                                    <CCol className='UiProductShowCase__settings'>
                                        { props.cmsView &&
                                            <>
                                                <UiCard headerClassName="UiProductShowCase__card__header" cardClassName="UiProductShowCase__card" cardBodyClassName="UiProductShowCase__card__body" title={t('Table.shaders')} collapsible={true} tooltipMsg={t("Tooltips.shader_tip")}>
                                                    <CRow className='mb-2'>
                                                        <CCol sm="12">
                                                            <UiRange
                                                                labelClassName="UiProductShowCase__label"
                                                                name="material.metallic"
                                                                disabled={props.disabled}
                                                                value={props.model?.material?.metallic}
                                                                min={0}
                                                                max={5}
                                                                step={0.1}
                                                                label={t('Table.metallic')}
                                                                onChange={getMetallic}
                                                            />
                                                        </CCol>
                                                    </CRow>

                                                    <CRow className='mb-2'>
                                                        <CCol sm="12">
                                                            <UiRange
                                                                labelClassName="UiProductShowCase__label"
                                                                name="material.roughness"
                                                                disabled={props.disabled}
                                                                value={props.model?.material?.roughness}
                                                                min={0}
                                                                max={5}
                                                                step={0.1}
                                                                label={t('Table.roughness')}
                                                                onChange={getRoughness}
                                                            />
                                                        </CCol>
                                                    </CRow>

                                                    <CRow>
                                                        <CCol>
                                                            <UiButton
                                                                classNameContainer="UiButton_fit_content UiButton_margin_auto"
                                                                size="sm"
                                                                aria-expanded={""}
                                                                aria-label={t('')}
                                                                onClick={setShaders}
                                                                icon={''} label={t('Common.add')}
                                                            />
                                                        </CCol>
                                                    </CRow>
                                                </UiCard>

                                                <UiCard headerClassName="UiProductShowCase__card__header" cardClassName="UiProductShowCase__card" cardBodyClassName="UiProductShowCase__card__body"  title={t('Table.rotation')} collapsible={true} tooltipMsg={t("Tooltips.rotation_tip")}>
                                                    <CRow className='mb-2'>
                                                        <CCol sm="12">
                                                            <UiRange
                                                                labelClassName="UiProductShowCase__label"
                                                                name="rotation.pitch"
                                                                disabled={props.disabled}
                                                                value={props.model?.rotation?.pitch === 0 ? 0 : props.model?.rotation?.pitch}
                                                                min={-180}
                                                                max={180}
                                                                label={t('Table.pitch')}
                                                                onChange={getPitch}
                                                            />
                                                        </CCol>
                                                    </CRow>
                                                    <CRow className='mb-2'>
                                                        <CCol sm="12">
                                                            <UiRange
                                                                labelClassName="UiProductShowCase__label"
                                                                name="rotation.yaw"
                                                                disabled={props.disabled}
                                                                value={props.model?.rotation?.yaw === 0 ? 0 : props.model?.rotation?.yaw}
                                                                min={-180}
                                                                max={180}
                                                                label={t('Table.yaw')}
                                                                onChange={getYaw}
                                                            />
                                                        </CCol>
                                                    </CRow>
                                                    <CRow className='mb-2'>
                                                        <CCol sm="12">
                                                            <UiRange
                                                                labelClassName="UiProductShowCase__label"
                                                                name="rotation.roll"
                                                                disabled={props.disabled}
                                                                value={props.model?.rotation?.roll === 0 ? 0 : props.model?.rotation?.roll}
                                                                min={-180}
                                                                max={180}
                                                                label={t('Table.roll')}
                                                                onChange={getRoll}
                                                            />
                                                        </CCol>
                                                    </CRow>
                                                    <CRow>
                                                        <CCol>
                                                            <UiButton
                                                                classNameContainer="UiButton_fit_content UiButton_margin_auto"
                                                                size="sm"
                                                                aria-expanded={""}
                                                                aria-label={t('')}
                                                                onClick={setRotation}
                                                                icon={''} label={t('Common.add')}
                                                            />
                                                        </CCol>
                                                    </CRow>
                                                </UiCard>
                                            </>
                                        }

                                        <UiCard headerClassName="UiProductShowCase__card__header" cardClassName="UiProductShowCase__card" cardBodyClassName="UiProductShowCase__card__body"  title={t('Table.hotspots')} collapsible={true} tooltipMsg={t("Tooltips.hotspot_tip")}>
                                            {props.cmsView && (
                                                <CRow className='mb-4'>
                                                    <CCol sm="12 mb-2">
                                                        <UiInput size={"sm"} type="text" name="hotspot_name" placeholder="Table.hotspot_name"
                                                            disabled={props.disabled}
                                                            value={state.hotspotName}
                                                            onChange={getHotspotName}
                                                        />
                                                    </CCol>

                                                    <CCol className='flex justify-content-end'>
                                                        <UiButton label="Common.add" onClick={handleAddHotspot} classNameContainer="UiButton_fit_content" />
                                                    </CCol>
                                                </CRow>
                                            )}
                                            {props.model?.hotspot.map(renderHotspots)}
                                        </UiCard>

                                        {props.cmsView && (
                                            <UiCard headerClassName="UiProductShowCase__card__header" cardClassName="UiProductShowCase__card" cardBodyClassName="UiProductShowCase__card__body"  title={t('Table.dome_colors')} collapsible={true} tooltipMsg={t("Tooltips.dome_colors")}>
                                                <CRow className='mb-2'>
                                                    <CCol xs="8">
                                                        <UiColorPicker inline bottom label={t('Table.top_dome_color')} onColorChange={changeTopDomeColor} color={currentTopDomeColor} />
                                                    </CCol>
                                                </CRow>

                                                <CRow>
                                                    <CCol xs="8">
                                                        <UiColorPicker inline bottom label={t('Table.bottom_dome_color')} onColorChange={changeBottomDomeColor} color={currentBottomDomeColor} />
                                                    </CCol>
                                                </CRow>
                                            </UiCard>
                                        )}

                                        <UiCard headerClassName="UiProductShowCase__card__header" cardClassName="UiProductShowCase__card" cardBodyClassName="UiProductShowCase__card__body"  title={t('Table.general_settings')} collapsible={true} tooltipMsg={t("Tooltips.general_tip")}>
                                            <CRow>
                                                <CCol sm="12" className='mb-1'>
                                                    <UiButton label="Common.toggle_env" onClick={toggleEnv}/>
                                                </CCol>
                                                <CCol sm="12" className='mb-1'>
                                                    <UiButton label="Common.reset_dome_colors" onClick={resetDomeColor}/>
                                                </CCol>
                                                {!props.cmsView && (
                                                    <CCol sm="12" className='mb-1'>
                                                        <UiButton label="Common.return_to_freeview" onClick={freeView}/>
                                                    </CCol>
                                                )}
                                            </CRow>
                                        </UiCard>

                                        {props.cmsView && (
                                            <>
                                                <UiCard headerClassName="UiProductShowCase__card__header" cardClassName="UiProductShowCase__card" cardBodyClassName="UiProductShowCase__card__body"  title={t('Table.fov_range')} collapsible={true} tooltipMsg={t("Tooltips.general_tip")}>
                                                    <CRow className='mb-2'>
                                                        <CCol>
                                                            <UiRange
                                                                className={"ciaooo"}
                                                                labelClassName="UiProductShowCase__label"
                                                                name="min"
                                                                disabled={props.disabled}
                                                                value={props.model?.fov_range?.min}
                                                                min={2}
                                                                max={props.model?.fov_range?.max}
                                                                label={t('Table.fov_range_min')}
                                                                onChange={handleFovRangeChange}
                                                            />
                                                        </CCol>
                                                    </CRow>

                                                    <CRow>
                                                        <CCol>
                                                            <UiRange
                                                                labelClassName="UiProductShowCase__label"
                                                                name="max"
                                                                disabled={props.disabled}
                                                                value={props.model?.fov_range?.max}
                                                                min={props.model?.fov_range?.min}
                                                                max={90}
                                                                label={t('Table.fov_range_max')}
                                                                onChange={handleFovRangeChange}
                                                            />
                                                        </CCol>
                                                    </CRow>
                                                </UiCard>

                                                <UiCard
                                                    headerClassName="UiProductShowCase__card__header"
                                                    cardClassName="UiProductShowCase__card" cardBodyClassName="UiProductShowCase__card__body" 
                                                    className="colored-header"
                                                    title={t('Table.pivot_offset')}
                                                    collapsible={true}
                                                    tooltipMsg={t("Tooltips.pivot_offset")}
                                                >
                                                    <CRow>
                                                        <CCol sm="12">
                                                            <UiRange
                                                                name="pivot_off_set"
                                                                disabled={props.disabled}
                                                                value={props.model?.pivot_off_set}
                                                                min={0}
                                                                max={150}
                                                                onChange={handleOnPivotOffsetChange}
                                                            />
                                                        </CCol>
                                                    </CRow>
                                                </UiCard>
                                            </>
                                        )}

                                        
                                        
                                        <UiCard
                                            headerClassName="UiProductShowCase__card__header"
                                            cardClassName="UiProductShowCase__card" cardBodyClassName="UiProductShowCase__card__body" 
                                            className="colored-header"
                                            title={t('Table.scene_light')}
                                            collapsible={true}
                                            tooltipMsg={t("Tooltips.scene_light")}
                                        >
                                            <CRow className='mb-2'>
                                                    <UiButton label="Common.prev" onClick={handleChangeLightSet("PREVIOUS")}/>
                                            </CRow>

                                            <CRow>
                                                    <UiButton label="Common.next" onClick={handleChangeLightSet("NEXT")}/>
                                            </CRow>
                                        </UiCard>
                                    </CCol>
                                )}
                            </>
                        )}
                    </I18n>
                </div>
            </CCol>
        </CRow>
    )
                                
}

export default UiProductShowcase