export default class ConstantsService {
  static autosaveEnabled = false;

  static defaultDBIdentifier = 'id';

  static defaultLanguage = 'it';

  static languagesList = ['en', 'it'];

  static itemsGridNumber = [2, 5, 10, 25, 50];

  static defaultItemsGridNumber = 25;
  
  static pageForAutocomplete = 30;
  
  static yes_no = [ { label: 'yes', value: 'yes' }, { label: 'no', value: 'no' } ];

  static productStatus = [ "draft", "completed", "approved", "published" ];

  static sceneLightSets = [ "3PL_Neutral", "3PL_Warm", "3PL_Cold", "Const_Neutral", "Const_Warm", "Const_Cold" ];

  static accountType = [
    {label: "Platinum", value: "PLATINUM"},
    {label: "Gold", value: "GOLD"},
    {label: "Silver", value: "SILVER"},
    {label: "Bronze", value: "BRONZE"},
  ]

  static gender_values = [
    { label: 'man', value: 'm' },
    { label: 'woman', value: 'f' },
    // { label: 'other', value: 'o' },
  ]

  static contactType = [
    {label: "administrative_contact", value: "ADMIN"},
    {label: "technical_contact", value: "TECH"}
  ]

  static addressType = [
    {label: "billing_address", value: "BILLING"},
    {label: "office_address", value: "ADDRESS"}
  ]

  static defaultRoles = ["super_admin", "admin", "sales_assistant", "user", "guest"];

  static countries = [
    { alpha_2_code: 'AF', alpha_3_code: 'AFG', name: 'Afghanistan' },
    { alpha_2_code: 'AX', alpha_3_code: 'ALA', name: 'Aland islands' },
    { alpha_2_code: 'AL', alpha_3_code: 'ALB', name: 'Albania' },
    { alpha_2_code: 'DZ', alpha_3_code: 'DZA', name: 'Algeria' },
    { alpha_2_code: 'AS', alpha_3_code: 'ASM', name: 'American Samoa' },
    { alpha_2_code: 'AD', alpha_3_code: 'AND', name: 'Andorra' },
    { alpha_2_code: 'AO', alpha_3_code: 'AGO', name: 'Angola' },
    { alpha_2_code: 'AI', alpha_3_code: 'AIA', name: 'Anguilla' },
    { alpha_2_code: 'AQ', alpha_3_code: 'ATA', name: 'Antarctica' },
    { alpha_2_code: 'AM', alpha_3_code: 'ARM', name: 'Armenia' },
    { alpha_2_code: 'AW', alpha_3_code: 'ABW', name: 'Aruba' },
    { alpha_2_code: 'AU', alpha_3_code: 'AUS', name: 'Australia' },
    { alpha_2_code: 'AT', alpha_3_code: 'AUT', name: 'Austria' },
    { alpha_2_code: 'AZ', alpha_3_code: 'AZE', name: 'Azerbaijan' },
    { alpha_2_code: 'BS', alpha_3_code: 'BHS', name: 'Bahamas' },
    { alpha_2_code: 'BH', alpha_3_code: 'BHR', name: 'Bahrain' },
    { alpha_2_code: 'BD', alpha_3_code: 'BGD', name: 'Bangladesh' },
    { alpha_2_code: 'BB', alpha_3_code: 'BRB', name: 'Barbados' },
    { alpha_2_code: 'BY', alpha_3_code: 'BLR', name: 'Belarus' },
    { alpha_2_code: 'BE', alpha_3_code: 'BEL', name: 'Belgium' },
    { alpha_2_code: 'BZ', alpha_3_code: 'BLZ', name: 'Belize' },
    { alpha_2_code: 'BJ', alpha_3_code: 'BEN', name: 'Benin' },
    { alpha_2_code: 'BM', alpha_3_code: 'BMU', name: 'Bermuda' },
    { alpha_2_code: 'BT', alpha_3_code: 'BTN', name: 'Bhutan' },
    { alpha_2_code: 'BO', alpha_3_code: 'BOL', name: 'Bolivia' },
    { alpha_2_code: 'BA', alpha_3_code: 'BIH', name: 'Bosnia and Herzegovina' },
    { alpha_2_code: 'BW', alpha_3_code: 'BWA', name: 'Botswana' },
    { alpha_2_code: 'BV', alpha_3_code: 'BVT', name: 'Bouvet Island' },
    { alpha_2_code: 'BR', alpha_3_code: 'BRA', name: 'Brazil' },
    { alpha_2_code: 'IO', alpha_3_code: 'IOT', name: 'British Indian Ocean Territory' },
    { alpha_2_code: 'BN', alpha_3_code: 'BRN', name: 'Brunei Darussalam' },
    { alpha_2_code: 'BG', alpha_3_code: 'BGR', name: 'Bulgaria' },
    { alpha_2_code: 'BF', alpha_3_code: 'BFA', name: 'Burkina Faso' },
    { alpha_2_code: 'BI', alpha_3_code: 'BDI', name: 'Burundi' },
    { alpha_2_code: 'CV', alpha_3_code: 'CPV', name: 'Cabo Verde' },
    { alpha_2_code: 'KH', alpha_3_code: 'KHM', name: 'Cambodia' },
    { alpha_2_code: 'CM', alpha_3_code: 'CMR', name: 'Cameroon' },
    { alpha_2_code: 'CA', alpha_3_code: 'CAN', name: 'Canada' },
    { alpha_2_code: 'KY', alpha_3_code: 'CYM', name: 'Cayman Islands' },
    { alpha_2_code: 'CF', alpha_3_code: 'CAF', name: 'Central African Republic' },
    { alpha_2_code: 'TD', alpha_3_code: 'TCD', name: 'Chad' },
    { alpha_2_code: 'CL', alpha_3_code: 'CHL', name: 'Chile' },
    { alpha_2_code: 'CN', alpha_3_code: 'CHN', name: 'China' },
    { alpha_2_code: 'CX', alpha_3_code: 'CXR', name: 'Christmas Island' },
    { alpha_2_code: 'CC', alpha_3_code: 'CCK', name: 'Cocos Islands' },
    { alpha_2_code: 'CO', alpha_3_code: 'COL', name: 'Colombia' },
    { alpha_2_code: 'KM', alpha_3_code: 'COM', name: 'Comoros' },
    { alpha_2_code: 'CD', alpha_3_code: 'COD', name: 'The Democratic Republic Of Congo' },
    { alpha_2_code: 'CG', alpha_3_code: 'COG', name: 'The Republic Of Congo' },
    { alpha_2_code: 'CK', alpha_3_code: 'COK', name: 'Cook Islands' },
    { alpha_2_code: 'CR', alpha_3_code: 'CRI', name: 'Costa Rica' },
    { alpha_2_code: 'CI', alpha_3_code: 'CIV', name: 'Cote d\'Ivoire' },
    { alpha_2_code: 'HR', alpha_3_code: 'HRV', name: 'Croatia' },
    { alpha_2_code: 'CU', alpha_3_code: 'CUB', name: 'Cuba' },
    { alpha_2_code: 'CW', alpha_3_code: 'CUW', name: 'Curacao' },
    { alpha_2_code: 'CY', alpha_3_code: 'CYP', name: 'Cyprus' },
    { alpha_2_code: 'CZ', alpha_3_code: 'CZE', name: 'Czech Republic' },
    { alpha_2_code: 'DK', alpha_3_code: 'DNK', name: 'Denmark' },
    { alpha_2_code: 'DJ', alpha_3_code: 'DJI', name: 'Djibouti' },
    { alpha_2_code: 'DM', alpha_3_code: 'DMA', name: 'Dominica' },
    { alpha_2_code: 'DO', alpha_3_code: 'DOM', name: 'Dominican Republic' },
    { alpha_2_code: 'EC', alpha_3_code: 'ECU', name: 'Ecuador' },
    { alpha_2_code: 'EG', alpha_3_code: 'EGY', name: 'Egypt' },
    { alpha_2_code: 'SV', alpha_3_code: 'SLV', name: 'El Salvador' },
    { alpha_2_code: 'GQ', alpha_3_code: 'GNQ', name: 'Equatorial Guinea' },
    { alpha_2_code: 'ER', alpha_3_code: 'ERI', name: 'Eritrea' },
    { alpha_2_code: 'EE', alpha_3_code: 'EST', name: 'Estonia' },
    { alpha_2_code: 'SZ', alpha_3_code: 'SWZ', name: 'Eswatini' },
    { alpha_2_code: 'ET', alpha_3_code: 'ETH', name: 'Ethiopia' },
    { alpha_2_code: 'FK', alpha_3_code: 'FLK', name: 'Falkland Islands' },
    { alpha_2_code: 'FO', alpha_3_code: 'FRO', name: 'Faroe Islands' },
    { alpha_2_code: 'FJ', alpha_3_code: 'FJI', name: 'Fiji' },
    { alpha_2_code: 'FI', alpha_3_code: 'FIN', name: 'Finland' },
    { alpha_2_code: 'FR', alpha_3_code: 'FRA', name: 'France' },
    { alpha_2_code: 'GF', alpha_3_code: 'GUF', name: 'French Guiana' },
    { alpha_2_code: 'PF', alpha_3_code: 'PYF', name: 'French Polynesia' },
    { alpha_2_code: 'TF', alpha_3_code: 'ATF', name: 'French Southern Territories' },
    { alpha_2_code: 'GA', alpha_3_code: 'GAB', name: 'Gabon' },
    { alpha_2_code: 'GM', alpha_3_code: 'GMB', name: 'Gambia' },
    { alpha_2_code: 'GE', alpha_3_code: 'GEO', name: 'Georgia' },
    { alpha_2_code: 'DE', alpha_3_code: 'DEU', name: 'Germany' },
    { alpha_2_code: 'GH', alpha_3_code: 'GHA', name: 'Ghana' },
    { alpha_2_code: 'GI', alpha_3_code: 'GIB', name: 'Gibraltar' },
    { alpha_2_code: 'GB', alpha_3_code: 'GBR', name: 'Great Britain' },
    { alpha_2_code: 'GR', alpha_3_code: 'GRC', name: 'Greece' },
    { alpha_2_code: 'GL', alpha_3_code: 'GRL', name: 'Greenland' },
    { alpha_2_code: 'GD', alpha_3_code: 'GRD', name: 'Grenada' },
    { alpha_2_code: 'GP', alpha_3_code: 'GLP', name: 'Guadeloupe' },
    { alpha_2_code: 'GU', alpha_3_code: 'GUM', name: 'Guam' },
    { alpha_2_code: 'GT', alpha_3_code: 'GTM', name: 'Guatemala' },
    { alpha_2_code: 'GG', alpha_3_code: 'GGY', name: 'Guernsey' },
    { alpha_2_code: 'GN', alpha_3_code: 'GIN', name: 'Guinea' },
    { alpha_2_code: 'GW', alpha_3_code: 'GNB', name: 'Guinea-bissau' },
    { alpha_2_code: 'GY', alpha_3_code: 'GUY', name: 'Guyana' },
    { alpha_2_code: 'HT', alpha_3_code: 'HTI', name: 'Haiti' },
    { alpha_2_code: 'HM', alpha_3_code: 'HMD', name: 'Heard Island and McDonald Islands' },
    { alpha_2_code: 'HN', alpha_3_code: 'HND', name: 'Honduras' },
    { alpha_2_code: 'HK', alpha_3_code: 'HKG', name: 'Honk Kong' },
    { alpha_2_code: 'HU', alpha_3_code: 'HUN', name: 'Hungary' },
    { alpha_2_code: 'IS', alpha_3_code: 'ISL', name: 'Iceland' },
    { alpha_2_code: 'IN', alpha_3_code: 'IND', name: 'India' },
    { alpha_2_code: 'ID', alpha_3_code: 'IDN', name: 'Indonesia' },
    { alpha_2_code: 'IR', alpha_3_code: 'IRN', name: 'Iran' },
    { alpha_2_code: 'IQ', alpha_3_code: 'IRQ', name: 'Iraq' },
    { alpha_2_code: 'IE', alpha_3_code: 'IRL', name: 'Ireland' },
    { alpha_2_code: 'IM', alpha_3_code: 'IMN', name: 'Isle of Man' },
    { alpha_2_code: 'IL', alpha_3_code: 'ISR', name: 'Israel' },
    { alpha_2_code: 'IT', alpha_3_code: 'ITA', name: 'Italy' },
    { alpha_2_code: 'CI', alpha_3_code: 'CIV', name: 'Ivory Coast' },
    { alpha_2_code: 'JM', alpha_3_code: 'JAM', name: 'Jamaica' },
    { alpha_2_code: 'JP', alpha_3_code: 'JPN', name: 'Japan' },
    { alpha_2_code: 'JE', alpha_3_code: 'JEY', name: 'Jersey' },
    { alpha_2_code: 'JO', alpha_3_code: 'JOR', name: 'Jordan' },
    { alpha_2_code: 'KZ', alpha_3_code: 'KAZ', name: 'Kazakhstan' },
    { alpha_2_code: 'KE', alpha_3_code: 'KEN', name: 'Kenya' },
    { alpha_2_code: 'KI', alpha_3_code: 'KIR', name: 'Kiribati' },
    { alpha_2_code: 'KP', alpha_3_code: 'PRK', name: 'North Korea' },
    { alpha_2_code: 'KR', alpha_3_code: 'KOR', name: 'South Korea' },
    { alpha_2_code: 'KW', alpha_3_code: 'KWT', name: 'Kuwait' },
    { alpha_2_code: 'KG', alpha_3_code: 'KGZ', name: 'Kyrgyzstan' },
    { alpha_2_code: 'LA', alpha_3_code: 'LAO', name: 'Lao people\'s Demoratic Republic' },
    { alpha_2_code: 'LV', alpha_3_code: 'LVA', name: 'Latvia' },
    { alpha_2_code: 'LB', alpha_3_code: 'LBN', name: 'Lebanon' },
    { alpha_2_code: 'LS', alpha_3_code: 'LSO', name: 'Lesotho' },
    { alpha_2_code: 'LR', alpha_3_code: 'LBR', name: 'Liberia' },
    { alpha_2_code: 'LY', alpha_3_code: 'LBY', name: 'Libya' },
    { alpha_2_code: 'LI', alpha_3_code: 'LIE', name: 'Liechtenstein' },
    { alpha_2_code: 'LT', alpha_3_code: 'LTU', name: 'Lithuania' },
    { alpha_2_code: 'LU', alpha_3_code: 'LUX', name: 'Luxembourg' },
    { alpha_2_code: 'MO', alpha_3_code: 'MAC', name: 'Macao' },
    { alpha_2_code: 'MK', alpha_3_code: 'MKD', name: 'North Macedonia' },
    { alpha_2_code: 'MG', alpha_3_code: 'MDG', name: 'Madagascar' },
    { alpha_2_code: 'MW', alpha_3_code: 'MWI', name: 'Malawi' },
    { alpha_2_code: 'MY', alpha_3_code: 'MYS', name: 'Malaysia' },
    { alpha_2_code: 'MV', alpha_3_code: 'MDV', name: 'Maldives' },
    { alpha_2_code: 'ML', alpha_3_code: 'MLI', name: 'Mali' },
    { alpha_2_code: 'MT', alpha_3_code: 'MLT', name: 'Malta' },
    { alpha_2_code: 'MH', alpha_3_code: 'MHL', name: 'Marshall Islands' },
    { alpha_2_code: 'MQ', alpha_3_code: 'MTQ', name: 'Martinique' },
    { alpha_2_code: 'MR', alpha_3_code: 'MRT', name: 'Mauritania' },
    { alpha_2_code: 'MU', alpha_3_code: 'MUS', name: 'Mauritius' },
    { alpha_2_code: 'YT', alpha_3_code: 'MYT', name: 'Mayotte' },
    { alpha_2_code: 'MX', alpha_3_code: 'MEX', name: 'Mexico' },
    { alpha_2_code: 'FM', alpha_3_code: 'FSM', name: 'Micronesia' },
    { alpha_2_code: 'MD', alpha_3_code: 'MDA', name: 'Moldova' },
    { alpha_2_code: 'MC', alpha_3_code: 'MCO', name: 'Monaco' },
    { alpha_2_code: 'MN', alpha_3_code: 'MNG', name: 'Mongolia' },
    { alpha_2_code: 'ME', alpha_3_code: 'MNE', name: 'Montenegro' },
    { alpha_2_code: 'MS', alpha_3_code: 'MSR', name: 'Montserrat' },
    { alpha_2_code: 'MA', alpha_3_code: 'MAR', name: 'Morocco' },
    { alpha_2_code: 'MZ', alpha_3_code: 'MOZ', name: 'Mozambique' },
    { alpha_2_code: 'MM', alpha_3_code: 'MMR', name: 'Myanmar' },
    { alpha_2_code: 'NA', alpha_3_code: 'NAM', name: 'Namibia' },
    { alpha_2_code: 'NR', alpha_3_code: 'NRU', name: 'Nauru' },
    { alpha_2_code: 'NP', alpha_3_code: 'NPL', name: 'Nepal' },
    { alpha_2_code: 'NL', alpha_3_code: 'NLD', name: 'Netherlands' },
    { alpha_2_code: 'NC', alpha_3_code: 'NCL', name: 'New Caledonia' },
    { alpha_2_code: 'NZ', alpha_3_code: 'NZL', name: 'New Zealand' },
    { alpha_2_code: 'NI', alpha_3_code: 'NIC', name: 'Nicaragua' },
    { alpha_2_code: 'NE', alpha_3_code: 'NER', name: 'Niger' },
    { alpha_2_code: 'NG', alpha_3_code: 'NGA', name: 'Nigeria' },
    { alpha_2_code: 'NU', alpha_3_code: 'NIU', name: 'Niue' },
    { alpha_2_code: 'NF', alpha_3_code: 'NFK', name: 'Norfolk Island' },
    { alpha_2_code: 'MP', alpha_3_code: 'MNP', name: 'Northern Mariana Islands' },
    { alpha_2_code: 'NO', alpha_3_code: 'NOR', name: 'Norway' },
    { alpha_2_code: 'OM', alpha_3_code: 'OMN', name: 'Oman' },
    { alpha_2_code: 'PK', alpha_3_code: 'PAK', name: 'Pakistan' },
    { alpha_2_code: 'PW', alpha_3_code: 'PLW', name: 'Palau' },
    { alpha_2_code: 'PS', alpha_3_code: 'PSE', name: 'Palestine' },
    { alpha_2_code: 'PA', alpha_3_code: 'PAN', name: 'Panama' },
    { alpha_2_code: 'PG', alpha_3_code: 'PNG', name: 'Papua New Guinea' },
    { alpha_2_code: 'PY', alpha_3_code: 'PRY', name: 'Paraguay' },
    { alpha_2_code: 'PE', alpha_3_code: 'PER', name: 'Peru' },
    { alpha_2_code: 'PH', alpha_3_code: 'PHL', name: 'Philippines' },
    { alpha_2_code: 'PN', alpha_3_code: 'PCN', name: 'Pitcairn' },
    { alpha_2_code: 'PL', alpha_3_code: 'POL', name: 'Poland' },
    { alpha_2_code: 'PT', alpha_3_code: 'PRT', name: 'Portugal' },
    { alpha_2_code: 'PR', alpha_3_code: 'PRI', name: 'Puerto Rico' },
    { alpha_2_code: 'QA', alpha_3_code: 'QAT', name: 'Qatar' },
    { alpha_2_code: 'RE', alpha_3_code: 'REU', name: 'Réunion' },
    { alpha_2_code: 'RO', alpha_3_code: 'ROU', name: 'Romania' },
    { alpha_2_code: 'RU', alpha_3_code: 'RUS', name: 'The Russian Federation' },
    { alpha_2_code: 'RW', alpha_3_code: 'RWA', name: 'Rwanda' },
    { alpha_2_code: 'BL', alpha_3_code: 'BLM', name: 'Saint Barthélemy' },
    { alpha_2_code: 'SH', alpha_3_code: 'SHN', name: 'Saint Helena, Ascension and Tristan de Cunha' },
    { alpha_2_code: 'KN', alpha_3_code: 'KNA', name: 'Saint Kitts and Nevis' },
    { alpha_2_code: 'LC', alpha_3_code: 'LCA', name: 'Saint Lucia' },
    { alpha_2_code: 'MF', alpha_3_code: 'MAF', name: 'Saint Martin' },
    { alpha_2_code: 'PM', alpha_3_code: 'SPM', name: 'Saint Pierre and Miquelon' },
    { alpha_2_code: 'VC', alpha_3_code: 'VCT', name: 'Saint Vincent and the Grenadines' },
    { alpha_2_code: 'WS', alpha_3_code: 'WSM', name: 'Samoa' },
    { alpha_2_code: 'SM', alpha_3_code: 'SMR', name: 'San Marino' },
    { alpha_2_code: 'ST', alpha_3_code: 'STP', name: 'Sao Tome and Principe' },
    { alpha_2_code: 'SA', alpha_3_code: 'SAU', name: 'Saudi Arabia' },
    { alpha_2_code: 'SN', alpha_3_code: 'SEN', name: 'Senegal' },
    { alpha_2_code: 'RS', alpha_3_code: 'SRB', name: 'Serbia' },
    { alpha_2_code: 'SC', alpha_3_code: 'SYC', name: 'Seychelles' },
    { alpha_2_code: 'SL', alpha_3_code: 'SLE', name: 'Sierra Leone' },
    { alpha_2_code: 'SG', alpha_3_code: 'SGP', name: 'Singapore' },
    { alpha_2_code: 'SX', alpha_3_code: 'SXM', name: 'Sint Maarten' },
    { alpha_2_code: 'SK', alpha_3_code: 'SVK', name: 'Slovakia' },
    { alpha_2_code: 'SI', alpha_3_code: 'SVN', name: 'Slovenia' },
    { alpha_2_code: 'SB', alpha_3_code: 'SLB', name: 'Solomon islands' },
    { alpha_2_code: 'SO', alpha_3_code: 'SOM', name: 'Somalia' },
    { alpha_2_code: 'ZA', alpha_3_code: 'ZAF', name: 'South Africa' },
    { alpha_2_code: 'GS', alpha_3_code: 'SGS', name: 'South Georgia and the South Sandwich Islands' },
    { alpha_2_code: 'SS', alpha_3_code: 'SSD', name: 'South Sudan' },
    { alpha_2_code: 'ES', alpha_3_code: 'ESP', name: 'Spain' },
    { alpha_2_code: 'LK', alpha_3_code: 'LKA', name: 'Sri Lanka' },
    { alpha_2_code: 'SD', alpha_3_code: 'SDN', name: 'Sudan' },
    { alpha_2_code: 'SR', alpha_3_code: 'SUR', name: 'Suriname' },
    { alpha_2_code: 'SJ', alpha_3_code: 'SJM', name: 'Svalbard and Jan Mayen' },
    { alpha_2_code: 'SE', alpha_3_code: 'SWE', name: 'Sweden' },
    { alpha_2_code: 'CH', alpha_3_code: 'CHE', name: 'Switzerland' },
    { alpha_2_code: 'SY', alpha_3_code: 'SYR', name: 'Syrian Arab Republic' },
    { alpha_2_code: 'TW', alpha_3_code: 'TWN', name: 'Taiwan' },
    { alpha_2_code: 'TJ', alpha_3_code: 'TJK', name: 'Tajikistan' },
    { alpha_2_code: 'TZ', alpha_3_code: 'TZA', name: 'Tanzania' },
    { alpha_2_code: 'TH', alpha_3_code: 'THA', name: 'Thailand' },
    { alpha_2_code: 'TL', alpha_3_code: 'TLS', name: 'Timor-Leste' },
    { alpha_2_code: 'TG', alpha_3_code: 'TGO', name: 'Togo' },
    { alpha_2_code: 'TK', alpha_3_code: 'TKL', name: 'Tokelau' },
    { alpha_2_code: 'TO', alpha_3_code: 'TON', name: 'Tonga' },
    { alpha_2_code: 'TT', alpha_3_code: 'TTO', name: 'Trinidad and Tobago' },
    { alpha_2_code: 'TN', alpha_3_code: 'TUN', name: 'Tunisia' },
    { alpha_2_code: 'TR', alpha_3_code: 'TUR', name: 'Turkey' },
    { alpha_2_code: 'TM', alpha_3_code: 'TKM', name: 'Turkmenistan' },
    { alpha_2_code: 'TC', alpha_3_code: 'TCA', name: 'Turks and Calcos Islands' },
    { alpha_2_code: 'UG', alpha_3_code: 'UGA', name: 'Uganda' },
    { alpha_2_code: 'UA', alpha_3_code: 'UKS', name: 'Ukraine' },
    { alpha_2_code: 'AE', alpha_3_code: 'ARE', name: 'United Arab Emirates' },
    { alpha_2_code: 'GB', alpha_3_code: 'GBR', name: 'United Kingdom of Great Britain and Northern Ireland' },
    { alpha_2_code: 'UM', alpha_3_code: 'UMI', name: 'United States Minor Outlying Islands' },
    { alpha_2_code: 'US', alpha_3_code: 'USA', name: 'United State of America' },
    { alpha_2_code: 'UY', alpha_3_code: 'URY', name: 'Uruguay' },
    { alpha_2_code: 'UZ', alpha_3_code: 'UZB', name: 'Uzbekistan' },
    { alpha_2_code: 'VU', alpha_3_code: 'VUT', name: 'Vanuatu' },
    { alpha_2_code: 'VE', alpha_3_code: 'VEN', name: 'Venezuale' },
    { alpha_2_code: 'VN', alpha_3_code: 'VNM', name: 'Vietnam' },
    { alpha_2_code: 'VG', alpha_3_code: 'VGB', name: 'Virgin Islands' },
    { alpha_2_code: 'VI', alpha_3_code: 'VIR', name: 'Virgin Islands of United States' },
    { alpha_2_code: 'WF', alpha_3_code: 'WLF', name: 'Wallis and Futuna' },
    { alpha_2_code: 'EH', alpha_3_code: 'ESH', name: 'Western Sahara' },
    { alpha_2_code: 'YE', alpha_3_code: 'YEM', name: 'Yemen' },
    { alpha_2_code: 'ZM', alpha_3_code: 'ZMB', name: 'Zambia' },
    { alpha_2_code: 'ZW', alpha_3_code: 'ZWE', name: 'Zimbawe' }
  ]

  static currencies = [
    { id: 'EUR', label: 'Euro - EUR', value: '€' },
    { id: 'USD', label: 'Dollaro - USD', value: '$' },
    { id: 'CAD', label: 'Dollaro canadese - CAD', value: '$-CAD' },
    { id: 'CHF', label: 'Franco svizzero - CHF', value: 'CHF' },
    { id: 'GBP', label: 'Sterlina inglese - GBP', value: '£' },
    { id: 'BGN', label: 'Lev bulgaro - BGN', value: 'лв' },
    { id: 'CZK', label: 'Corona ceca - CZK', value: 'Kc' },
    { id: 'DKK', label: 'Corona danese - DKK', value: 'kr-DKK' },
    { id: 'NOK', label: 'Corona norvegese - NOK', value: 'kr-NOK' },
    { id: 'HUF', label: 'Fiorino ungherese - HUF', value: 'Ft' },
    { id: 'HRK', label: 'Kuna croata - HRK', value: 'kn' },
    { id: 'PLN', label: 'Złoty polacco - PLN', value: 'zł' },
    { id: 'RON', label: 'Leu rumeno - RON', value: 'lei' },
    { id: 'SEK', label: 'Corona svedese - SEK', value: 'kr-SEK' },
  ];

  static italyZones = ['nord', 'center', 'sud', 'islands']

  static vat = [
    { label: '0%', value: 0 },
    { label: '4%', value: 4 },
    { label: '10%', value: 10 },
    { label: '22%', value: 22 },
  ];

  static image_types = [
    { label: 'Fronte', value: 'front' },
    { label: 'Retro', value: 'back' },
    { label: 'Lato', value: 'side' },
  ];

  static academy_types = [
    { label: 'Front-end', value: 'FE' },
    { label: 'Back-end', value: 'BE' },
  ];

  static job_types = [
    { label: 'Front End', value: 'front end' },
    { label: 'Back End', value: 'Backend' },
    { label: 'Full Stack', value: 'full stack' },
    { label: 'Insegnante', value: 'insegnate academy' },
    { label: 'Mobile', value: 'mobile' },
  ];

  static mode_types = [
    { label: 'Hybrid', value: 'hybrid' },
    { label: 'Milan', value: 'milan' },
    { label: 'Remote', value: 'remote' },
  ];

  static case_study_types = [
    { label: 'up', value: 1 },
    { label: 'academy', value: 2 },
  ];

  static iso6933LangList = [
    {
      id: 37, iso2: 'en', iso3: 'eng', name: 'English', icon: 'gb'
    },
    {
      id: 73, iso2: 'it', iso3: 'ita', name: 'Italian', icon: 'it'
    }
  ];

  static iso6933LangExtList = [
    {
      id: 7, iso2: 'ar', iso3: 'ara', name: 'Arabic',
    },
    {
      id: 13, iso2: 'be', iso3: 'bel', name: 'Belarusian',
    },
    {
      id: 14, iso2: 'bg', iso3: 'bul', name: 'Bulgarian',
    },
    {
      id: 22, iso2: 'ca', iso3: 'cat', name: 'Catalan',
    },
    {
      id: 27, iso2: 'cs', iso3: 'ces', name: 'Czech',
    },
    {
      id: 31, iso2: 'da', iso3: 'dan', name: 'Danish',
    },
    {
      id: 32, iso2: 'de', iso3: 'deu', name: 'German',
    },
    {
      id: 36, iso2: 'el', iso3: 'ell', name: 'Greek',
    },
    {
      id: 37, iso2: 'en', iso3: 'eng', name: 'English',
    },
    {
      id: 39, iso2: 'es', iso3: 'spa', name: 'Spanish',
    },
    {
      id: 40, iso2: 'et', iso3: 'est', name: 'Estonian',
    },
    {
      id: 44, iso2: 'fi', iso3: 'fin', name: 'Finnish',
    },
    {
      id: 47, iso2: 'fr', iso3: 'fra', name: 'French',
    },
    {
      id: 49, iso2: 'ga', iso3: 'gle', name: 'Irish',
    },
    {
      id: 56, iso2: 'iw', iso3: 'heb', name: 'Hebrew',
    },
    {
      id: 57, iso2: 'hi', iso3: 'hin', name: 'Hindi',
    },
    {
      id: 59, iso2: 'hr', iso3: 'hrv', name: 'Croatian',
    },
    {
      id: 61, iso2: 'hu', iso3: 'hun', name: 'Hungarian',
    },
    {
      id: 65, iso2: 'in', iso3: 'ind', name: 'Indonesian',
    },
    {
      id: 72, iso2: 'is', iso3: 'isl', name: 'Icelandic',
    },
    {
      id: 73, iso2: 'it', iso3: 'ita', name: 'Italian',
    },
    {
      id: 76, iso2: 'ja', iso3: 'jpn', name: 'Japanese',
    },
    {
      id: 87, iso2: 'ko', iso3: 'kor', name: 'Korean',
    },
    {
      id: 100, iso2: 'lt', iso3: 'lit', name: 'Lithuanian',
    },
    {
      id: 102, iso2: 'lv', iso3: 'lav', name: 'Latvian',
    },
    {
      id: 106, iso2: 'mk', iso3: 'mkd', name: 'Macedonian',
    },
    {
      id: 111, iso2: 'ms', iso3: 'msa', name: 'Malay',
    },
    {
      id: 112, iso2: 'mt', iso3: 'mlt', name: 'Maltese',
    },
    {
      id: 119, iso2: 'nl', iso3: 'nld', name: 'Dutch',
    },
    {
      id: 121, iso2: 'no', iso3: 'nor', name: 'Norwegian',
    },
    {
      id: 132, iso2: 'pl', iso3: 'pol', name: 'Polish',
    },
    {
      id: 134, iso2: 'pt', iso3: 'por', name: 'Portuguese',
    },
    {
      id: 138, iso2: 'ro', iso3: 'ron', name: 'Romanian',
    },
    {
      id: 139, iso2: 'ru', iso3: 'rus', name: 'Russian',
    },
    {
      id: 147, iso2: 'sk', iso3: 'slk', name: 'Slovak',
    },
    {
      id: 148, iso2: 'sl', iso3: 'slv', name: 'Slovenian',
    },
    {
      id: 152, iso2: 'sq', iso3: 'sqi', name: 'Albanian',
    },
    {
      id: 153, iso2: 'sr', iso3: 'srp', name: 'Serbian',
    },
    {
      id: 157, iso2: 'sv', iso3: 'swe', name: 'Swedish',
    },
    {
      id: 162, iso2: 'th', iso3: 'tha', name: 'Thai',
    },
    {
      id: 168, iso2: 'tr', iso3: 'tur', name: 'Turkish',
    },
    {
      id: 174, iso2: 'uk', iso3: 'ukr', name: 'Ukrainian',
    },
    {
      id: 178, iso2: 'vi', iso3: 'vie', name: 'Vietnamese',
    },
    {
      id: 186, iso2: 'zh', iso3: 'zho', name: 'Chinese',
    },
  ];

}
