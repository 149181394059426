// SERVICES
import UsersService from './usersService'; import TenantsService from './tenantsService';
import ProjectsService from './projectsService';
import ProductsService from './productsService';
import UserProjectsService from './userProjectsService';
import ContractsService from './contractsService';
import TenantsAddressesService from './tenantsAddressesService';
import TenantsContactsService from './tenantsContactsService';
import ProjectUsersService from './projectUsersService';
import PoliciesService from './policiesService';
import RolesService from './rolesService';

// VIEWS
import Dashboard from '../components/views/Dashboard';
import User from '../components/views/User';
import Users from '../components/views/Users';
import Tenants from '../components/views/Tenants';
import Tenant from '../components/views/Tenant';
import Projects from '../components/views/Projects';
import Project from '../components/views/Project';
import Try from '../components/views/Try';
import Products from '../components/views/Products';
import Product from '../components/views/Product';
import Roles from '../components/views/Roles';
import Role from '../components/views/Role';
import Contracts from '../components/views/Contracts';
import Contract from '../components/views/Contract';
import ProjectProductsService from './projectProductsService';

/**
 * Servizio che ha il compito di mappare tutte le sezioni e le rotte correlate del sito in un unico punto di ingresso.
 * @category Services
 */
export default class SiteMapService {
  /**
   * Metodo che ritorna la sitemap sottoforma di oggetto JavaScript del tipo:
   *
   * <pre>
   * #sectionIndex:
   * -- Indice della sezione all'interno del menù;
   * #sectionName:
   * -- Nome della label che andrà inserita all'interno dell'header della sezione;
   * #sectionIcon:
   * -- Nome dell'icona che andrà inserita nel menu ed all'interno dell'header della sezione;
   * #sectionId:
   * -- Id della sezione utilizzato per riconoscere la sezione corrente salvata nel GlobalService, per identificare i campi della sezione nel {@link TableMapService} e per identificare la sezione padre o la sezione figlia nella mappa;
   * #sectionMenuId:
   * -- Proprietà speciale che sostituisce sectionId per identificare delle voci di menù che raggruppano più sezioni;
   * #menuChildren:
   * -- Array di sectionId che popoleranno il raggruppamento di menu (necessario solo se presente sectionMenuId);
   * #sectionRoute:
   * -- Rotta della sezione che verrà settata nel routing;
   * #exactRoute:
   * -- Flag exactRoute settato nel routing;
   * #sectionComponent:
   * -- Classe del componente della sezione;
   * #sectionService:
   * -- Classe del servizio della sezione (necessario solo per sezioni padre, le sezioni figlie lo ereditano);
   * #sectionReducerId:
   * -- Nome del reducer della sezione (necessario solo per la sezione dashboard);
   * #sectionTabs:
   * -- Array di tab che devono essere renderizzati nella sezione identificati da
   *      {id} del tab,
   *      {policy} abilitato a visualizzare il tab,
   *      flag {default} che identifica il tab attivato di default all'apertura della pagina e
   *      la {route} dello specifico tab (!!! utilizzare currentSectionId al posto di :id nell'url ed assicurarsi che sectionRoute termini con /:activeTab?);
   * #sectionNewItemsTitle:
   * -- Nome della label del titolo della modale di creazione (necessario solo per sezioni padre);
   * #sectionDefaultFilters:
   * -- Oggetto che definisce l'ordinamento di default e il numero di elementi visualizzati nella griglia (necessario solo per sezioni padre) tramite:
   *         {numPagination} numero elementi,
   *         {sort} nome della colonna su cui ordinare,
   *         {order} 'ASC' o 'DESC';
   * #sectionChild:
   * -- Id della sezione figlia a cui redirigere la selezione di una riga nella griglia (necessario solo per sezioni padre);
   * #sectionParent:
   * -- Id della sezione padre (necessario solo per sezioni figlie);
   * #sectionMainField:
   * -- Nome del parametro principale da visualizzare come sottotitolo nell'header in caso di sezione figlia o da visualizzare nell'errore per identificare gli    elementi in caso di fallimento delle api su una selezione multipla nelle griglie;
   * #sectionLanguagesData:
   * -- Array di campi necessari in caso di modali di creazione di nuove lingue all'interno della sezione (necessario solo per sezioni figlie);
   * #sectionGroups:
   * -- Array di id dei gruppi di utenze abilitate a vedere la sezione (necessario solo per sezioni padre, le sezioni figlie lo ereditano);
   * #sectionViewPolicy:
   * -- Policy necessario a visualizzare la sezione (necessario solo per sezioni padre, le sezioni figlie lo ereditano);
   * #sectionCreatePolicy:
   * -- Policy necessario a creare un nuovo item nella sezione (necessario solo per sezioni padre, le sezioni figlie lo ereditano);
   * #sectionEditPolicy:
   * -- Policy necessario a modificare un item nella sezione (necessario solo per sezioni padre, le sezioni figlie lo ereditano);
   * #sectionDeletePolicy:
   * -- Policy necessario a cancellare un item nella sezione (necessario solo per sezioni padre, le sezioni figlie lo ereditano);
   * </pre>
   * @static
   * @public
   * @return {map} Sitemap del sito
   */
  static getSiteMap() {
    const siteMap = {};

    siteMap.home = {
      sectionIndex: 0,
      sectionName: 'Section.dashboard',
      sectionIcon: 'cil-speedometer',
      sectionBadge: {
        variant: 'info',
        text: 'NEW',
      },
      sectionId: 'dashboard',
      sectionReducerId: 'users',
      sectionRoute: '/',
      exactRoute: true,
      sectionComponent: Dashboard,
      sectionService: UsersService,
      sectionTabs: [],
      sectionGroups: undefined,
      sectionViewPolicy: '*',
    };

    siteMap.products = {
      sectionIndex: 4,
      sectionName: 'Section.products',
      sectionIcon: 'cil-chart-bubble',
      sectionId: 'products',
      sectionRoute: '/products',
      exactRoute: true,
      sectionComponent: Products,
      sectionService: ProductsService,
      sectionTabs: [],
      sectionNewItemsTitle: 'Common.add_product',
      sectionChild: 'product',
      sectionMainField: 'name',
      sectionGroups: undefined,
      sectionViewPolicy: 'product_view',
      sectionCreatePolicy: 'product_create',
      sectionEditPolicy: 'product_edit',
      sectionDeletePolicy: 'product_delete',
    };

    siteMap.product = {
      sectionName: 'Section.product',
      sectionIcon: 'cil-chart-bubble',
      sectionId: 'product',
      sectionRoute: '/product/:id/:activeTab?',
      exactRoute: false,
      sectionComponent: Product,
      sectionParent: 'products',
      sectionMainField: 'name',
      sectionLanguagesData: [],
      sectionTabs: [
        {
          id: 'product_info',
          policy: '*',
          default: true,
          route: '/product/currentSectionId/product_info',
        },
        {
          id: '3d_upload',
          policy: '*',
          default: false,
          route: '/product/currentSectionId/3d_upload',
        },
        {
          id: '3d_preview',
          policy: '*',
          default: false,
          route: '/product/currentSectionId/3d_preview',
        },
      ],
    };

    siteMap.projects = {
      sectionIndex: 3,
      sectionName: 'Section.projects',
      sectionIcon: 'cil-briefcase',
      sectionId: 'projects',
      sectionRoute: '/projects',
      exactRoute: true,
      sectionComponent: Projects,
      sectionService: ProjectsService,
      sectionTabs: [],
      sectionNewItemsTitle: 'Common.add_project',
      sectionChild: 'project',
      sectionMainField: 'business_name',
      sectionGroups: undefined,
      sectionViewPolicy: 'project_view',
      sectionCreatePolicy: 'project_create',
      sectionEditPolicy: 'project_edit',
      sectionDeletePolicy: 'project_delete',
    };

    siteMap.project = {
      sectionName: 'Section.project',
      sectionIcon: 'cil-briefcase',
      sectionId: 'project',
      sectionRoute: '/project/:id/:activeTab?',
      exactRoute: false,
      sectionComponent: Project,
      sectionTabs: [],
      sectionParent: 'projects',
      sectionMainField: 'project_name',
      // sectionMainFieldTwo: 'last_name',
      sectionLanguagesData: [],
      sectionTabs: [
        {
          id: 'project_info',
          policy: '*',
          default: true,
          route: '/project/currentSectionId/project_info',
        },
        {
          id: '3d_upload',
          policy: '*',
          default: false,
          route: '/project/currentSectionId/3d_upload',
        },
      ],
    };

    siteMap.tenants = {
      sectionIndex: 1,
      sectionName: 'Section.tenants',
      sectionIcon: 'cil-building-business',
      sectionId: 'tenants',
      sectionRoute: '/tenants',
      exactRoute: true,
      sectionComponent: Tenants,
      sectionService: TenantsService,
      sectionTabs: [],
      sectionNewItemsTitle: 'Common.add_tenant',
      sectionChild: 'tenant',
      sectionMainField: 'business_name',
      sectionGroups: undefined,
      sectionViewPolicy: 'tenant_view',
      sectionCreatePolicy: 'tenant_create',
      sectionEditPolicy: 'tenant_edit',
      sectionDeletePolicy: 'tenant_delete',
    };

    siteMap.tenant = {
      sectionName: 'Section.tenant',
      sectionIcon: 'cil-building-business',
      sectionId: 'tenant',
      sectionRoute: '/tenant/:id/:activeTab?',
      exactRoute: false,
      sectionComponent: Tenant,
      sectionParent: 'tenants',
      sectionMainField: 'business_name',
      // sectionMainFieldTwo: 'last_name',
      sectionLanguagesData: [],
      sectionTabs: [
        {
          id: 'tenant_info',
          policy: '*',
          default: true,
          route: '/tenant/currentSectionId/tenant_info',
        },
        {
          id: 'economics',
          policy: '*',
          default: false,
          route: '/tenant/currentSectionId/economics',
        },
        {
          id: 'contacts_addresses',
          policy: '*',
          default: false,
          route: '/tenant/currentSectionId/contacts_addresses',
        },
        {
          id: '3d_upload',
          policy: '*',
          default: false,
          route: '/tenant/currentSectionId/3d_upload',
        },
      ],
    };

    siteMap.personal_tenant = {
      sectionIndex: 2,
      sectionName: 'Section.personal_tenant',
      sectionIcon: 'cil-group',
      sectionId: 'personal_tenant',
      sectionRoute: '/tenant/logged',
      exactRoute: true,
      sectionComponent: Tenant,
      sectionService: TenantsService,
      sectionTabs: [],
      // sectionNewItemsTitle: 'Common.add_user',
      // sectionChild: 'user',
      // sectionMainField: 'email',
      sectionGroups: undefined,
      sectionViewPolicy: 'tenant_logged',
      sectionCreatePolicy: '',
      sectionEditPolicy: 'tenant_logged',
      sectionDeletePolicy: '',
    };

    /* siteMap.contracts = {
      sectionIndex: 1,
      sectionName: 'Section.contracts',
      sectionIcon: 'cil-building-business',
      sectionId: 'contracts',
      sectionRoute: '/contracts',
      exactRoute: true,
      sectionComponent: Contracts,
      sectionService: ContractsService,
      sectionTabs: [],
      sectionNewItemsTitle: 'Common.add_contract',
      sectionChild: 'contract',
      sectionMainField: 'name',
      sectionGroups: undefined,
      sectionViewPolicy: '*',
      sectionCreatePolicy: '*',
      sectionEditPolicy: '*',
      sectionDeletePolicy: '*',
    };

    siteMap.contract = {
      sectionName: 'Section.contract',
      sectionIcon: 'cil-building-business',
      sectionId: 'contract',
      sectionRoute: '/contract/:id',
      exactRoute: false,
      sectionComponent: Contract,
      sectionParent: 'contracts',
      sectionMainField: 'name'
    }; */

    siteMap.roles = {
      sectionIndex: 6,
      sectionName: 'Section.roles',
      sectionIcon: 'cil-key',
      sectionId: 'roles',
      sectionRoute: '/roles',
      exactRoute: true,
      sectionComponent: Roles,
      sectionService: RolesService,
      sectionTabs: [],
      sectionNewItemsTitle: 'Common.add_role',
      sectionChild: 'role',
      sectionMainField: 'role_name',
      sectionGroups: undefined,
      sectionViewPolicy: 'role_view',
      sectionCreatePolicy: 'role_create',
      sectionEditPolicy: 'role_edit',
      sectionDeletePolicy: 'role_delete',
    };

    siteMap.role = {
      sectionName: 'Section.role',
      sectionIcon: 'cil-key',
      sectionId: 'role',
      sectionRoute: '/role/:id',
      exactRoute: false,
      sectionComponent: Role,
      sectionTabs: [],
      sectionParent: 'roles',
      sectionMainField: 'role_name',
      sectionLanguagesData: [],
    };

    siteMap.users = {
      sectionIndex: 5,
      sectionName: 'Section.users',
      sectionIcon: 'cil-group',
      sectionId: 'users',
      sectionRoute: '/users',
      exactRoute: true,
      sectionComponent: Users,
      sectionService: UsersService,
      sectionTabs: [],
      sectionNewItemsTitle: 'Common.add_user',
      sectionChild: 'user',
      sectionMainField: 'email',
      sectionGroups: undefined,
      sectionViewPolicy: 'user_view',
      sectionCreatePolicy: 'user_create',
      sectionEditPolicy: 'user_edit',
      sectionDeletePolicy: 'user_delete',
    };

    siteMap.user = {
      sectionName: 'Section.user',
      sectionIcon: 'cil-group',
      sectionId: 'user',
      sectionRoute: '/user/:id',
      exactRoute: false,
      sectionComponent: User,
      sectionTabs: [],
      sectionParent: 'users',
      sectionMainField: 'first_name',
      sectionMainFieldTwo: 'last_name',
      sectionLanguagesData: [],
    };

    siteMap.try = {
      sectionIndex: 100,
      sectionName: 'Section.try_tool',
      sectionIcon: 'cil-speedometer',
      sectionId: 'try',
      sectionReducerId: 'tenants',
      sectionRoute: '/try',
      exactRoute: true,
      sectionComponent: Try,
      sectionService: ProjectsService,
      sectionTabs: [],
      sectionGroups: undefined,
      sectionViewPolicy: '*'
    };

    return siteMap;
  }

  /**
   * Metodo che ritorna una mappa dei reducer per singola rotta. Ciascun reducer ha un nome e un servizio associato.
   * @static
   * @public
   * @return {map} Mappa dei reducer.
   */
  static getReducersMapByRoute() {
    const reducerMap = {
      user: { reducer_name: 'users', service: UsersService },
      user_projects: { reducer_name: 'user_projects', service: UserProjectsService },
      role: { reducer_name: 'roles', service: RolesService },
      role_related: { reducer_name: 'role_related', service: RolesService },
      tenant: { reducer_name: "tenants", service: TenantsService },
      contract: { reducer_name: "contracts", service: ContractsService },
      tenant_addresses: {reducer_name: "tenant_addresses", service: TenantsAddressesService },
      tenant_contacts: {reducer_name: "tenant_contacts", service: TenantsContactsService },
      project_users: {reducer_name: "project_users", service: ProjectUsersService},
      project_products: {reducer_name: "project_products", service: ProjectProductsService},
      project: { reducer_name: "projects", service: ProjectsService },
      project_related: { reducer_name: "project_related", service: ProjectsService },
      policy: { reducer_name: "policies", service: PoliciesService }
    };

    return reducerMap;
  }
}
