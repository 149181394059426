// GLOBAL
import { connect } from 'react-redux';
import React from 'react';

// UTILS
import withRouter from '../../utils/withNavigation';
import cloneDeep from 'lodash/cloneDeep';

//SERVICES
import ConstantsService from '../../services/constantsService';
import PoliciesService from '../../services/policiesService';

// COMPONENTS
import { mapStateToProps } from './AbstractComponent';
import AbstractDetail from './AbstractDetail';
import UiCheckbox from '../ui/UiCheckbox';
import UiButton from '../ui/UiButton';

//UI
import { CCard, CCardBody, CRow, CCol, CBadge, CFormSwitch, CFormLabel } from '@coreui/react-pro';
import UiCard from '../ui/UiCard';
import UiInput from '../ui/UiInput';
import UiAutocomplete from '../ui/UiAutocomplete';
import store from '../../redux/store';
import UiModal from '../ui/UiModal';

class Role extends AbstractDetail {
  constructor(props) {
    super(props);
    this.policiesService = PoliciesService.getInstance(store);
  }
  
  updateModel() {
    if (this.computedItemId !== 0 && this.itemId !== 0 && !this.computedItemId && !this.itemId) {
      return;
    }
    super.updateModel();
  }

  buildHeaderSubtitle(t) {
    if (this.state.model) {
      const subtitle = super.buildHeaderSubtitle();
      
      const badgeLabel = !!this.state.model?.active ? "active" : "not_active",
      badgeColor = !!this.state.model?.active ? "success" : "danger";
      
      return <span className='flex'>{subtitle} <CBadge style={{marginLeft: 10}} color={badgeColor}>{t('Table.' + badgeLabel)}</CBadge></span>;
    }
  }

  buildHeaderButtons(t) {
    if (this.state.model?.role_name === "ADMIN" && this.globalService?.currentUser?.role === "ADMIN") return;

    const buttonLabel = !!this.state.model.active ? "deactivate" : "activate";

    return (
      <>
        <CFormSwitch type='checkbox' checked={this.state.model.active} onClick={this.toggleModal.bind(this, "modalRoleStatus")} size='xl' />

        <UiModal title={t(`Common.handle_role_status`)} okLabel={t('Common.yes')} koLabel={t('Common.no')}
          isOpen={this.state.modalRoleStatus}
          onSubmit={this.handleOnActiveChange.bind(this, "modalRoleStatus")} onCancel={this.toggleModal.bind(this, "modalRoleStatus")}
          >
          <p>{t(`Common.confirm_${buttonLabel}_role`)}</p>
        </UiModal>
      </>
    );
  }

  handlePoliciesChange(parameter, evt) {
    const model = parameter === "all" ? this.toggleAllPolicies() : this.handleSinglePolicy(evt.target);
    this.setState({ model, changing: true }, () => { this.props.onSaveButtonEnabled(this.state.changing) });
  }


  handleSinglePolicy(target){
    const { name: id, value: checked } = target;
    const model = cloneDeep(this.state.model);

    if (!model.policies){
      model.policies = [];
    }

    if (checked) model.policies.push(Number(id));

    if (!checked) {
      const index = model.policies.findIndex(policy => policy === Number(id));
      model.policies.splice(index, 1);
    }

    return model;
  }

  toggleAllPolicies(){
    const model = cloneDeep(this.state.model),
      modelPolicies = [...model.policies],
      allIncluded = this.props.apiReducer?.policies?.data.every(item => modelPolicies.includes(item.id));
    
    // Nel controllare
    if (!allIncluded){
      model.policies = this.buildPolicyIdsArray();
    } else {
      // Quando deseleziono tutte le policies, aggiungo quelle riservate se il ruolo le possiede di default [user_me (29) e tenant_logged (30)]
      model.policies = [];
      if (modelPolicies.includes(29)) model.policies.push(29);
      if (modelPolicies.includes(30)) model.policies.push(30);
    }
    
    return model;
  }
  
  buildPolicyIdsArray(){
    // Per selezionare tutte le policies, creo un array con quelle che prendo dalla GET, più quelle riservate se il ruolo le possiede di default [user_me (29) e tenant_logged (30)]
    const policyIdsArray = this.props.apiReducer.policies.data.map(policy => policy.id);
    if (this.state.model.policies.includes(29)) policyIdsArray.push(29);
    if (this.state.model.policies.includes(30)) policyIdsArray.push(30);

    return policyIdsArray;
  }

  renderPolicies(policiesDisabled) {
    return this.props.apiReducer?.policies?.data.map((policy, key) => {
      return (
        <CCol className='mb-1' key={key} sm="12" md="6" lg="4">
          <UiCheckbox name={policy[ConstantsService.defaultDBIdentifier]} label={`SelectValues.${policy.policy_name}`}
            disabled={policiesDisabled}
            value={this.state.model.policies?.includes(policy[ConstantsService.defaultDBIdentifier])}
            onChange={this.handlePoliciesChange.bind(this, "single")}
          />
        </CCol>
      )
    })
  }

  checkAllSelected(){
    return this.props.apiReducer?.policies?.data.every(item => this.state.model.policies.includes(item.id));
  }

  renderChildren(t) {
    const allSelected = this.checkAllSelected();

    let viewTenant = false,
      policiesDisabled = false,
      roleDisabled = false;

    if (this.globalService?.currentUser?.policies.includes("tenant_view")) {
      viewTenant = true;
    }
    
    if (this.state.model?.role_name === "ADMIN" || this.state.model?.role_name === "SUPER_ADMIN" || !this.state.editable) {
      policiesDisabled = true;
      roleDisabled = true;
    }

    return (
      <>
          <CCard className="mb-4" collapsible={false}>
            <CCardBody>
              <CRow>
                <CCol sm="12" md="6" lg="6" xl="6">
                  <UiInput type="text" name="role_name" label="Table.role"
                    disabled={roleDisabled}
                    required={this.state.mandatoryFields.indexOf('role_name') >= 0}
                    value={this.state.model.role_name}
                    onFocus={this.handleOnFocus.bind(this)}
                    onChange={this.handleOnChange.bind(this)}
                    onBlur={this.handleOnBlur.bind(this)}
                  />
                </CCol>
                {
                  viewTenant &&
                  <CCol sm="12" md="6" lg="6" xl="6" className='rightCol color-column' >
                    <UiAutocomplete type="text" name="tenant_id" reducer="tenants" label="Table.tenant_name" disabled={true}
                      value={this.state.model.tenant_id} required={this.state.mandatoryFields.indexOf('tenant_id') >= 0} apiReducer={this.props.apiReducer}
                      values={this.props.apiReducer.tenants?.data} fieldDisplayed="business_name" fieldReturned={ConstantsService.defaultDBIdentifier}
                      onFocus={this.handleOnFocus.bind(this)} onChange={this.handleOnChange.bind(this)} onBlur={this.handleOnBlur.bind(this)}
                      onValueChanged={this.handleOnForcedChange.bind(this)} onSearchChange={this.handleOnSearchChange.bind(this)} />
                  </CCol>
                  }
              </CRow>
            </CCardBody>
          </CCard>

          <CCard>
            <CCardBody>
              <CRow className='mb-2'>
                <CCol>
                  <CFormLabel className="UiMainLabel">
                    {t("Table.policies")}
                  </CFormLabel>
                  <UiCheckbox name={'select_all'} label={allSelected ? 'Common.deselect_all' : 'Common.select_all'}
                    disabled={policiesDisabled}
                    value={allSelected}
                    onChange={this.handlePoliciesChange.bind(this, 'all')}
                    />
                </CCol>
              </CRow>
              <CRow>
                {this.renderPolicies(policiesDisabled)}
              </CRow>
            </CCardBody>
          </CCard>

      </>
    )
  }
}

export default connect(mapStateToProps)(withRouter(Role));