import React, { useEffect, useState, useRef } from 'react';
import { CRow, CCol, CFormInput, CFormLabel, CTooltip, CInputGroup, CInputGroupText, CFormRange } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { I18n } from 'react-i18next';
import PropTypes from 'prop-types';
import UiInput from './UiInput';

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  title: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  display: PropTypes.string,
  plaintext: PropTypes.bool,
  inline: PropTypes.bool,
  className: PropTypes.string,
  tooltipMsg: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.number,
  max: PropTypes.number,
  type: PropTypes.oneOf(['tel', 'text', 'email', 'password', 'number', 'currency']),
};

const defaultProps = { type: 'text' };

function UiRange(props) {
  const initialValue = useRef(props.value);
  const changed = useRef(false);

  const [ state, setState ] = useState({
    value: props.value >= props.min ? props.value : (props.min + props.max) / 2,
  })

  let valueTimeout = null;

  useEffect(() => {
    if (!props.disabled) {
      if (props.onChange && typeof props.onChange === "function"){
        if (initialValue.current === props.value && !changed.current){
          changed.current = true;
          return;
        }

        valueTimeout = setTimeout(() => {
          const e = {
            target: {
              name: props.name,
              value: state.value,
            },
          };
          props.onChange(e);
        }, 500);
      }
    }

    return () => clearTimeout(valueTimeout);
  }, [state.value])

  function handleOnChange(e) {
    setState({
      ...state,
      value: e.target.value,
    })
  }

  return (
    <I18n ns="translations">
      {t => (
        <CRow className={"align-items-center"}>
          {
            props.label &&
            <CCol xs={3}>
              <CFormLabel htmlFor={props.name} className={`UiRange__label${props.labelClassName ? ` ${props.labelClassName}` : ""}`}>
                { props.label && t(props.label)} {props.required ? '*' : '' }

                { props.tooltipMsg && (
                  <span>
                    <CTooltip placement="top" content={props.tooltipMsg ? t(props.tooltipMsg) : t('Tooltips.currency_format_info')}>
                      <CIcon icon="cil-info-circle" size="sm" />
                    </CTooltip>
                  </span>
                ) }
              </CFormLabel>
            </CCol>
          }

          <CCol style={{width: 65, flex: "none", marginRight: 10}}>
            <UiInput
              type="number"
              value={state.value}
              onChange={handleOnChange.bind(this)}
            />
            {/* <span className='UiRange__value'>{ state.value }</span> */}
          </CCol>

          <CCol className='d-flex align-items-center'>
            <CFormRange
              id={props.name}
              name={props.name}
              onChange={handleOnChange}
              value={state.value}
              min={props.min}
              max={props.max}
              step={props.step ? props.step : 1}
            />
          </CCol>
        </CRow>
      )}
    </I18n>
  );

}

UiRange.propTypes = propTypes;
UiRange.defaultProps = defaultProps;

export default UiRange;
