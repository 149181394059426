import BaseService from './baseService';

export default class UsersService extends BaseService {
  static instance = null;

  static getInstance(store) {
    if (!UsersService.instance) {
      UsersService.instance = new UsersService(store);
    }
    return UsersService.instance;
  }

  constructor(store) {
    const baseUrl = process.env.API_PATH;
    super(store, baseUrl, 'users', 'user');
  }

  getUserMe(lang, token, okCallBack, koCallBack) {
    /* function meOkCallBack(res) {
      let responseData = {... res.data.userProfileDto};
      responseData.policies = res.data.backoffice_section;
      if (okCallBack) okCallBack({data: responseData});
    } */
    // return super.get(`${this.itemName}_me`, '/myprofile', lang, token, null, meOkCallBack.bind(this), koCallBack);
    return super.get(`${this.itemName}_me`, 'users/auth', lang, token, null, okCallBack, koCallBack);
  }

  getQueue(lang, token, okCallBack, koCallBack) {
    return super.get('queue', 'queue', lang, token, null, okCallBack, koCallBack);
  }

  updateUserPassword(userId, obj, lang, token, okCallBack, koCallBack){
    return super.put(this.itemName, `${this.urlPath}/${userId}/password`, lang, token, obj, null, okCallBack, koCallBack);
  }
}
