import axios from 'axios/index';
import camelCase from 'lodash/camelCase';

import ApiCallsQueueService from './apiCallsQueueService';

//Api action creator
import { init, initList, request, received, receivedNotAuthorized, invalidate } from '../redux/apiReducer';
import { refreshToken } from '../redux/tokenReducer';

import ObjectsUtils from '../utils/objectsUtils';

export default class AbstractService {

  constructor(store, baseUrl) {
    this.store = store;

    this.apiCallsQueueService = ApiCallsQueueService.getInstance();

    this.axiosInstance = axios.create({
      baseURL: baseUrl
    });

    /*this.axiosInstance.interceptors.response.use(
      (response) => {
      return response;
      }, 
      (error) => {
      let originalRequest = {}
      originalRequest.request = error.config;
      if (error.response.status === 401 && !originalRequest.request._retry) {
        originalRequest._originalService = this;
        originalRequest.request._retry = true;
        this.apiCallsQueueService.queueApiCall(originalRequest);
      }
      return Promise.reject(error);
    });*/
  }

  parseLinkHeader(linkHeader) {
    if (!linkHeader || linkHeader.length === 0) {
      return {};
    }
    // Split parts by comma
    let parts = linkHeader.split(',');
    let links = {};
    // Parse each part into a named link
    for (let p of parts) {
      let section = p.split(';');
      if (section.length !== 2) {
        continue;
      }
      let url = section[0].replace(/<(.*)>/, '$1').trim();
      let name = section[1].replace(/rel="(.*)"/, '$1').trim();
      // links[name] = url.replace('http:', 'https:');
      if (url && url.length > 0) links[name] = url;
    }

    return links;
  }

  parseBodyMetadata(response) {
    let metadata = { links: {} };
    let bodyMetadata = response.data.metadata;

    if (bodyMetadata.totalRows) {
      metadata.total = bodyMetadata.totalRows;
    }

    if (ObjectsUtils.isNumberValid(bodyMetadata.start) && ObjectsUtils.isNumberValid(bodyMetadata.end)) {
      //fake tanto viene gestito da handlePage di AbstractGrid
      metadata.links.first = response.request.responseURL;
      metadata.links.prev = response.request.responseURL;
      metadata.links.next = response.request.responseURL;
      metadata.links.last = response.request.responseURL;
    }

    return metadata;
  }

  convertDateStringsToDates(entry) {
    let regexIso8601 = /^(\d{4}|\+\d{6})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(.\d{6})?Z$/;
    // Ignore things that aren't objects.
    if (typeof entry === 'object') {
      for (let key in entry) {
        let value = entry[key];
        let match = [];
        // Check for string properties which look like dates.
        if (typeof value === 'string' && (match = value.match(regexIso8601))) {
          let milliseconds = Date.parse(match[0]);
          if (!isNaN(milliseconds)) {
            entry[key] = new Date(Date.UTC(match[1], match[2] - 1, match[3], match[4], match[5], match[6]));
          }
        } else if (typeof value === 'object') {
          // Recurse into object
          entry[key] = this.convertDateStringsToDates(value);
        }
      }
    }
    return entry;
  }

  getHeaders(lang, auth) {
    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': lang
    };

    if (!!auth && !!auth.accessToken) {
      headers['Authorization'] = ((auth.tokenType) ? auth.tokenType : 'Bearer') + " " + auth.accessToken;
    }

    return headers;
  }

  getMultipartHeaders(lang, auth) {
    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data',
      'Accept-Language': lang
    };

    if (!!auth && !!auth.accessToken) {
      headers['Authorization'] = ((auth.tokenType) ? auth.tokenType : 'Bearer') + " " + auth.accessToken;
    }

    return headers;
  }

  buildQueryParams(filter) {
    let response = '';
    const queryParams = [];

    if (filter) {
      for (let key in filter) {
        if (key === 'filter') {
          for (let filterKey in filter['filter']) {
            let fkey = (process.env.API_RESPONSE_FORMAT === 'camelCase') ? camelCase(filterKey) : filterKey;
            let fvalue = (filter['filter'][filterKey]) ? filter['filter'][filterKey].toString() : null;
            if (fvalue) queryParams.push(fkey + '=' + fvalue);
          }
        } else if (filter[key]) {
          let fkey = null;
          let envKey = null;
          let fvalue = null;

          switch (key) {
            case 'paginate':
              if (process.env.API_PAGINATION_TYPE === 'index') {
                envKey = process.env.API_PAGINATION_END_INDEX_KEY ?? 'range_end';
                fkey = (process.env.API_RESPONSE_FORMAT === 'camelCase') ? camelCase(envKey) : envKey;
                fvalue = filter.page * filter.paginate;
                queryParams.push(`${fkey}=${fvalue}`);
                break;
              }
              envKey = process.env.API_PAGINATION_SIZE_KEY ?? key;
              fkey = (process.env.API_RESPONSE_FORMAT === 'camelCase') ? camelCase(envKey) : envKey;
              queryParams.push(`${fkey}=${filter[key]}`);
              break;
            case 'page':
              envKey = process.env.API_PAGINATION_INDEX_KEY ?? key;
              fkey = (process.env.API_RESPONSE_FORMAT === 'camelCase') ? camelCase(envKey) : envKey;
              fvalue = (process.env.API_PAGINATION_TYPE === 'index') ? (filter.page - 1) * filter.paginate : filter[key];
              queryParams.push(`${fkey}=${fvalue}`);
              break;
            case 'sort':
              envKey = process.env.API_SORT_KEY ?? key;
              fkey = (process.env.API_RESPONSE_FORMAT === 'camelCase') ? camelCase(envKey) : envKey;
              queryParams.push(`${fkey}=${filter[key]}`);
              break;
            case 'order':
              envKey = process.env.API_SORT_ORDER_KEY ?? key;
              fkey = (process.env.API_RESPONSE_FORMAT === 'camelCase') ? camelCase(envKey) : envKey;
              queryParams.push(`${fkey}=${filter[key]}`);
              break;
            case 'elastic_query':
              envKey = process.env.API_FULL_TEXT_SEARCH_KEY ?? key;
              fkey = (process.env.API_RESPONSE_FORMAT === 'camelCase') ? camelCase(envKey) : envKey;
              queryParams.push(`${fkey}=${filter[key]}`);
              break;
            default:
              fkey = (process.env.API_RESPONSE_FORMAT === 'camelCase') ? camelCase(key) : key;
              queryParams.push(`${fkey}=${filter[key]}`);
          }
        }
      }
    }

    for (let index in queryParams) {
      if (+index === 0) {
        response = '?';
      }
      response += queryParams[index];
      if (index < queryParams.length - 1) {
        response += '&';
      }
    }

    return response;
  }

  transformGetResponse(response) {
    let parsedLinks = {};
    let parsedTotal = null;
    let total = response?.data?.totalItems;
    //let links = response.headers.link;
    if (total) {
      //parsedLinks = this.parseLinkHeader(links);
      parsedTotal = total;
    } else if (!total && response?.data?.metadata) {
      let parsedMetadata = this.parseBodyMetadata(response);
      //parsedLinks = parsedMetadata.links;
      parsedTotal = parsedMetadata.total;
    }

    const obj = {
      body: (response?.data?.content) ? response.data.content : (response?.data?.data) ? response.data.data : response.data,
      links: parsedLinks,
      total: parsedTotal
    }
    return obj
  }

  manageSuccess(response, moduleName, okCallBack) {
    let responseData = (response?.data?.data) ? response.data.data : (response?.data) ? response.data : response;
    if (!!responseData) {
      if (typeof responseData === 'object') {
        responseData = (process.env.API_RESPONSE_FORMAT === 'camelCase') ? ObjectsUtils.camelCaseToSnakeCase(responseData) : responseData;
      }
      this.store.dispatch(received({ moduleName: moduleName, json: responseData }));
    }
    if (!!okCallBack) {
      okCallBack({ data: responseData });
    }
  }

  manageError(error, method, retried, moduleName, path, lang, obj, okCallBack, koCallBack, firstError) {
    if (error.response) {
      let errorData = (error.response?.data && typeof error.response?.data === 'string') ? {message: error.response?.data} : error.response;
      if (errorData && typeof errorData === 'object') {
        errorData = (process.env.API_RESPONSE_FORMAT === 'camelCase') ? ObjectsUtils.camelCaseToSnakeCase(errorData) : errorData;
        if (error.response?.data) {
          errorData.status = error.response.status;
        }
      }
      
      if (errorData.config){
        delete errorData.config.adapter;
        delete errorData.config.transform_request;
      }

      if ((errorData.status === 401 || errorData.status === 403) && !retried) {
        this.store.dispatch(refreshToken());
        this.store.dispatch(receivedNotAuthorized({ moduleName: moduleName }));
        this.store.dispatch(invalidate({ type: "INVALIDATED", error: errorData, moduleName: "login" }));

        if (method) {
          let originalRequest = { moduleName, path, lang, obj, okCallBack, koCallBack, firstError }
          originalRequest.originalService = this;
          originalRequest.method = method;
          this.apiCallsQueueService.queueApiCall(originalRequest);
        }
      } else {
        if (error.response.status === 500) {
          this.store.dispatch(invalidate({ type: "SERVER_INVALIDATED", error: errorData, moduleName: moduleName }));
        }
        this.store.dispatch(invalidate({ type: "INVALIDATED", error: errorData, moduleName: moduleName }));
        if (!!koCallBack) {
          koCallBack({ data: errorData });
        }
      }
    } else {
      this.store.dispatch(invalidate({ type: "SERVER_INVALIDATED", error: (error.message) ? error.message : error, moduleName: moduleName }));
      if (!!koCallBack) {
        koCallBack(error);
      }
    }
  }

  initApiActions(moduleName) {
    this.store.dispatch(init({ moduleName: moduleName }));
  }

  initListApiActions(moduleName) {
    this.store.dispatch(initList({ moduleName: moduleName }));
  }

  getApiActionTypes() {
    return ApiActions.getTypesModule();
  }

  get(moduleName, path, lang, auth, obj, okCallBack, koCallBack, firstError, retried) {
    this.store.dispatch(request({ moduleName: moduleName }));
    this.axiosInstance.defaults.headers = this.getHeaders(lang, auth);
    let queryParams = this.buildQueryParams(obj);
    return this.axiosInstance.get(path + queryParams)
      .then(response => {
        if (!!response) {

          let res = this.transformGetResponse(response);

          res.body = (process.env.API_RESPONSE_FORMAT === 'camelCase') ? ObjectsUtils.camelCaseToSnakeCase(res.body) : res.body;

          this.store.dispatch(received({
            moduleName: moduleName,
            json: res.body,
            links: res.links,
            total: res.total,
            filter: queryParams
          }));

          if (!!okCallBack) {
            okCallBack({ data: res.body }, firstError);
          }
        } else {
          if (!!okCallBack) {
            okCallBack(response, firstError);
          }
        }
      })
      .catch(error => {
        this.manageError(error, 'get', retried, moduleName, path, lang, obj, okCallBack, koCallBack, firstError);
      });
  }

  post(moduleName, path, lang, auth, obj, okCallBack, koCallBack, retried) {
    this.store.dispatch(request({ moduleName: moduleName }));
    this.axiosInstance.defaults.headers = this.getHeaders(lang, auth);
    let body = (process.env.API_RESPONSE_FORMAT === 'camelCase') ? ObjectsUtils.snakeCaseToCamelCase(obj) : obj;
    return this.axiosInstance.post(path, JSON.stringify(body))
      .then(response => {
        this.manageSuccess(response, moduleName, okCallBack);
      })
      .catch(error => {
        this.manageError(error, 'post', retried, moduleName, path, lang, obj, okCallBack, koCallBack);
      });
  }

  postMultipartArray(moduleName, path, lang, auth, obj, fileContainerName, okCallBack, koCallBack, progressCallback, retried) {
    // this.store.dispatch(ApiActions.request(moduleName));
    this.store.dispatch(request({ moduleName: moduleName }));
    this.axiosInstance.defaults.headers = this.getMultipartHeaders(lang, auth);

    const data = new FormData();

    Object.keys(obj).map((key) => {
      if (key !== 'files' && obj[key]) {
        if (!Array.isArray(obj[key])) {
          data.append(key, obj[key]);
        } else if (obj[key].length > 0) {
          for (var i = 0; i < obj[key].length; i++) {
            data.append(key + '[]', obj[key][i]);
          }
        }
      }
    });

    for (let i = 0; i < obj.files.length; i++) {
      data.append(fileContainerName + '[' + i + ']', obj.files[i]);
    }

    let config = {};

    if (progressCallback) {
      config = {
        onUploadProgress: progressEvent => progressCallback(progressEvent)
      }
    }

    return this.axiosInstance.post(path, data, config)
      .then(response => {
        this.manageSuccess(response, moduleName, okCallBack);
      })
      .catch(error => {
        this.manageError(error, 'postMultipartArray', retried, moduleName, path, lang, obj, okCallBack, koCallBack);
      });
  }

  postMultipart(moduleName, path, lang, auth, obj, singlefileContainerName, okCallBack, koCallBack, progressCallback, retried) {
    // this.store.dispatch(ApiActions.request(moduleName));
    this.store.dispatch(request({ moduleName: moduleName }));

    this.axiosInstance.defaults.headers = this.getMultipartHeaders(lang, auth);

    const data = new FormData();

    Object.keys(obj).map((key) => {
      if (key !== 'files' && obj[key]) {
        if (!Array.isArray(obj[key])) {
          data.append(key, obj[key]);
        } else if (obj[key].length > 0) {
          for (var i = 0; i < obj[key].length; i++) {
            data.append(key + '[]', obj[key][i]);
          }
        }
      }
    });
    data.append(singlefileContainerName, obj.file);

    let config = {};

    if (progressCallback) {
      config = {
        onUploadProgress: progressEvent => progressCallback(progressEvent)
      }
    }

    return this.axiosInstance.post(path, data, config)
      .then(response => {
        this.manageSuccess(response, moduleName, okCallBack);
      })
      .catch(error => {
        this.manageError(error, 'postMultipart', retried, moduleName, path, lang, obj, okCallBack, koCallBack);
      });
  }

  put(moduleName, path, lang, auth, obj, okCallBack, koCallBack, retried) {
    // this.store.dispatch(ApiActions.request(moduleName));
    this.store.dispatch(request({ moduleName: moduleName }));
    this.axiosInstance.defaults.headers = this.getHeaders(lang, auth);
    let body = (process.env.API_RESPONSE_FORMAT === 'camelCase') ? ObjectsUtils.snakeCaseToCamelCase(obj) : obj;
    return this.axiosInstance.put(path, JSON.stringify(body))
      .then(response => {
        this.manageSuccess(response, moduleName, okCallBack);
      })
      .catch(error => {
        this.manageError(error, 'put', retried, moduleName, path, lang, obj, okCallBack, koCallBack);
      });
  }

  delete(moduleName, path, lang, auth, okCallBack, koCallBack, retried) {
    // this.store.dispatch(ApiActions.request(moduleName));
    this.store.dispatch(request({ moduleName: moduleName }));
    this.axiosInstance.defaults.headers = this.getHeaders(lang, auth);
    return this.axiosInstance.delete(path)
      .then(response => {
        this.manageSuccess(response, moduleName, okCallBack);
      })
      .catch(error => {
        this.manageError(error, 'delete', retried, moduleName, path, lang, okCallBack, koCallBack);
      });
  }

  download(moduleName, path, lang, auth, obj, okCallBack, koCallBack) {
    // this.store.dispatch(ApiActions.request(moduleName));
    this.store.dispatch(request({ moduleName: moduleName }));
    this.axiosInstance.defaults.headers = this.getHeaders(lang, auth);
    this.axiosInstance.defaults.responseType = 'arraybuffer';

    return this.axiosInstance.get(path, { params: obj })
      .then(response => {
        this.manageSuccess(response, moduleName, okCallBack);
      })
      .catch(error => {
        this.manageError(error, null, retried, moduleName, path, lang, obj, okCallBack, koCallBack);
      });
  }

}
