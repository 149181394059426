import axios from 'axios/index';
import i18n from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import snakeCase from 'lodash/snakeCase';
import camelCase from 'lodash/camelCase';

import ConstantsService from '../services/constantsService';

export default class ObjectsUtils {

  static isIdValid(target) {
    const starget = `${target}`;
    const isNumeric = /^\d+$/.test(starget);
    return target !== null && target !== undefined && ((isNumeric) ? starget > 0 : starget.length > 0);
  }

  static isGuidValid(target) {
    const regexNetwork = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/;
    const starget = `${target}`;
    const match = starget.match(regexNetwork);
    return target !== null && target !== undefined && starget.length > 0 && match && match.length > 0;
  }

  static isStringValid(target) {
    const starget = `${target}`;
    return target !== null && target !== undefined && starget.length > 0;
  }

  static areStringsEqual(target1, target2) {
    if (ObjectsUtils.isStringValid(target1) && ObjectsUtils.isStringValid(target2)) {
      return target1 === target2;
    } else if (ObjectsUtils.isStringValid(target1) || ObjectsUtils.isStringValid(target2)) {
      return false;
    }
    return true;
  }

  static isEmailValid(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }


  static isNumberValid(target) {
    return target !== null && target !== undefined && !Number.isNaN(target);
  }

  static isIntegerValid(target) {
    const itarget = (target) ? parseInt(target) : null;
    return target !== null && !Number.isNaN(itarget);
  }

  static isArrayValid(target) {
    return target !== null && target !== undefined && Array.isArray(target);
  }

  static isArrayNotEmpty(target) {
    return target !== null && target !== undefined && Array.isArray(target) && target.length > 0;
  }

  static isObjectValid(target) {
    return target !== null && target !== undefined && typeof target === 'object';
  }

  static isObjectNotEmpty(target) {
    return target !== null && target !== undefined && typeof target === 'object' && Object.keys(target).length > 0;
  }

  static isImgValid(target) {
    const props = Object.getOwnPropertyNames(target);
    if (props.length === 3 && props.indexOf('id') >= 0 && props.indexOf('status') >= 0 && props.indexOf('url') >= 0) {
      return target !== null && target !== undefined && target.url !== null && target.url !== undefined && target.url.length > 0;
    }
    return false;
  }

  static isYearValid(target) {
    return target !== null && target !== undefined && parseInt(target) > 1900 && parseInt(target) <= (new Date()).getFullYear() + 5;
  }

  static isIpValid(target) {
    const regexNetwork = /^([2][0-5][0-5]|[1][0-9][0-9]|[1-9][0-9]|[0-9]{1,3})\.([2][0-5][0-5]|[1][0-9][0-9]|[1-9][0-9]|[0-9]{1,3})\.([2][0-5][0-5]|[1][0-9][0-9]|[1-9][0-9]|[0-9]{1,3})\.([2][0-5][0-5]|[1][0-9][0-9]|[1-9][0-9]|[0-9]{1,3})$/;
    const starget = `${target}`;
    const match = starget.match(regexNetwork);
    return target !== null && target !== undefined && starget.length > 0 && match && match.length > 0;
  }

  static isNetworkValid(target) {
    const regexNetwork = /^([2][0-5][0-5]|[1][0-9][0-9]|[1-9][0-9]|[0-9]{1,3})\.([2][0-5][0-5]|[1][0-9][0-9]|[1-9][0-9]|[0-9]{1,3})\.([2][0-5][0-5]|[1][0-9][0-9]|[1-9][0-9]|[0-9]{1,3})\.([2][0-5][0-5]|[1][0-9][0-9]|[1-9][0-9]|[0-9]{1,3})\/([0-9]{1,3})$/;
    const starget = `${target}`;
    const match = starget.match(regexNetwork);
    return target !== null && target !== undefined && starget.length > 0 && match && match.length > 0;
  }

  static areModelsEqual(model1, model2) {
    const currentModel = cloneDeep(model1);
    if (currentModel) {
      currentModel.created_at = undefined;
      currentModel.updated_at = undefined;
      currentModel.deleted_at = undefined;
    }
    const savedModel = cloneDeep(model2);
    if (savedModel) {
      savedModel.created_at = undefined;
      savedModel.updated_at = undefined;
      savedModel.deleted_at = undefined;
    }

    return isEqual(currentModel, savedModel);
  }

  static getUrlParamsFromFormat(urlFormat, url) {
    let urlFormatParts = urlFormat.split('/');
    let urlParts = url.split('/');
    let params = {};
    for (let i = 0; i < urlFormatParts.length; i++) {
      if (urlFormatParts[i].indexOf(':') === 0) {
        params[urlFormatParts[i].substring(1).replace('?', '')] = (urlParts[i]) ? urlParts[i] : undefined;
      }
    }
    return params;
  }

  static imageBlobtoDataURL(imageUrl) {
    return axios.get(imageUrl, { responseType: 'blob' })
      .then((response) => {
        if (response && response.data) {
          return URL.createObjectURL(response.data);
        }
        return null;
      })
      .catch(() => null);
  }

  static mapToString(map) {
    let string = "";
    if (map && typeof map === 'object' && Object.keys(map).length > 0) {
      for (let key of Object.keys(map)) {
        if (string.length > 0) {
          string += ", ";
        }
        string += key + ": " + map[key];
      }
    }
    return string;
  }

  static camelCaseToSnakeCase(obj) {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    const result = Array.isArray(obj) ? [] : {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const snakeCaseKey = snakeCase(key);
        result[snakeCaseKey] = (typeof obj[key] === 'object') ? ObjectsUtils.camelCaseToSnakeCase(obj[key]) : obj[key];
      }
    }
    return result;
  }

  static snakeCaseToCamelCase(obj) {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }
    const result = Array.isArray(obj) ? [] : {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        try {
          const camelCaseKey = camelCase(key);
          result[camelCaseKey] = (typeof obj[key] === 'object') ? ObjectsUtils.snakeCaseToCamelCase(obj[key]) : obj[key];
          //console.log(result);
        } catch (e){
          console.log(e);
        }
      }
    }
    return result;
  }
  
  static buildError(error){
    if (error?.status === 500 || error.response?.status === 500 || error.data?.status === 500) {
      return i18n.t('Common.internal_server_error');
    }
    console.log("ERRORE", error);
    if (error?.data?.data?.result.error_message) {
      console.log("ERRORE DENTRO", error);
      return error.data.data.result.error_message;
    }
    /* if (error?.response?.data?.message) {
      return error.response.data.message;
    } else if (error?.data?.data?.message) {
      return error.data.data.message;
    }  else if (error?.data?.data?.error) {
      return error.data.data.error;
    } else if (error?.data?.message) {
      return error.data.message;
    } else if (error?.data?.error) {
      return error.data.error;
    } else if (error?.message) {
      return error.message;
    } */
    return i18n.t('Common.generic_error');
  }

  static capitalizeString(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  static isoToDate(date) {
    let ts = Date.parse(date)
    let newDate = new Date(ts)
    return newDate;
  }

  static isValidZipCode(zipCode) {
    // Regular expression pattern for a 5-digit zip code
    var zipCodePattern = /^\d{5}$/;

    // Test if the zip code matches the pattern
    return zipCodePattern.test(zipCode);
  }

  static isValidFaxNumber(faxNumber) {
    // Regular expression pattern for a fax number
    var faxNumberPattern = /^(\+?\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    // Test if the fax number matches the pattern
    return faxNumberPattern.test(faxNumber);
  }

  static isValidTaxIdCode(taxIdCode) {
    // Regular expression pattern for an Italian fiscal code
    var codiceFiscalePattern = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i;

    // Test if the fiscal code matches the pattern
    return codiceFiscalePattern.test(taxIdCode);
  }

  static isValidIBAN(iban) {
    // // Remove all spaces and convert to uppercase
    // iban = iban.replace(/\s/g, '').toUpperCase();

    // // Check if the country code is valid
    // var countryCode = iban.substring(0, 2);
    // if (!/^[A-Z]{2}$/.test(countryCode)) {
    //   return false;
    // }

    // // Move the four initial characters to the end of the string
    // iban = iban.substring(4) + iban.substring(0, 4);

    // // Replace each letter with two digits
    // iban = iban.replace(/[A-Z]/g, function (char) {
    //   return char.charCodeAt(0) - 55;
    // });

    // // Convert the IBAN to a number and check if it's valid
    // return parseInt(iban, 10) % 97 === 1;
    const ibanRegex = /^[A-Z]{2}\d{2}[A-Z0-9]{1,30}$/;
    const isValidIban = ibanRegex.test(iban);
    return isValidIban;
  }

  static isSwiftCode(code) {
    // Verifica la lunghezza del codice SWIFT
    if (code.length !== 8 && code.length !== 11) {
      return false;
    }

    // Verifica la presenza di solo caratteri alfabetici e numerici
    if (!/^[0-9a-zA-Z]+$/.test(code)) {
      return false;
    }

    // Verifica che i primi 4 caratteri siano il codice della banca
    var bankCode = code.substring(0, 4);
    if (!/^[a-zA-Z]{4}$/.test(bankCode)) {
      return false;
    }

    // Verifica che i due caratteri successivi siano il codice del paese
    var countryCode = code.substring(4, 6);
    if (!/^[a-zA-Z]{2}$/.test(countryCode)) {
      return false;
    }

    // Verifica che il carattere successivo sia "X" o un numero
    var locationCode = code.substring(6, 7);
    if (!/^[0-9X]$/.test(locationCode)) {
      return false;
    }

    // Verifica che gli ultimi 3 caratteri siano opzionali e contengano solo numeri o "X"
    var branchCode = code.substring(7);
    if (branchCode.length === 3 && !/^[0-9X]{3}$/.test(branchCode)) {
      return false;
    }

    return true;
  }

  static isPecValid(pec) {
    // Verifica la lunghezza dell'indirizzo PEC
    if (pec.length < 6 || pec.length > 254) {
      return false;
    }

    // Verifica la presenza di una sola chiocciola
    if ((pec.match(/@/g) || []).length !== 1) {
      return false;
    }

    // Verifica che la chiocciola non sia il primo o l'ultimo carattere
    if (pec[0] === '@' || pec[pec.length - 1] === '@') {
      return false;
    }

    // Verifica che l'indirizzo contenga solo caratteri validi
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(pec)) {
      return false;
    }

    return true;
  }

  static buildPermalink(title, locale) {
    let loc = (locale && locale !== ConstantsService.defaultLanguage)? '-'+locale : '';
    return title.toLowerCase().replace(/[^\w\s]/gi, '').replace(/ /g, '-') + loc;
  }

  static hexToRgb(hex){
    hex = hex.replace(/^#/, '');

    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `${r}, ${g}, ${b}`;
  }

}
