import BaseService from './baseService';

export default class ContractsService extends BaseService {
  static instance = null;

  static getInstance(store) {
    if (!ContractsService.instance) {
      ContractsService.instance = new ContractsService(store);
    }
    return ContractsService.instance;
  }

  constructor(store) {
    const baseUrl = process.env.API_PATH;
    super(store, baseUrl, 'contracts', 'contract');
  }
}