import { CCard, CCardBody, CCol, CRow } from '@coreui/react-pro'
import React, { useState, useMemo, useEffect, useRef, createRef } from 'react'
import { I18n } from 'react-i18next';
import store from '../../redux/store';


//services
import ConstantsService from '../../services/constantsService';
import { useLoadScript, GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import MapService from '../../services/mapService';

//components
import UiAutocomplete from './UiAutocomplete';
import UiButton from './UiButton';
import UiInput from './UiInput';

const customMarker = {
  path: 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
  fillColor: "red",
  fillOpacity: 2,
  strokeWeight: 1,
  rotation: 0,
  scale: 1,
};

function UiMap(props) {

  const [city, setCity] = useState(2);
  const [address, setAddress] = useState();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });
  const [center, setCenter] = useState({ lat: 45.6101939, lng: 9.2289157 });
  // const center = useMemo(() => ({ lat: 45.6101939, lng: 9.2289157 }), []);
  const [markers, setMarkers] = useState([
    {
      lat: 45.6101939, lng: 9.2289157, icon: 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png'
    },
  ])

  const mapService = MapService.getInstance(store);

  const [infoWindowData, setInfoWindowData] = useState();
  const [showWindowData, setShowWindowData] = useState(false);

  const mapRef = useRef();

  useEffect(() => {
    if(markers.length > 1) {
      onLoad(mapRef.current.state.map);
    }
  }, [markers])

  function handleOnChange(evt) {
    setCity(evt.target.value);
  }

  function handleAddressChange(evt) {
    setAddress(evt.target.value);
  }

  function handleMarker(center) {
    setInfoWindowData(center.lat, center.lng);
    setShowWindowData(true);
  }

  function handleMap() {
    //mapService.findAddress(address).then(res => setCenter(res));
    setMarkers([
      ...markers,
      {
        lat: 50.6101939, lng: 9.2289157, icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
      },
      {
        lat: 40.6101939, lng: 9.2289157, icon: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
      },
    ])
  };

  const onLoad = (map) => {
    const bounds = new google.maps.LatLngBounds();
    markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    map.fitBounds(bounds);
  };

  return (
    <I18n ns="translations">
      {t => (
        <CCard>
          <CCardBody>
            <CRow>
              {props.apiReducer?.cities?.data && (
                <UiAutocomplete name="city_id" label="Table.city_id" route="city" currentUser={props.currentUser} apiReducer={props.apiReducer}
                  values={props.apiReducer.cities.data}
                  value={city} fieldDisplayed="city_name" fieldReturned={ConstantsService.defaultDBIdentifier}
                  //onValueChanged={props.handleOnForcedChange.bind(this)} 
                  onSearchChange={props.handleOnSearchCityChange.bind(this)} onChange={handleOnChange.bind(this)}
                // onFocus={props.handleOnFocus.bind(props)} onBlur={props.handleOnBlur.bind(props)}
                />
              )}
            </CRow>
            <CRow className="mt-4">
              {isLoaded && (
                <GoogleMap zoom={10} center={center} mapContainerClassName='map-container' onLoad={onLoad.bind(this)} ref={mapRef}>
                  {
                    markers.map((m, key) => {
                      const { lat, lng, icon } = m;
                      return (
                        <Marker position={{lat, lng}} onClick={handleMarker.bind(this, center)} onMouseOver={handleMarker.bind(this, center)} key={key} icon={icon}>
                          {showWindowData &&
                            <InfoWindow onCloseClick={() => setShowWindowData(false)} onMouseOver={() => setShowWindowData(true)} onMouseOut={() => setShowWindowData(false)}>
                              <p>Sta funzionandooooo</p>
                            </InfoWindow>
                          }
                        </Marker>
                      )
                    })
                  }
                </GoogleMap>
              )}
            </CRow>
            <CRow className="mt-4">
              <CCol sm="12" md="6" lg="6" xl="6">
                <UiInput type="text" name="address" label="Table.address"
                  //disabled={!props.editable}
                  value={address}
                  //onFocus={props.handleOnFocus.bind(props)}
                  onChange={handleAddressChange.bind(this)}
                //onBlur={props.handleOnBlur.bind(props)}
                />
              </CCol>
              <CCol sm="12" md="6" lg="6" xl="6" style={{ display: 'flex', alignItems: 'end' }}>
                <UiButton label={t('Common.open_map')} onClick={handleMap.bind(this)} />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      )}
    </I18n>
  )
}

export default UiMap