import React from 'react';
import { CFormInput, CFormLabel } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { I18n } from 'react-i18next';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

const propTypes = {
  type: PropTypes.oneOf(['tel,', 'text', 'email', 'password', 'number', 'currency']),
  name: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

function UiSearch(props) {

  const [search, setSearch] = useState(props.value ? props.value : '');
  const [oldVal, setOldVal] = useState(props.value);

  let idleTimeout = React.useRef(null);

  useEffect(() => {
    return () => { //triggered on componentWillUnmount
      if (idleTimeout.current) {
        clearTimeout(idleTimeout.current);
        idleTimeout = null;
      }
    }
  }, [])

  useEffect(() => {
    if (props.value !== oldVal) {
      setSearch(props.value ? props.value : '');
      setOldVal(props.value);
    }
  }, [props])
  
  function handleOnFocus(evt) {
    if (!props.disabled) {
      if (props.onFocus) props.onFocus(evt);
    }
  }

  function handleOnChange(evt) {
    setSearch(evt.target.value);
    if (!props.disabled) {
      const event = {
        target: {
          name: evt.target.name,
          value: evt.target.value && evt.target.value.length > 0 ? evt.target.value : null,
        },
      };

      if (idleTimeout.current) {
        clearTimeout(idleTimeout.current);
      }

      idleTimeout.current = setTimeout(() => {
        if (props.onChange) props.onChange(event);
        setOldVal(event.target.value);
      }, 750);
    }
  }

  function handleOnKeyPress(event = null) {
    if (event && event.key) {
      switch (event.key) {
        case 'Enter':
          event.preventDefault();
          break;
        // no default
      }
    }
  }
  
  function handleOnBlur() {
    if (!props.disabled) {
      const event = {
        target: {
          name: props.name,
          search,
        },
      };
      
      if (props.onBlur) props.onBlur(event);
    }
  }

  return (
    <I18n ns="translations">
      {t => (
        <div className="UiSearch">
          {props.label ? (
            <CFormLabel htmlFor={props.name} className="UiMainLabel">
              {t(props.label)} {props.required ? '*' : ''}
            </CFormLabel>
          ) : (
            ''
          )}
          <CFormInput type={props.type} id={props.name} name={props.name} disabled={props.disabled}
            title={props.title} placeholder={(props.disabled) ? '' : (props.placeholder) ? t(props.placeholder) : t('Common.search')}
            required={props.required} value={search}
            onFocus={handleOnFocus.bind(this)} onChange={handleOnChange.bind(this)} onBlur={handleOnBlur.bind(this)}
            onKeyPress={handleOnKeyPress.bind(this)} />
          {/* <CIcon icon="cis-search" className="UiSearchIcon" /> */}
        </div>
      )}
    </I18n>
  );
}

UiSearch.propTypes = propTypes;

export default UiSearch;
