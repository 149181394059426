import BaseService from './baseService';

export default class TenantsService extends BaseService {
  static instance = null;

  static getInstance(store) {
    if (!TenantsService.instance) {
      TenantsService.instance = new TenantsService(store);
    }
    return TenantsService.instance;
  }

  constructor(store) {
    const baseUrl = process.env.API_PATH;
    super(store, baseUrl, 'tenants', 'tenant');
  }

  addApiKey(tenantId, lang, token, okCallBack, koCallBack){
    return super.post(this.itemName, this.urlPath + "/" + tenantId + "/create-api-key", lang, token, null, okCallBack, koCallBack);
  }

  deleteApiKey(tenantId, lang, token, okCallBack, koCallBack){
    return super.delete(this.itemName, this.urlPath + "/" + tenantId + "/delete-api-key", lang, token, okCallBack, koCallBack);
  }
}