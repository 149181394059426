//GLOBAL
import React from 'react'
import { connect } from 'react-redux';
import { I18n } from 'react-i18next';
import i18n from 'i18next';
import ApplicationStore from '../../redux/store';
import cloneDeep from 'lodash/cloneDeep';

//UI
import { CBadge, CCard, CCardBody, CCol, CContainer, CFormLabel, CFormSwitch, CRow, CTableDataCell, CTableHeaderCell } from '@coreui/react-pro';

//UTILS
import withRouter from '../../utils/withNavigation';
import { ChromePicker, CirclePicker, SketchPicker } from 'react-color';
import ConstantsService from '../../services/constantsService';

// SERVICES
import TenantsAddressesService from '../../services/tenantsAddressesService';
import TenantsContactsService from '../../services/tenantsContactsService';
import LambdaService from '../../services/lambdaService';

//COMPONENTS
import { mapStateToProps } from './AbstractComponent';
import AbstractDetail from './AbstractDetail';
import UiInput from '../ui/UiInput';
import UiPictureUpload from '../ui/UiPictureUpload';
import UiColorPicker from '../ui/UiColorPicker';
import UiRelationBox from '../ui/UiRelationBox';
import UiButton from '../ui/UiButton';
import UiSingleFileUpload from '../ui/UiSingleFileUpload';
import UiModal from '../ui/UiModal';

class Tenant extends AbstractDetail {
  constructor(props) {
    super(props);

    this.tenantAddressesService = TenantsAddressesService.getInstance(ApplicationStore);
    this.tenantContactsService = TenantsContactsService.getInstance(ApplicationStore);
    this.lambdaService = LambdaService.getInstance(ApplicationStore);
  }

  /* componentDidMount(){
    this.onGetFileLambda("49_1702625270119.plf");
  } */

  buildHeaderSubtitle(t) {
    if (this.state.model){
      const subtitle = super.buildHeaderSubtitle();
      
      const badgeLabel = !!this.state.model?.active ? "active" : "not_active",
      badgeColor = !!this.state.model?.active ? "success" : "danger";

      return <span className='flex'>{subtitle} <CBadge style={{marginLeft: 10}} color={badgeColor}>{t('Table.' + badgeLabel)}</CBadge></span>;
    }
  }

  buildHeaderButtons(t) {
    if (this.props.location?.pathname.includes("/tenant/logged")) return;
    const buttonLabel = !!this.state.model.active ? "deactivate" : "activate";

    return (
      <>
        <CFormSwitch type='checkbox' checked={this.state.model.active} onClick={this.toggleModal.bind(this, "modalTenantStatus")} size='xl' />

        <UiModal title={t(`Common.handle_tenant_status`)} okLabel={t('Common.yes')} koLabel={t('Common.no')}
          isOpen={this.state.modalTenantStatus}
          onSubmit={this.handleOnActiveChange.bind(this, "modalTenantStatus")} onCancel={this.toggleModal.bind(this, "modalTenantStatus")}
          >
          <p>{t(`Common.confirm_${buttonLabel}_tenant`)}</p>
        </UiModal>
      </>
    );
  }

  handleColorChange(name, color) {
    const obj = {
      target: {
        name: name,
        value: `${color.rgb.r},${color.rgb.g},${color.rgb.b}`
      }
    }
    this.handleOnChange(obj);
  }

  renderContactHeader(){
    return <>
      <CTableHeaderCell></CTableHeaderCell>
      <CTableHeaderCell>{i18n.t("Table.first_name")}</CTableHeaderCell>
      <CTableHeaderCell>{i18n.t("Table.last_name")}</CTableHeaderCell>
      <CTableHeaderCell>{i18n.t("Table.email")}</CTableHeaderCell>
      <CTableHeaderCell>{i18n.t("Table.phone_number")}</CTableHeaderCell>
      <CTableHeaderCell></CTableHeaderCell>
    </>
  }

  renderAddressHeader(){
    return <>
      <CTableHeaderCell></CTableHeaderCell>
      <CTableHeaderCell>{i18n.t("Table.street")}</CTableHeaderCell>
      <CTableHeaderCell>{i18n.t("Table.state")}</CTableHeaderCell>
      <CTableHeaderCell>{i18n.t("Table.zip_code")}</CTableHeaderCell>
      <CTableHeaderCell>{i18n.t("Table.country")}</CTableHeaderCell>
      <CTableHeaderCell></CTableHeaderCell>
    </>
  }

  renderContactRow(contact) {
    const cardTitle = "SelectValues." + (contact.contact_type === "TECH" ? "technical_contact" : "administrative_contact");
    return <>
      <CTableHeaderCell>{i18n.t(cardTitle)}</CTableHeaderCell>
      <CTableDataCell>{contact.first_name}</CTableDataCell>
      <CTableDataCell>{contact.last_name}</CTableDataCell>
      <CTableDataCell>{contact.email}</CTableDataCell>
      <CTableDataCell>{contact.phone_number}</CTableDataCell>
    </>
  }

  renderAddressRow(address) {
    const cardTitle = "SelectValues." + (address.address_type === "BILLING" ? "billing_address" : "office_address");
    return <>
      <CTableHeaderCell>{i18n.t(cardTitle)}</CTableHeaderCell>
      <CTableDataCell>{address.street}</CTableDataCell>
      <CTableDataCell>{address.state}</CTableDataCell>
      <CTableDataCell>{address.zip_code}</CTableDataCell>
      <CTableDataCell>{address.country}</CTableDataCell>
    </>
  }

  handleOnAddContact(contact) {
    this.toggleModal('modalAddContact');
    const tenantId = this.props.location?.pathname.includes("/tenant/logged") ? "logged" : this.state.model.id;
    this.tenantContactsService.addItem(tenantId, contact, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this));
  }

  handleOnEditContact(contact) {
    this.toggleModal('modalAddContact');
    const tenantId = this.props.location?.pathname.includes("/tenant/logged") ? "logged" : this.state.model.id;
    this.tenantContactsService.updateItem(tenantId, contact, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this));
  }

  handleOnDeleteContact(contact) {
    this.toggleModal('modalDeleteContact');
    const tenantId = this.props.location?.pathname.includes("/tenant/logged") ? "logged" : this.state.model.id;
    this.tenantContactsService.deleteItem(tenantId, contact.id, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this));
  }

  handleOnAddAddress(address) {
    this.toggleModal('modalAddAddress');
    const tenantId = this.props.location?.pathname.includes("/tenant/logged") ? "logged" : this.state.model.id;
    this.tenantAddressesService.addItem(tenantId, address, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this));
  }

  handleOnEditAddress(address) {
    this.toggleModal('modalAddAddress');
    const tenantId = this.props.location?.pathname.includes("/tenant/logged") ? "logged" : this.state.model.id;
    this.tenantAddressesService.updateItem(tenantId, address, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this));
  }

  handleOnDeleteAddress(address) {
    this.toggleModal('modalDeleteAddress');
    const tenantId = this.props.location?.pathname.includes("/tenant/logged") ? "logged" : this.state.model.id;
    this.tenantAddressesService.deleteItem(tenantId, address.id, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this));
  }

  renderChildren(t) {
    const isPersonalTenant = this.props.location?.pathname.includes("/tenant/logged");
    const imagePath = this.state.model.image_path ? process.env.API_PATH + this.state.model.image_path : null;
    const tenantPrimaryColor = this.state.model.primary_color ? `rgb(${this.state.model.primary_color})` : "rgb(177,32,9)";
    const tenantFontColor = this.state.model.font_color ? `rgb(${this.state.model.font_color})` : "rgb(255,255,255)";
    return (
      <>
        <div key="tenant_info">
          <CCard className="mb-4">
            <CCardBody>
              <CRow>
                <CCol className="mb-3" sm="12" md="6" lg="6" xl="6">
                  <UiInput type="text" name="business_name" label="Table.business_name"
                    disabled={!!isPersonalTenant ? isPersonalTenant : !this.state.editable}
                    required={this.state.mandatoryFields.indexOf('business_name') >= 0}
                    value={this.state.model.business_name}
                    onFocus={this.handleOnFocus.bind(this)}
                    onChange={this.handleOnChange.bind(this)}
                    onBlur={this.handleOnBlur.bind(this)}
                  />
                </CCol>
                <CCol sm="12" md="6" lg="6" xl="6" className='mb-3 rightCol'>
                  <UiInput type="text" name="website" label="Table.website"
                    disabled={!this.state.editable}
                    required={this.state.mandatoryFields.indexOf('website') >= 0}
                    value={this.state.model.website}
                    onFocus={this.handleOnFocus.bind(this)}
                    onChange={this.handleOnChange.bind(this)}
                    onBlur={this.handleOnBlur.bind(this)}
                  />
                </CCol>

                <CCol sm="12" md="6">
                  <UiColorPicker
                    onColorChange={this.handleColorChange.bind(this,'primary_color')}
                    color={tenantPrimaryColor}
                    name="primary_color"
                    label="Table.primary_color"
                  />
                </CCol>

                <CCol sm="12" md="6" lg="6" xl="6" className='rightCol'>
                  <UiColorPicker
                    type="circle"
                    onColorChange={this.handleColorChange.bind(this,'font_color')}
                    color={tenantFontColor}
                    name="font_color"
                    label="Table.font_color"
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>

          <CCard>
            <CCardBody>
              <CRow>
                <CCol className="mb-3" sm="12" md="12" lg="12" xl="12">
                  <UiPictureUpload name='logo'
                    disabled={!this.state.editable}
                    value={imagePath}
                    onChange={this.onNewImageChange.bind(this, this.state.model, 'logo')}
                    onDelete={this.onImageDelete.bind(this, this.itemId)}
                    deletable fullScreenEnabled
                  />
                </CCol>
              </CRow>
              </CCardBody>
          </CCard>
        </div>

        <div key="economics">
          <CCard>
            <CCardBody>
              <CRow>
                <CCol className="mb-3" sm="12">
                  <UiInput type="text" name="vat" label="Table.vat_number"
                    disabled={!!isPersonalTenant ? isPersonalTenant : !this.state.editable}
                    required={this.state.mandatoryFields.indexOf('vat') >= 0}
                    value={this.state.model.vat}
                    onFocus={this.handleOnFocus.bind(this)}
                    onChange={this.handleOnChange.bind(this)}
                    onBlur={this.handleOnBlur.bind(this)}
                  />
                </CCol>

                {/* <CCol className="mb-3" sm="12" md="6" lg="6" xl="6" className='rightCol'>
                  <UiSelectClassic name='account_type' label={'Table.account_type'}
                    value={this.state.model.account_type}
                    disabled={!!isPersonalTenant ? isPersonalTenant : !this.state.editable}
                    fieldDisplayed={"label"} fieldReturned={"value"}
                    values={ConstantsService.accountType}
                    nosort={true}
                    onValueChanged={this.handleOnChange.bind(this)}
                    onChange={this.handleOnChange.bind(this)}
                    // forceTranslation alreadyTranslated={false}
                  />
                </CCol>

                <CCol className="mb-3" sm="12" md="6">
                  <UiInput type="text" name="expiration_date" label="Table.expiration_date"
                    disabled={!!isPersonalTenant ? isPersonalTenant : !this.state.editable}
                    required={this.state.mandatoryFields.indexOf('expiration_date') >= 0}
                    value={this.state.model.expiration_date}
                    onFocus={this.handleOnFocus.bind(this)}
                    onChange={this.handleOnChange.bind(this)}
                    onBlur={this.handleOnBlur.bind(this)}
                    />
                </CCol> */}

                <CCol className="mb-3" sm="12" md="6">
                  <UiInput type="text" name="app_env_id" label="Table.app_env_id"
                    disabled={!this.state.editable}
                    required={this.state.mandatoryFields.indexOf('app_env_id') >= 0}
                    value={this.state.model.app_env_id}
                    onFocus={this.handleOnFocus.bind(this)}
                    onChange={this.handleOnChange.bind(this)}
                    onBlur={this.handleOnBlur.bind(this)}
                    />
                </CCol>

                <CCol sm="12" md="6" className='mb-3 rightCol'>
                  <UiInput type="text" name="app_api_key" label="Table.app_api_key"
                    disabled={!this.state.editable}
                    required={this.state.mandatoryFields.indexOf('app_api_key') >= 0}
                    value={this.state.model.app_api_key}
                    onFocus={this.handleOnFocus.bind(this)}
                    onChange={this.handleOnChange.bind(this)}
                    onBlur={this.handleOnBlur.bind(this)}
                    />
                </CCol>

                <CCol sm="12" lg="9">
                  <UiInput type="text" name="api_key" label="Table.api_key"
                    readOnly
                    value={this.state.model.api_key}
                    onFocus={this.handleOnFocus.bind(this)}
                    onChange={this.handleOnChange.bind(this)}
                    onBlur={this.handleOnBlur.bind(this)}
                    />
                </CCol>
                <CCol sm="12" lg="3" className="rightCol">
                  <UiButton label={t(`Common.${this.state.model.api_key ? "delete_api_key" : "generate_api_key"}`)}
                    className="justify-content-center" classNameContainer="ButtonsContainerEnd ms-0 mt-2 mt-sm-0"
                    onClick={this.handleApiKey.bind(this)}
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </div>

        <div key="contacts_addresses">
          <CRow className='mb-4'>
            <CCol>
              <UiRelationBox
                title='Table.contacts' icon='cis-group' model={this.state.model.contacts} disabled={!this.state.editable}
                currentUser={this.globalService.currentUser} apiReducer={this.props.apiReducer} route='tenant_contacts' apiReducerName={'tenants'}
                renderHeader={this.renderContactHeader.bind(this)}
                renderRow={this.renderContactRow.bind(this)}
                insertable
                editable
                deletable
                onEdit={this.handleOnEditContact.bind(this)}
                onAdd={this.handleOnAddContact.bind(this)}
                onDelete={this.handleOnDeleteContact.bind(this)}
                toggleModal={this.toggleModal.bind(this, 'modalAddContact')}
                isModalOpen={this.state.modalAddContact}
                toggleDeleteModal={this.toggleModal.bind(this, 'modalDeleteContact')}
                isDeleteModalOpen={this.state.modalDeleteContact}
                onSearchChange={this.handleOnSearchChange.bind(this)}
                /* 
                isCreationValid={this.isCreationValid.bind(this, 'address')}
                */
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <UiRelationBox
                title='Table.addresses' icon='cis-group' model={this.state.model.addresses} disabled={!this.state.editable}
                currentUser={this.globalService.currentUser} apiReducer={this.props.apiReducer} route='tenant_addresses' apiReducerName={'tenants'}
                renderHeader={this.renderAddressHeader.bind(this)}
                renderRow={this.renderAddressRow.bind(this)}
                insertable
                editable
                deletable
                onEdit={this.handleOnEditAddress.bind(this)}
                onAdd={this.handleOnAddAddress.bind(this)}
                onDelete={this.handleOnDeleteAddress.bind(this)}
                toggleModal={this.toggleModal.bind(this, 'modalAddAddress')}
                isModalOpen={this.state.modalAddAddress}
                toggleDeleteModal={this.toggleModal.bind(this, 'modalDeleteAddress')}
                isDeleteModalOpen={this.state.modalDeleteAddress}
                onSearchChange={this.handleOnSearchChange.bind(this)}
                /*
                isCreationValid={this.isCreationValid.bind(this, 'address')}
                */
              />
            </CCol>
          </CRow>
        </div>

        <div key="3d_upload">
          <CCard className='mb-4'>
            <CCardBody>
              <CRow>
                <CCol>
                  <UiSingleFileUpload
                      name="tenant_env"
                      multiple={false}
                      filename={this.state.model.environment_id}
                      label="Common.import_env"
                      description="Common.import_env_des"
                      onChange={this.onUploadFileLambda.bind(this, ".env")}
                      tooltipMsg="import_env"
                      onDelete={this.onDeleteFileLambda.bind(this, ".env")}
                      />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>

          <CCard className='mb-4'>
            <CCardBody>
              <CRow>
                <CCol>
                  <UiSingleFileUpload
                      name="tenant_plf"
                      filename={this.state.model.platform_id}
                      multiple={false}
                      label="Common.import_plf"
                      description="Common.import_plf_des"
                      onChange={this.onUploadFileLambda.bind(this, ".plf")}
                      tooltipMsg="import_plf"
                      onDelete={this.onDeleteFileLambda.bind(this, ".plf")}
                      />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>

          <CCard>
            <CCardBody>
              <CRow>
                <CCol>
                  <UiSingleFileUpload
                    name="tenant_hotspot"
                    filename={this.state.model.tenant_icon_hotspot_id}
                    multiple={false}
                    label="Common.import_png"
                    description="Common.import_png_des"
                    onChange={this.onUploadFileLambda.bind(this, ".png")}
                    tooltipMsg="import_png"
                    onDelete={this.onDeleteFileLambda.bind(this, ".png")}
                    />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps)(withRouter(Tenant));