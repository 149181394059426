//GLOBAL
import axios from 'axios/index';

//ACTIONS
import { tokenNeeded, invalidateToken, receiveToken } from '../redux/tokenReducer';
import ObjectsUtils from '../utils/objectsUtils';

// SERVICES

export default class Oauth2Service {

	static instance = null;

	static getInstance(store, baseUrl, client_id, client_secret, storage, basename) {
		if (!Oauth2Service.instance) {
			Oauth2Service.instance = new Oauth2Service(store, baseUrl, client_id, client_secret, storage, basename);
		}
		return Oauth2Service.instance;
	}

	constructor(store, baseUrl, client_id, client_secret, storage, basename) {
		this.store = store;
		this.clientId = (client_id && client_id.length > 0) ? client_id : undefined;
		this.clientSecret = (client_secret && client_secret.length > 0) ? client_secret : undefined;
		this.basename = basename;

		this.axiosInstance = axios.create({
			baseURL: baseUrl,
			timeout: 5000
		});

		if (storage) {
			this.localStorage = storage;
		}
	}

	getRedirectURI() {
		return window.location.origin + ((this.basename) ? (this.basename.endsWith("/")) ? this.basename : this.basename + '/' : '/') + 'login';
	}

	getToken(username, password, okCallback, koCallback) {
		this.clearLocalStorage();

		let path = '/oauth/token';
		let body = {
			grant_type: "password",
			client_id: this.clientId,
			client_secret: this.clientSecret,
			username: username,
			password: password,
			policies: ""
		};

		let getKoCallBack = (error) => {
			this.handleTokenError(error);
			if (koCallback) {
				koCallback(error);
			}
		}

		this.store.dispatch(tokenNeeded(username, password));

		return this.axiosInstance.post(path, body)
			.then(this.handleTokenSuccess.bind(this))
			.catch(getKoCallBack.bind(this));
	}

	refreshToken(okCallback, koCallback) {
		const auth = this.localStorage.getItem('auth');
		if (auth) {
			const cookie = JSON.parse(this.localStorage.getItem('auth'));
			this.localStorage.removeItem('auth');

			let path = '/refresh-token';
			let body = {
				refresh_token: cookie.refreshToken,
			};

			body = (process.env.API_RESPONSE_FORMAT === 'camelCase') ? ObjectsUtils.snakeCaseToCamelCase(body) : body;

			let koCallBack = (error) => {
				this.handleTokenError(error);
				if (koCallback) {
					koCallback(error);
				}
			}

			this.store.dispatch(tokenNeeded());

			return this.axiosInstance.post(path, body)
				.then(this.handleTokenSuccess.bind(this))
				.catch(koCallBack.bind(this));
		}
		return null;
	}

	resetPassword(email, okCallback, koCallback) {
		let path = '/users/reset_password';
		let body = {
			email: email,
			client_id: this.clientId,
			client_secret: this.clientSecret
		};

		return this.axiosInstance.post(path, body)
			.then(okCallback.bind(this))
			.catch(koCallback.bind(this));
	}

	authorizeByCode(code) {
		this.clearLocalStorage();

		let path = '/users/token';
		let body = { code: code };

		return this.axiosInstance.post(path, body)
			.then(this.handleTokenSuccess.bind(this))
			.catch(this.handleTokenError.bind(this));
	}

	getAuthorizationCookie() {
		let auth = null;
		let cookie = this.localStorage.getItem('auth');
		if (cookie !== null && cookie != undefined) {
			auth = JSON.parse(cookie);
		}
		return auth;
	}

	setAuthorizationCookie(res) {
		let now = new Date();
		let token = {
			accessToken: (res.accessToken) ? res.accessToken : (res.access_token) ? res.access_token : res.token,
			tokenType: (res.tokenType) ? res.tokenType : (res.token_type) ? res.token_type : 'Bearer',
			refreshToken: (res.refreshToken) ? res.refreshToken : res.refresh_token,
			expiresIn: (res.expTokenSec) ? res.expTokenSec : (res.expiresAt) ? res.expiresAt : res.expires_at,
			policies: res.userData.polices ? res.userData.polices : res.userData.policies ? res.userData.policies : [],
			timestamp: now.getTime()
		};
		this.localStorage.setItem('auth', JSON.stringify(token));
		this.store.dispatch(receiveToken(token));
	}

	isPolicyLogged(policy) {
		let auth = this.getAuthorizationCookie();
		if (!auth || !auth.policies) {
			return false;
		}
		return (auth.policies.indexOf(policy) >= 0);
	}

	clearLocalStorage() {
		// TODO: DECOMMENTARE QUANDO SI VA IN PROD
		/* this.localStorage.removeItem('auth');
		this.localStorage.removeItem('user');
		this.localStorage.removeItem('msgQueue');
		this.localStorage.removeItem('msgQueueDate'); */
		const language = this.localStorage.getItem("i18nextLng");
		this.localStorage.clear();
		this.localStorage.setItem("i18nextLng", language);
	}

	logout() {
		this.clearLocalStorage();
		this.store.dispatch(invalidateToken());
		window.location.href = ((this.basename) ? (this.basename.endsWith("/")) ? this.basename : this.basename + '/' : '/') + 'login';
	}

	handleTokenSuccess(res) {
		if (res.data?.data?.tokens) {
			this.setAuthorizationCookie(res.data.data.tokens);
		} else if (res.data?.data) {
			this.setAuthorizationCookie(res.data.data);
		} else if (res.data) {
			this.setAuthorizationCookie(res.data);
		} else {
			this.setAuthorizationCookie(res);
		}
	}

	handleTokenError(error) {
		this.store.dispatch(invalidateToken(error));
	}

}