import React, { useEffect } from 'react';
import { CRow, CFormLabel, CFormInput, CButton } from '@coreui/react-pro';
import { I18n } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';

import UiButton from './UiButton';
import { useState } from 'react';
import { useRef } from 'react';
import CIcon from '@coreui/icons-react';

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  required: PropTypes.bool,
  filename: PropTypes.string
};

function UiFilesUpload(props) {

  const [isDragging, setIsDragging] = useState(false);
  const [files, setFiles] = useState(props.filename ? [{name: props.filename}] : []);
  const [hasImage, setHasImage] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();

  let UiInputFile = useRef(null);

  useEffect(() => {
    document.addEventListener('resetFile', resetFile);

    return () => {
      document.removeEventListener('resetFile', resetFile);
    }
  }, [])

  useEffect(() => {
    console.log(files)
  }, [files])

  function resetFile() {
    setFiles([])
  }

  function handleImageChange(ev) {
    ev.preventDefault();
    const tmpfiles = cloneDeep(files);

    if (ev.target.files && ev.target.files.length > 0) {
      if (props.multiple) {
        for (let i = 0; i < ev.target.files.length; i++) {
          tmpfiles[tmpfiles.length] = ev.target.files[i];
        }
      } else {
        tmpfiles[0] = ev.target.files[0];
        setPreviewUrl(URL.createObjectURL(tmpfiles[0]))
      }
    }
    setFiles(tmpfiles);
    dispatchChange(tmpfiles);
  }

  function handleDragOver(ev) {
    ev.preventDefault();

    if (!isDragging) {
      setIsDragging(true);
    }
  }

  function handleDrop(ev) {
    ev.preventDefault();
    const tmpfiles = cloneDeep(files);
    if (ev.dataTransfer.files && ev.dataTransfer.files.length > 0) {
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        tmpfiles[tmpfiles.length] = ev.dataTransfer.files[i];
      }
    }
    setFiles(tmpfiles);
    dispatchChange(tmpfiles);
  }

  function handleDragLeave() {
    if (isDragging) {
      setIsDragging(false);
    }
  }

  function dispatchChange(files) {
    if (!props.multiple && props.filename) return;

    const event = {
      target: {
        name: props.name,
        value: (props.multiple) ? files : files[0],
      },
    };
    if (props.onChange) {
      props.onChange(event);
    }
  }

  return (
    <I18n ns="translations">
      {t => (
        <div className="UiPictureUpload">
          {props.label ? (
            <CFormLabel className="UiMainLabel">
              {t(props.label)} {props.required ? '*' : ''}
            </CFormLabel>
          ) : (
            ''
          )}
          <div className="Detail_imageCover previewComponent">
            <div className={`UiPictureUploadPreview ${isDragging ? ' dragInFile' : ''} ${hasImage ? ' imagePreview' : ''} mx-auto`}
              draggable onDragEnter={handleDragOver.bind(this)} onDragOver={handleDragOver.bind(this)}
              onDragLeave={handleDragLeave.bind(this)} onDrop={handleDrop.bind(this)}>
              <div className={`previewPlaceholder${isDragging ? ' dragInFile' : ''} ${!hasImage ? ' noImage' : ''}`}>
                <div className={`placeholderContent${!hasImage ? ' noImage' : ''}`}>
                  {!files || files.length === 0 &&
                    <p className="placeholderMessage">{t(props.multiple ? 'Common.dnd_files_here' : 'Common.dnd_file_here')}</p>
                  }

                  {files && files.map((file, index) => (
                    <p key={`file-upload-${props.name}-${index}`} className="placeholderMessage">{file.name}</p>
                  ))}

                  {files && files.length >= 1 ?
                      <CButton variant="ghost" color="danger" disabled={props.disabled}
                        title={t('Common.delete_file')}
                        onClick={props.onDelete}
                      >
                        <CIcon icon="cis-trash" />
                      </CButton>
                    :
                    <UiButton className="UiAddImageButton" label={t('Common.change_file')} onClick={() => (UiInputFile.click())} />
                  }
                </div>
              </div>
            </div>
            <CRow>
              <div>
                <CFormInput type="file" ref={node => {
                  if (node) {
                    UiInputFile = node;
                  }
                }}
                  id="UiInputFile" className="UiInputFile" name="UiInputFile" onChange={e => handleImageChange(e)} multiple={props.multiple} key={Date.now()} />
              </div>
            </CRow>
          </div>
        </div>
      )}
    </I18n>
  );
}

UiFilesUpload.propTypes = propTypes;

export default UiFilesUpload;