//GLOBAL
import React from 'react'
import { connect } from 'react-redux';
import ApplicationStore from '../../redux/store';
import i18n from 'i18next';

//SERVICES
import LambdaService from '../../services/lambdaService';
import ConstantsService from '../../services/constantsService';
import ProjectUsersService from '../../services/projectUsersService';

//UI
import { CBadge, CCard, CCardBody, CCol, CContainer, CFormInput, CFormSwitch, CRow, CSmartTable, CTableDataCell, CTableHeaderCell } from '@coreui/react-pro';

//UTILS
import withRouter from '../../utils/withNavigation';

//COMPONENTS
import { mapStateToProps } from './AbstractComponent';
import AbstractDetail from './AbstractDetail';
import UiCard from '../ui/UiCard';
import UiInput from '../ui/UiInput';
import UiAutocomplete from '../ui/UiAutocomplete';
import UiRelationBox from '../ui/UiRelationBox';
import UiButton from '../ui/UiButton';
import UiModal from '../ui/UiModal';
import UiFilesUpload from '../ui/UiFilesUpload';
import UiSingleFileUpload from '../ui/UiSingleFileUpload';

class Project extends AbstractDetail {
  constructor(props) {
    super(props);

    this.projectUsersService = ProjectUsersService.getInstance(ApplicationStore)
    this.lambdaService = LambdaService.getInstance(ApplicationStore);
  }

  buildHeaderSubtitle(t) {
    if (this.state.model) {
      const subtitle = super.buildHeaderSubtitle();
      
      const badgeLabel = !!this.state.model?.active ? "active" : "not_active",
      badgeColor = !!this.state.model?.active ? "success" : "danger";
      
      return <span className='flex'>{subtitle} <CBadge style={{marginLeft: 10}} color={badgeColor}>{t('Table.' + badgeLabel)}</CBadge></span>;
    }
  }

  buildHeaderButtons(t) {
    const buttonLabel = !!this.state.model.active ? "deactivate" : "activate";

    return (
      <>
        <CFormSwitch type='checkbox' checked={this.state.model.active} onClick={this.toggleModal.bind(this, "modalProjectStatus")} size='xl' />

        <UiModal title={t(`Common.handle_project_status`)} okLabel={t('Common.yes')} koLabel={t('Common.no')}
          isOpen={this.state.modalProjectStatus}
          onSubmit={this.handleOnActiveChange.bind(this, "modalProjectStatus")} onCancel={this.toggleModal.bind(this, "modalProjectStatus")}
          >
          <p>{t(`Common.confirm_${buttonLabel}_project`)}</p>
        </UiModal>
      </>
    );
  }
  
  // FUNCTION TO RENDER  USERS ROW INSIDE UIRELATIONBOX

  renderUserHeader() {
    return <>
      <CTableHeaderCell>{i18n.t("Table.first_name")}</CTableHeaderCell>
      <CTableHeaderCell>{i18n.t("Table.last_name")}</CTableHeaderCell>
      <CTableHeaderCell>{i18n.t("Table.email")}</CTableHeaderCell>
      <CTableHeaderCell>{i18n.t("Table.role")}</CTableHeaderCell>
      <CTableHeaderCell></CTableHeaderCell>
    </>
  }

  renderUserRow(user) {
    return (
      <>
        <CTableDataCell>
          {user.first_name}
        </CTableDataCell>
        <CTableDataCell>
          {user.last_name}
        </CTableDataCell>
        <CTableDataCell>
          {user.email}
        </CTableDataCell>
        <CTableDataCell>
          {user.role_name}
        </CTableDataCell>
      </>
    );
  }

  // FUNCTION TO TOGGLE MODAL AND POST/DELETE A NEW USER

  handleOnAddUser(user) {
    this.toggleModal('modalAddUser');
    
    this.projectUsersService.addUserToProject(this.state.model[ConstantsService.defaultDBIdentifier], user.user_id, this.globalService.currentLanguage, this.globalService.currentUser, this.addProjectUserOkCallback.bind(this), this.koCallback.bind(this));
  }

  handleOnDeleteUser(user) {
    this.toggleModal('modalDeleteUser');
    this.projectUsersService.deleteUserFromProject(this.state.model[ConstantsService.defaultDBIdentifier], user[ConstantsService.defaultDBIdentifier], this.globalService.currentLanguage, this.globalService.currentUser, this.deleteProjectUserOkCallback.bind(this), this.koCallback.bind(this));
  }
  
  renderChildren(t) {
    let viewTenant = false;
    if (this.globalService?.currentUser?.policies.includes("tenant_view")) {
      viewTenant = true;
    }
    return (
      <>
        <div key={"project_info"}>
          <CCard className='mb-4'>
            <CCardBody>
              <CRow>
                <CCol sm="12" md={viewTenant ? "6" : "12"}>
                  <UiInput type="text" name="project_name" label="Table.project_name"
                    disabled={!this.state.editable}
                    required={this.state.mandatoryFields.indexOf('project_name') >= 0}
                    value={this.state.model.project_name}
                    onFocus={this.handleOnFocus.bind(this)}
                    onChange={this.handleOnChange.bind(this)}
                    onBlur={this.handleOnBlur.bind(this)}
                    />
                </CCol>
                {
                  viewTenant &&
                  <CCol sm="12" md="6" lg="6" xl="6" className='rightCol color-column'>
                    <UiAutocomplete type="text" name="tenant_id" reducer="tenants" label="Table.tenant_name" disabled={!this.state.editable}
                      value={this.state.model.tenant_id} required={this.state.mandatoryFields.indexOf('tenant_id') >= 0} apiReducer={this.props.apiReducer}
                      values={this.props.apiReducer.tenants?.data} fieldDisplayed="business_name" fieldReturned={ConstantsService.defaultDBIdentifier}
                      onFocus={this.handleOnFocus.bind(this)} onChange={this.handleOnChange.bind(this)} onBlur={this.handleOnBlur.bind(this)}
                      onValueChanged={this.handleOnForcedChange.bind(this)} onSearchChange={this.handleOnSearchChange.bind(this)}
                      />
                  </CCol>
                }
              </CRow>
            </CCardBody>
          </CCard>

          <CRow>
            <CCol>
              <UiRelationBox
                title='Table.users' icon='cis-group' 
                model={this.state.model.users} disabled={!this.state.editable}
                currentUser={this.globalService.currentUser} apiReducer={this.props.apiReducer} route='project_users' apiReducerName={'projects'}
                renderHeader={this.renderUserHeader.bind(this)}
                renderRow={this.renderUserRow.bind(this)}
                insertable
                deletable
                // cardClassName={"colored-header"}
                onAdd={this.handleOnAddUser.bind(this)}
                onDelete={this.handleOnDeleteUser.bind(this)}
                toggleModal={this.toggleModal.bind(this, 'modalAddUser')}
                isModalOpen={this.state.modalAddUser} 
                toggleDeleteModal={this.toggleModal.bind(this, 'modalDeleteUser')}
                isDeleteModalOpen={this.state.modalDeleteUser}
                onSearchChange={this.handleOnSearchChange.bind(this)}
                // isCreationValid={this.isCreationValid.bind(this, 'address')}
              />
            </CCol>
          </CRow>
        </div>
        
        <div key={"3d_upload"}>
          <CCard>
            <CCardBody>
              <CRow>
                <CCol sm="12">
                  <UiSingleFileUpload
                    name="project_hotspot"
                    filename={this.state.model.project_icon_hotspot_id}
                    multiple={false}
                    label="Common.import_png"
                    description="Common.import_png_des"
                    onChange={this.onUploadFileLambda.bind(this, ".png")}
                    tooltipMsg="import_png"
                    onDelete={this.onDeleteFileLambda.bind(this, ".png")}
                    />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </div>
      </>
    )
  }
}

export default connect(mapStateToProps)(withRouter(Project));