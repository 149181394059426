import React, { useState, useEffect } from 'react';
import { CFormLabel } from '@coreui/react-pro';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { I18n } from 'react-i18next';
import i18n from 'i18next';

import UiCheckbox from './UiCheckbox';

import PropTypes from 'prop-types';
const propTypes = {
  fieldReturned: PropTypes.string,
  fieldDisplayed: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.array,
  values: PropTypes.array,
  columns: PropTypes.number,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  alreadyTranslated: PropTypes.bool,
  sortValue: PropTypes.string
};

function UiSortableGrid(props) {
  const [currentSelected, setCurrentSelected] = useState([]);
  const [currentSelectedValues, setCurrentSelectedValues] = useState([]);
  const [allGroupSelected, setAllGroupSelected] = useState(false);
  const [fieldDisplayed, setFieldDisplayed] = useState();
  const [fieldReturned, setFieldReturned] = useState();
  const [invalid, setInvalid] = useState();
  const [values, setValues] = useState([]);

  useEffect(() => {
    setFieldDisplayed(props.fieldDisplayed);
    setFieldReturned(props.fieldReturned);
    const oldValues = (values && values.length > 0) ? values : [];
    const newValues = (props.values && props.values.length > 0) ? props.values : [];
    let localValues;
    if (!isEqual(newValues, oldValues)) {
      localValues = cloneDeep(newValues);
    } else {
      localValues = cloneDeep(values);
    }
    const oldVal = (currentSelectedValues && currentSelectedValues.length > 0) ? currentSelectedValues : [];
    const newVal = (props.value && props.value.length > 0) ? props.value : [];
    if (!isEqual(newVal, oldVal)) {
      let localCurrentSelected = []
      for (const val of newVal) {
        localCurrentSelected.push(localValues.find((obj) => ((props.fieldReturned) ? ''+obj[props.fieldReturned] === ''+val : ''+obj === ''+val)));
      }
      setCurrentSelected(localCurrentSelected);
      setCurrentSelectedValues(newVal);
      setAllGroupSelected(newVal && newVal.length === props.values.length);
    }
    setValues(localValues);
  }, [props])

  function selectAllCheckboxGroup() {
    if (!props.disabled && props.onChange) {
      let value = [];

      if (!allGroupSelected) {
        value = (fieldReturned) ? props.values.map((obj) => `${obj[fieldReturned]}`) : props.values;
      }

      const event = {
        target: {
          name: props.name,
          value,
        },
      };

      props.onChange(event);
    }
  }

  function handleOnChange(evt) {
    if (!props.disabled) {
      let localCurrentSelectedValues = cloneDeep(currentSelectedValues);

      let index = -1;
      const element = evt.target.name;
      const { value } = evt.target;

      if (!value) {
        index = localCurrentSelectedValues.indexOf(element);
        if (index > -1) localCurrentSelectedValues.splice(index, 1);
      } else {
        localCurrentSelectedValues.push(element);
      }

      const event = {
        target: {
          name: props.name,
          value: localCurrentSelectedValues,
        },
      };
      if (props.onChange) props.onChange(event);
    }
  }

  function onSortEnd({ oldIndex, newIndex }) {
    const newCurrentSelected = arrayMove(currentSelected, oldIndex, newIndex);
    const newCurrentSelectedValues = (fieldReturned) ? newCurrentSelected.map((obj) => `${obj[fieldReturned]}`) : newCurrentSelected;
    setCurrentSelected(newCurrentSelected);
    setCurrentSelectedValues(newCurrentSelectedValues);

    if (props.onChange) {
      const event = {
        target: {
          name: props.name,
          value: newCurrentSelectedValues,
        },
      };
      props.onChange(event);
    }
  }

  const itemWidthPerc = Math.floor(100 / props.columns) - 1;

  function calcValue(current, currentSelectedValues, fieldReturned) {
    return (!props.checkable || currentSelectedValues.indexOf((fieldReturned) ? `${current[fieldReturned]}` : `${current}`) > -1);
  }

  const SortableItem = SortableElement(({ value }) => (
    <div className={`sortablebox widthperc-${itemWidthPerc} ${(calcValue(value, currentSelectedValues, fieldReturned) === true) ? 'selected' : ''}`}>
      {props.checkable ? (
        <UiCheckbox name={(fieldReturned) ? value[fieldReturned] : value}
          label={(fieldDisplayed) ? value[fieldDisplayed] : ((String(value).indexOf('Table.') !== 0 && String(value).indexOf('Common.') !== 0 && !props.alreadyTranslated) ? `SelectValues.${value}` : value)}
          disabled={props.disabled} group
          value={calcValue(value, currentSelectedValues, fieldReturned)}
          onChange={handleOnChange.bind(this)} />
      ) : (
        <CFormLabel className="UiMainLabel">
          {(fieldDisplayed) ? value[fieldDisplayed] : ((String(value).indexOf('Table.') !== 0 && String(value).indexOf('Common.') !== 0 && !props.alreadyTranslated) ? `SelectValues.${value}` : value)}
        </CFormLabel>
      )}
    </div>
  ));

  const SortableList = SortableContainer(({ items }) => {
    const collection = values?.filter((obj) => currentSelectedValues.indexOf((fieldReturned) ? ''+obj[fieldReturned] : ''+obj) === -1);
    return (
      <div className="sortablecontainer">
        {items.map((item, index) => <SortableItem key={`sortable-item-${props.name}-${index}`} index={index} value={item} collection="enabled" />)}
        {collection.map((item, index) => <SortableItem key={`sortable-item-${props.name}-${index}`} index={index} value={item} collection="disabled" disabled />)}
      </div>
    );
  });

  return (
    <I18n ns="translations">
      {t => (
        <div className="UiCheckboxGroup">
          {props.checkable && (
            <div style={{ width: '100%', height: '40px' }}>
              <UiCheckbox label={props.label ? t(props.label) : ''} group disabled={props.disabled}
                value={allGroupSelected} required={props.required}
                onChange={selectAllCheckboxGroup.bind(this)} />
            </div>
          )}
          <div>
            <SortableList items={currentSelected} onSortEnd={onSortEnd.bind(this)} axis="xy" />
          </div>
        </div>
      )}
    </I18n>
  );
}

UiSortableGrid.propTypes = propTypes;

export default UiSortableGrid;
