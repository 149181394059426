import BaseService from './baseService';

export default class ProjectsService extends BaseService {
  static instance = null;

  static getInstance(store) {
    if (!ProjectsService.instance) {
      ProjectsService.instance = new ProjectsService(store);
    }
    return ProjectsService.instance;
  }

  constructor(store) {
    const baseUrl = process.env.API_PATH;
    super(store, baseUrl, 'projects', 'project');
  }

  getProductsList(projectId, lang, token, okCallBack, koCallBack) {
    return super.get(this.listName + "_products", this.urlPath + "/" + projectId + "/products", lang, token, null, okCallBack, koCallBack);
  }
}
