// GLOBAL
import React from 'react';
import { CForm, CRow } from '@coreui/react-pro';
import { I18n } from 'react-i18next';
import i18n from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

// SERVICES
import ConstantsService from '../../services/constantsService';
import TableMapService from '../../services/tableMapService';

// UTILS
import ObjectsUtils from '../../utils/objectsUtils';

// COMPONENTS
import AbstractComponent from './AbstractComponent';
import UiBasicGridFilters from '../ui/UiBasicGridFilters';
import UiAdvancedGridFilters from '../ui/UiAdvancedGridFilters';
import UiFlatTable from '../ui/UiFlatTable';
import UiModal from '../ui/UiModal';
import UiCreationsModal from '../ui/UiCreationsModal';
import UiSearch from '../ui/UiSearch';
import { toast } from 'react-toastify';

export default class AbstractGrid extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      viewable: false,
      insertable: false,
      editable: false,
      deletable: false,
      notinsertable: false,
      noteditable: false,
      notdeletable: false,
      currentSection: null,
      selectedItems: {},
      modalAdd: false,
      modalDelete: false,
      basicFilter: null,
      errorsModal: null,
      deleteCount: 0,
      exportData: {},
      importData: {},
      modelLoaded: false,
      notClickable: false,
      itemsAreSelectable: true,
      selectable: true
    };

    this.updateTimeout = null;
  }

  componentWillUnmount() {
    if (this.updateTimeout) clearTimeout(this.updateTimeout);
  }

  populateServices() {
    if (!this.globalService.currentUser || !(this.globalService.currentUser.userId !== 0)) {
      return;
    }

    if (!this.sectionService) {
      super.populateServices();

      this.sectionService.initList();
    }

    if (this.state && this.state.basicFilter) {
      this.updateModel(this.state.basicFilter);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = cloneDeep(prevState);
    if (nextProps.globalServiceReducer && nextProps.globalServiceReducer.status === 'SESSION_READY' && nextProps.globalServiceReducer.currentUser
      && (!prevState.currentSection || prevState.currentSection.sectionId !== nextProps.globalServiceReducer.currentSection.sectionId)
      && nextProps.apiReducer[nextProps.globalServiceReducer.currentSection.sectionId]
      && nextProps.apiReducer[nextProps.globalServiceReducer.currentSection.sectionId].status === 'INITIALIZED') {
      let settedFilters = {};
      try {
        const { sectionDefaultFilters } = nextProps.globalServiceReducer.currentSection;
        if (localStorage.getItem(`${nextProps.globalServiceReducer.currentSection.sectionId}Filters`)) {
          settedFilters = JSON.parse(localStorage.getItem(`${nextProps.globalServiceReducer.currentSection.sectionId}Filters`));
          if (settedFilters && !settedFilters.page) {
            settedFilters = {
              paginate: sectionDefaultFilters && sectionDefaultFilters.numPagination ? sectionDefaultFilters.numPagination : ConstantsService.defaultItemsGridNumber,
              page: 1,
              sort: sectionDefaultFilters && sectionDefaultFilters.sort ? sectionDefaultFilters.sort : 'id',
              order: sectionDefaultFilters && sectionDefaultFilters.order ? sectionDefaultFilters.order : 'ASC',
              elastic_query: '',
              filter: settedFilters,
              query_origin: 'table',
            };
          }
          if (settedFilters && !Array.isArray(settedFilters.filter)) {
            const filters = [];
            if (settedFilters.filter.query) {
              settedFilters.elastic_query = settedFilters.filter.query;
              settedFilters.filter.query = undefined;
            }
            Object.keys(settedFilters.filter).forEach(key => {
              if (settedFilters.filter[key]) {
                const entry = settedFilters.filter[key];
                entry.key = key;
                filters.push(entry);
              }
            });
            settedFilters.filter = filters;
          }
        } else {
          settedFilters = {
            paginate: sectionDefaultFilters && sectionDefaultFilters.numPagination ? sectionDefaultFilters.numPagination : ConstantsService.defaultItemsGridNumber,
            page: 1,
            sort: sectionDefaultFilters && sectionDefaultFilters.sort ? sectionDefaultFilters.sort : 'id',
            order: sectionDefaultFilters && sectionDefaultFilters.order ? sectionDefaultFilters.order : 'ASC',
            elastic_query: '',
            filter: [],
            query_origin: 'table',
          };
        }
      } catch (e) {
        settedFilters = {
          paginate: ConstantsService.defaultItemsGridNumber,
          page: 1,
          sort: 'id',
          order: 'ASC',
          elastic_query: '',
          filter: [],
          query_origin: 'table',
        };
      }

      const viewable = nextProps.globalServiceReducer.currentUser.policies.indexOf(nextProps.globalServiceReducer.currentSection.sectionViewPolicy) >= 0 || nextProps.globalServiceReducer.currentSection.sectionViewPolicy === '*';
      const insertable = !prevState.notinsertable && (nextProps.globalServiceReducer.currentUser.policies.indexOf(nextProps.globalServiceReducer.currentSection.sectionCreatePolicy) >= 0 || nextProps.globalServiceReducer.currentSection.sectionCreatePolicy === '*');
      const editable = !prevState.noteditable && (nextProps.globalServiceReducer.currentUser.policies.indexOf(nextProps.globalServiceReducer.currentSection.sectionEditPolicy) >= 0 || nextProps.globalServiceReducer.currentSection.sectionEditPolicy === '*');
      const deletable = !prevState.notdeletatable && (nextProps.globalServiceReducer.currentUser.policies.indexOf(nextProps.globalServiceReducer.currentSection.sectionDeletePolicy) >= 0 || nextProps.globalServiceReducer.currentSection.sectionDeletePolicy === '*');

      newState = {
        viewable,
        insertable,
        editable,
        deletable,
        currentSection: nextProps.globalServiceReducer.currentSection,
        selectedItems: {},
        modalAdd: false,
        modalDelete: false,
        basicFilter: settedFilters,
        errorsModal: null,
        deleteCount: 0,
        exportData: {},
        importData: {},
        modelLoaded: false,
      };
    }
    return newState;
  }

  shouldComponentUpdate(nextProps, nextState) {
    const superShould = super.shouldComponentUpdate(nextProps, nextState);
    let should = false;
    if (this.sectionService) {
      if (!isEqual(this.state, nextState)) {
        if (!isEqual(this.state.selectedItems, nextState.selectedItems)) {
          return true;
        }
        if (this.state.basicFilter && nextState.basicFilter && !isEqual(this.state.basicFilter, nextState.basicFilter)) {
          if (isEqual(this.state.basicFilter.filter, nextState.basicFilter.filter)) {
            this.updateModel(nextState.basicFilter);
            return false;
          }
          should = true;
          if (nextState.basicFilter.filter && Object.keys(nextState.basicFilter.filter).length > 0) {
            if (Object.keys(nextState.basicFilter.filter).length !== Object.keys(this.state.basicFilter.filter).length) {
              this.updateModel(nextState.basicFilter);
              return false;
            }
            for (const key of Object.keys(nextState.basicFilter.filter)) {
              if (!isEqual(this.state.basicFilter.filter[key], nextState.basicFilter.filter[key])) {
                if (nextState?.basicFilter?.filter[key]?.value && ObjectsUtils.isArrayValid(nextState.basicFilter.filter[key].value)) {
                  this.updateModel(nextState.basicFilter);
                  return false;
                } else if (nextState?.basicFilter?.filter[key]?.value && Number.isNaN(parseInt(nextState.basicFilter.filter[key].value))) {
                  if (nextState?.basicFilter?.filter[key]?.value?.length >= 2) {
                    this.updateModel(nextState.basicFilter);
                    return false;
                  } else if (this.state?.basicFilter?.filter[key]?.value?.length >= 2) {
                    const filter = cloneDeep(nextState.basicFilter);
                    filter.filter[key].value = '';
                    this.updateModel(filter);
                    return false;
                  }
                } else {
                  this.updateModel(nextState.basicFilter);
                  return false;
                }
              }
            }
          } else if (this.state.basicFilter.filter && Object.keys(this.state.basicFilter.filter).length > 0) {
            this.updateModel(nextState.basicFilter);
            return false;
          }
        } else {
          if (!nextState.modelLoaded) {
            this.updateModel(nextState.basicFilter);
            return false;
          }
          should = true;
        }
      } else if (this.props.globalServiceReducer && nextProps.globalServiceReducer && !isEqual(this.props.globalServiceReducer, nextProps.globalServiceReducer)) {
        if (this.props.globalServiceReducer.currentLanguage !== nextProps.globalServiceReducer.currentLanguage) {
          this.updateModel(nextState.basicFilter);
          return false;
        }
        should = nextProps.globalServiceReducer.status === 'SESSION_READY';
      } else {
        if (!nextState.modelLoaded && nextState.basicFilter) {
          this.updateModel(nextState.basicFilter);
          return false;
        }
        should = true;
      }
    }
    return should && superShould;
  }

  updateModel(basicFilter) {
    if (!this.globalService.currentUser || !(this.globalService.currentUser.userId !== 0)) {
      return;
    }

    if (this.props.apiReducer && this.props.apiReducer[this.props.globalServiceReducer.currentSection.sectionId]
      && this.props.apiReducer[this.props.globalServiceReducer.currentSection.sectionId].status === 'LOADING') {
      if (this.updateTimeout) clearTimeout(this.updateTimeout);
      this.updateTimeout = setTimeout(() => { this.updateModel(basicFilter); }, 100);
      return;
    }

    this.setState({ modelLoaded: true }, () => {
      this.sectionService.getList(basicFilter, this.globalService.currentLanguage, this.globalService.currentUser, this.storeModel.bind(this));
    });

  }

  storeModel() {
    const lastSelected = (localStorage.getItem('lastSelected')) ? JSON.parse(localStorage.getItem('lastSelected')) : {};
    lastSelected[this.props.globalServiceReducer.currentSection.sectionId] = this.props.apiReducer[this.props.globalServiceReducer.currentSection.sectionId].data.map((obj) => obj[ConstantsService.defaultDBIdentifier]);
    lastSelected[this.props.globalServiceReducer.currentSection.sectionChild] = undefined;
    localStorage.setItem('lastSelected', JSON.stringify(lastSelected));
  }

  handleSearch(event) {
    const basicFilter = cloneDeep(this.state.basicFilter);
    basicFilter[event.target.name] = event.target.value;
    basicFilter.page = 1;
    this.setState({ basicFilter });

    const storageBasicFilter = cloneDeep(basicFilter);
    if (event.target.name && event.target.name === 'elastic_query' && event.target.value && event.target.value.length > 0 && event.target.value.length < 3) {
      storageBasicFilter.elastic_query = '';
    }
    localStorage.setItem(`${this.props.globalServiceReducer.currentSection.sectionId}Filters`, JSON.stringify(storageBasicFilter));
  }

  handleAdvancedSearch(filter) {
    let basicFilter = cloneDeep(this.state.basicFilter);
    if (filter === 'clear_all') {
      basicFilter = {
        paginate: basicFilter.paginate,
        page: 1,
        sort: 'id',
        order: 'ASC',
        elastic_query: '',
        filter: [],
        query_origin: 'table',
      };
    } else {
      basicFilter.page = 1;
      basicFilter.filter = filter ?? [];
    }
    localStorage.setItem(`${this.props.globalServiceReducer.currentSection.sectionId}Filters`, JSON.stringify(basicFilter));
    this.setState({ basicFilter });
  }

  handleSort(sort, order) {
    const basicFilter = cloneDeep(this.state.basicFilter);
    basicFilter.sort = sort;
    basicFilter.order = order;
    localStorage.setItem(`${this.props.globalServiceReducer.currentSection.sectionId}Filters`, JSON.stringify(basicFilter));
    this.setState({ basicFilter });
  }

  handlePage(link) {
    let basicFilter = cloneDeep(this.state.basicFilter);
    basicFilter.page = link;
    localStorage.setItem(`${this.props.globalServiceReducer.currentSection.sectionId}Filters`, JSON.stringify(basicFilter));
    this.setState({ basicFilter });
  }

  handlePaginate(paginate) {
    const basicFilter = cloneDeep(this.state.basicFilter);
    basicFilter.paginate = paginate;
    localStorage.setItem(`${this.props.globalServiceReducer.currentSection.sectionId}Filters`, JSON.stringify(basicFilter));
    this.setState({ basicFilter });
  }

  onOpenDetailNewTab(id, preventNav) {
    if (!preventNav) {
      window.open(`/${this.props.globalServiceReducer.currentSection.sectionChild}/${id}`, '_blank');
    }
  }

  openDetail(id, preventNav) {
    if (!preventNav) {
      this.props.navigate(`/${this.props.globalServiceReducer.currentSection.sectionChild}/${id}`);
    }
  }

  handleOnSelectChange(selectedItems) {
    this.setState({ selectedItems });
  }

  onTableButtonClick(eventType, selectedItems) {
    if (eventType === 'Edit') {
      this.onEditItem(selectedItems);
    } else if (eventType === 'Add' || eventType === 'Delete') {
      this.toggleModal(`modal${eventType}`);
    }
  }

  onAddItem(creationModel) {
    function okCallback(success) {
      this.toggleModal('modalAdd');
      if(success.data["id_user"]){
        return this.openDetail(success.data["id_user"]);
      }
      this.openDetail(success.data[ConstantsService.defaultDBIdentifier]);
    }

    function koCallback(error) {
      let errors = [];
      errors.push(ObjectsUtils.buildError(error));
      this.setState({ loadingModal: false, errorsModal: errors });
    }

    this.sectionService.addItem(creationModel, this.globalService.currentLanguage, this.globalService.currentUser, okCallback.bind(this), koCallback.bind(this));
  }

  onEditItem(selectedItems) {
    const ids = selectedItems;
    for (const id in ids) {
      if (ids[id] === true) {
        // window.open('/' + this.props.globalServiceReducer.currentSection.sectionChild + '/' + id, '_blank');
        this.props.navigate(`/${this.props.globalServiceReducer.currentSection.sectionChild}/${id}`);
        return;
      }
    }
  }

  onDeleteItem() {
    const ids = cloneDeep(this.state.selectedItems);
    this.setState({ deleteCount: Object.values(ids).filter(obj => obj === true).length });
    const errors = {};

    function okCallback(success) {
      let count = this.state.deleteCount;
      count--;
      this.setState({ deleteCount: count });
      this.updateModel(this.state.basicFilter);
      if (count === 0) {
        this.toggleModal('modalDelete');
        this.setState({ errorsModal: null });
      }
      return toast.success(i18n.t('Common.delete_success'))
    }

    function koCallback(errors, response, firsterror) {
      if (!!firsterror && !!firsterror.data && firsterror.data.message) {
        const key = response.data[this.globalService.currentSection.sectionMainField]
          ? response.data[this.globalService.currentSection.sectionMainField]
          : response.data[ConstantsService.defaultDBIdentifier];

        errors[response.data[ConstantsService.defaultDBIdentifier]] = `${key}: ${firsterror.data.message}`;
      }

      let count = this.state.deleteCount;
      count--;
      this.setState({ deleteCount: count });
      if (count === 0) {
        this.setState({ errorsModal: errors });
      }
    }

    for (const id in ids) {
      if (ids[id] === true) {
        this.sectionService.deleteItem(id, this.globalService.currentLanguage, this.globalService.currentUser, okCallback.bind(this), koCallback.bind(this, errors));
      }
    }
  }

  handleCreateIsValid(form) {
    let tableMap = TableMapService.getTableMapByGroup(this.globalService.currentUser.userGroup)[this.state.currentSection.sectionChild];
    let valid = true;
    if (form) {
      tableMap.forEach((field) => {
        if (field.mandatory && field.creation !== 0) {
          if (!form.hasOwnProperty(field.name)) {
            valid = false;
          } else if (form[field.name] === undefined || form[field.name] === null || form[field.name] === '') {
            valid = false;
          }
        }
      })

    }
    return valid;
  }

  renderLegend() {
    return (<div></div>);
  }

  renderCreateModal() {
    if (this.globalService && this.globalService.currentUser && this.globalService.currentUser.userId !== 0) {
      return (
        <UiCreationsModal title={this.state.currentSection.sectionNewItemsTitle}
          isOpen={this.state.modalAdd} errorsModal={this.state.errorsModal}
          fields={TableMapService.getTableMapByGroup(this.globalService.currentUser.userGroup)[this.state.currentSection.sectionChild]}
          model={this.state.creationModel} isValid={this.handleCreateIsValid.bind(this)}
          apiReducer={this.props.apiReducer} currentUser={this.globalService.currentUser} onSearchChange={this.handleOnSearchChange.bind(this)}
          onSubmit={this.onAddItem.bind(this)} onCancel={this.toggleModal.bind(this, 'modalAdd')} creation={true} />
      );
    }
    return null;
  }

  renderDeleteModal(t) {
    return (
      <UiModal title={t('Common.delete_items')} okLabel={t('Common.yes')} koLabel={t('Common.no')}
        isOpen={this.state.modalDelete} errorsModal={this.state.errorsModal}
        onSubmit={this.onDeleteItem.bind(this)} onCancel={this.toggleModal.bind(this, 'modalDelete')}>
        <p>{t('Common.are_you_sure')}</p>
      </UiModal>
    );
  }

  render() {
    const dataModelValid = (this.globalService.currentUser && (this.globalService.currentUser.userId !== 0) && this.state.currentSection && this.props.apiReducer && this.props.apiReducer[this.state.currentSection.sectionId] && this.props.apiReducer[this.state.currentSection.sectionId].status === 'VALID');
    return (
      <I18n ns="translations">
        {t => (
          <div className="animated">
            {this.renderToast()}
            {this.state.viewable && (
              <div className="Home-content">
                
                {dataModelValid && this.renderLegend(t)}
                {dataModelValid && (
                  <UiFlatTable label={this.state.currentSection.sectionName} selectable={this.state.selectable}
                    currentUser={this.globalService.currentUser}
                    model={this.props.apiReducer[this.state.currentSection.sectionId].data}
                    showFooter={true} notClickable={this.state.notClickable}
                    selectedItems={this.state.selectedItems}
                    links={this.props.apiReducer[this.state.currentSection.sectionId].links}
                    page={this.state.basicFilter.page} paginate={this.state.basicFilter.paginate}
                    total={this.props.apiReducer[this.state.currentSection.sectionId].total}
                    fields={TableMapService.getTableMapByGroup(this.globalService.currentUser.userGroup)[this.state.currentSection.sectionChild]} apiReducer={this.props.apiReducer}
                    sort={this.state.basicFilter.sort} order={this.state.basicFilter.order} onPaginateChange={this.handlePaginate.bind(this)}
                    onSortChange={this.handleSort.bind(this)} onPageChange={this.handlePage.bind(this)} onOpenDetail={this.openDetail.bind(this)} onOpenDetailNewTab={this.onOpenDetailNewTab.bind(this)}
                    insertable={this.state.insertable} editable={this.state.editable} deletable={this.state.deletable} taggable={this.taggable}
                    onSelectChange={this.handleOnSelectChange.bind(this)} onTableButtonClick={this.onTableButtonClick.bind(this)} itemsAreSelectable={this.state.itemsAreSelectable} >
                      <CForm>
                        {/* {dataModelValid && (process.env.API_FULL_TEXT_SEARCH_KEY) && (
                          <CRow>
                            <UiSearch type="text" name="elastic_query" label="Table.query" value={this.state.basicFilter.elastic_query} onChange={this.handleSearch.bind(this)} />
                          </CRow>
                        )} */}
                        {dataModelValid && (process.env.API_SEARCH_TYPE === 'basic') && (
                          <UiBasicGridFilters currentSectionId={this.state.currentSection.sectionId} apiReducer={this.props.apiReducer} currentUser={this.globalService.currentUser}
                            disabled={!!this.state.basicFilter && !!this.state.basicFilter.elastic_query && this.state.basicFilter.elastic_query.length > 0}
                            fields={TableMapService.getTableMapByGroup(this.globalService.currentUser.userGroup)[this.state.currentSection.sectionChild]}
                            onSearchChange={this.handleOnSearchChange.bind(this)} onChange={this.handleAdvancedSearch.bind(this)} />
                        )}
                        {dataModelValid && (process.env.API_SEARCH_TYPE === 'advanced') && (
                          <UiAdvancedGridFilters currentSectionId={this.state.currentSection.sectionId} apiReducer={this.props.apiReducer}
                            disabled={!!this.state.basicFilter && !!this.state.basicFilter.elastic_query && this.state.basicFilter.elastic_query.length > 0}
                            fields={TableMapService.getTableMapByGroup(this.globalService.currentUser.userGroup)[this.state.currentSection.sectionChild]}
                            onSearchChange={this.handleOnSearchChange.bind(this)} onChange={this.handleAdvancedSearch.bind(this)} />
                        )}
                      </CForm>
                    </UiFlatTable>
                )}
                {!dataModelValid && this.renderSpinner()}
                {!!this.state.currentSection && !!this.props.apiReducer && this.renderCreateModal(t)}
                {this.renderDeleteModal(t)}
                {this.renderExportModal(t)}
                {this.renderImportModal(t)}
              </div>
            )}
          </div>
        )}
      </I18n>
    );
  }
}


// this.props.globalServiceReducer.currentSection.sectionId
// da inserire nella UI FLAT TABLE 