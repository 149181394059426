// GLOBAL
import React, { Component } from 'react';
import { CHeaderToggler, CCollapse, CSmartPagination, CContainer, CNavbar, CNavbarBrand, CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody, CTableDataCell, CCard, CCardBody, CRow, CCol, CCardTitle, CCardHeader } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { I18n } from 'react-i18next';
import i18n from 'i18next';

import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';

//SERVICES
import ConstantsService from '../../services/constantsService';

// UTILS
import TablesUtils from '../../utils/tablesUtils';

// COMPONENTS
import UiButton from './UiButton';
import UiCheckbox from './UiCheckbox';
import UiSelectClassic from './UiSelectClassic';

const propTypes = {
  label: PropTypes.string,
  apiReducer: PropTypes.object,
  deletable: PropTypes.bool,
  editable: PropTypes.bool,
  watchable: PropTypes.bool,
  fields: PropTypes.arrayOf(PropTypes.object),
  insertable: PropTypes.bool,
  links: PropTypes.object, // {next: string, last:string, prev:string, first:string}
  model: PropTypes.arrayOf(PropTypes.object),
  onOpenDetail: PropTypes.func,
  onPageChange: PropTypes.func,
  onSelectChange: PropTypes.func,
  onSortChange: PropTypes.func,
  onTableButtonClick: PropTypes.func,
  order: PropTypes.string,
  page: PropTypes.number,
  paginate: PropTypes.number,
  selectable: PropTypes.bool,
  selectedItems: PropTypes.object,
  sort: PropTypes.string,
  taggable: PropTypes.bool,
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  alreadyTranslated: PropTypes.bool,
  disabledRows: PropTypes.array,
  itemsAreSelectable: PropTypes.bool
};

class UiFlatTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
      model: [],
      columns: [],
      selectAll: false,
      selectedItems: {},
      preventNav: false,
      windowSize: window.innerWidth
    };

    this.preventFlag = false;
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize.bind(this));
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize.bind(this));
  }

  handleResize() {
    this.setState({
      windowSize: window.innerWidth
    })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const state = cloneDeep(prevState);
    if (!isEqual(state.model, nextProps.model) || !isEqual(state.selectedItems, nextProps.selectedItems)) {
      if (!isEqual(state.model, nextProps.model)) {
        state.model = cloneDeep(nextProps.model);
        state.selectedItems = {};
        if (nextProps.onSelectChange) nextProps.onSelectChange({});
      } else {
        state.selectedItems = (nextProps.selectedItems) ? nextProps.selectedItems : {};
      }
      state.selectAll = false;
      state.columns = [];
      if (nextProps.fields && nextProps.fields.length > 0) {
        const columns = [];
        nextProps.fields.forEach(f => {
          const field = f;
          if (field.grid > 0) {
            // Se è presente la chiave policy e l'utente non ha la policy, non vedrà il seguente campo
            if (field.policy && !nextProps?.currentUser?.policies.includes(field.policy)) return;
            columns.push(field);
          }
        });
        state.columns = columns.sort((a, b) => ((a.grid > b.grid) ? 1 : ((a.grid < b.grid) ? -1
          : ((a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0)
        )));
      }
      if (nextProps.model && nextProps.model.length > 0) {
        let enabled = nextProps.model;
        if (nextProps.disabledRows && nextProps.disabledRows.length > 0) {
          enabled = nextProps.model.filter((obj) => nextProps.disabledRows.indexOf(obj[ConstantsService.defaultDBIdentifier]) < 0);
        }
        enabled.forEach(rowData => {
          state.selectedItems[rowData[ConstantsService.defaultDBIdentifier]] = false;
        });
      }
    }
    return state;
  }

  onOpenDetail(id) {
    //do nothing
  }

  preventNavigation() {
    this.preventFlag = true;
  }

  goToDetailPage(rowData, event) {
    if (this.props.notClickable) return;

    if (event.ctrlKey && this.props.onOpenDetailNewTab) {
      this.props.onOpenDetailNewTab(rowData[ConstantsService.defaultDBIdentifier], this.preventFlag);
      this.preventFlag = false;
      return;
    }
    if (this.props.onOpenDetail) {
      this.props.onOpenDetail(rowData[ConstantsService.defaultDBIdentifier], this.preventFlag);
    }
    this.preventFlag = false;
  }

  renderHeader(t) {
    if (this.state.columns && this.state.columns.length > 0) {
      const labels = this.state.columns.map((key, k) => {
        let sortOrder = null;
        if (!!this.props.sort && (this.props.sort === key.like_name || this.props.sort === key.name)) {
          const sortDirection = this.props.order === 'ASC' ? 'cis-caret-top' : 'cis-caret-bottom';
          sortOrder = <CIcon icon={sortDirection} className="UiFlatTable_sortArrow" />;
        }
        if (key.type === 'reserved' || key.type === 'multicolor') {
          return null;
        }
        if (key.addYear) {
          return (<CTableHeaderCell className={this.props.sort && `UiFlatTable_th${key.sortable ? ' sortable' : ''}`} scope="col" key={`flat-table-th-${this.props.name}-${k}`} title={t(key.label)} onClick={this.handleSort.bind(this, key)}>{t(key.label) + ' ' + key.year}{sortOrder}</CTableHeaderCell>);
        } else {
          return (<CTableHeaderCell className={this.props.sort && `UiFlatTable_th${key.sortable ? ' sortable' : ''}`} scope="col" key={`flat-table-th-${this.props.name}-${k}`} title={t(key.label)} onClick={this.handleSort.bind(this, key)}>{t(key.label)}{sortOrder}</CTableHeaderCell>);
        }
      });
      return labels;
    }
    return null;
  }

  renderBody() {
    if (this.state.columns && this.state.columns.length > 0 && this.props.model && this.props.model.length > 0) {
      return this.props.model.map((rowData, kk) => {
        const multicolorType = this.state.columns.find((obj) => obj.type === 'multicolor');
        const reservedType = this.state.columns.find((obj) => obj.type === 'reserved');
        let color = undefined;
        if (multicolorType && rowData[multicolorType.name]) {
          color = ConstantsService.statusColors[rowData[multicolorType.name]];
          if (!color && reservedType && rowData[reservedType.name]) {
            color = ConstantsService.statusColors.reserved;
          }
        }

        return (
          <CTableRow key={`flat-table-tr-${this.props.name}-${kk}`} className={!this.props.notClickable ? `clickableRow${(color) ? ' bg-' + color : ''}` : ''} >
            <CTableDataCell className='checkbox-row'>
              {this.props.selectable && (
                <UiCheckbox name={rowData[ConstantsService.defaultDBIdentifier]}
                  disabled={this.props.disabledRows && this.props.disabledRows.indexOf(rowData[ConstantsService.defaultDBIdentifier]) >= 0}
                  value={!!this.state.selectedItems[rowData[ConstantsService.defaultDBIdentifier]]}
                  onChange={this.selectCheckbox.bind(this)} />
              )}
            </CTableDataCell>
              {TablesUtils.renderRowByType(this.state.columns, rowData, this.runningCheck, this.goToDetailPage.bind(this, rowData))}
          </CTableRow>
        );
      });
    }
    return null;
  }

  runningCheck(data) {
    const now = (new Date()).getTime();
    const start = (data.start_date) ? (new Date(data.start_date)).getTime() : 0;
    const end = (data.end_date) ? (new Date(data.end_date)).getTime() : 0;

    if (start === end) {
      return '-';
    } else if (start > now) {
      return i18n.t('SelectValues.waiting');
    } else if (start < now && end > now) {
      return i18n.t('SelectValues.running');
    }
    return i18n.t('SelectValues.finished');
  }

  handleSort(event) {
    if (!event.sortable) {
      return;
    }
    let sortProperty = this.props.sort;
    let sortDirection = this.props.order;
    if (sortProperty === event.like_name || sortProperty === event.name) {
      sortDirection = (sortDirection === 'ASC') ? 'DESC' : 'ASC';
    } else {
      sortProperty = (event.like_name) ? event.like_name : event.name;
      sortDirection = 'ASC';
    }

    if (this.props.onSortChange) this.props.onSortChange(sortProperty, sortDirection);
  }

  handlePageChange(link) {
    if (this.props.onPageChange && link > 0) this.props.onPageChange(link);
  }

  setPaginateValue(quantity) {
    const paginate = parseInt(quantity.target.value);
    if (this.props.onPaginateChange) this.props.onPaginateChange(paginate);
  }

  toggleMenu() {
    this.setState({ openMenu: !this.state.openMenu });
  }

  selectCheckbox(event) {
    if (this.props?.itemsAreSelectable) {
      const selectedItems = cloneDeep(this.state.selectedItems);
      if (event.target.name === 'selectAll') {
        for (const prop in selectedItems) {
          selectedItems[prop] = event.target.value;
        }
        this.setState({ [event.target.name]: event.target.value, selectedItems });
      } else {
        selectedItems[event.target.name] = event.target.value;
        this.setState({ selectedItems });
      }

      if (this.props.onSelectChange) {
        this.props.onSelectChange(selectedItems);
      }
    }
  }

  renderPagination() {
    if (!this.props.links || !this.props.total) return null;
    return (
      <CSmartPagination className="UiFlatTable_pagination"
        pages={Math.ceil(this.props.total / this.props.paginate)} activePage={this.props.page}
        onActivePageChange={(i) => this.handlePageChange(i)}
        // firstButton={i18n.t('Common.first')} 
        // lastButton={i18n.t('Common.last')}
        // previousButton={<>&laquo;</>} 
        // nextButton={<>&raquo;</>} 
        size={this.state.windowSize < 450 ? 'sm' : 'sm'}
      />
    );
  }

  renderTotalItems(t) {
    if (!this.props.model || !this.props.total) return null;
    const startIndex = (this.props.model.length > 0) ? (this.props.page - 1) * this.props.paginate + 1 : 0;
    const endIndex = (this.props.model.length > 0 && startIndex > 0) ? startIndex - 1 + this.props.model.length : 0;
    return (
      <div className="UiFlatTable-footer__total__number">
        {`${t('Common.results')} ${startIndex}`}
        {endIndex > startIndex ? ` - ${endIndex} ` : ' '}
        {`${t('Common.of')} ${this.props.total}`}
      </div>
    );
  }

  render() {
    return (
      <I18n ns="translations">
        {t => (
          <CCard className="mb-4">
            <CCardHeader>
              <CRow>
                <CCol sm="12" md="auto">
                  <CCardTitle className='fs-4 fw-semibold'>{this.props.alreadyTranslated ? this.props.label : t(this.props.label)}</CCardTitle>
                </CCol>
                {(this.props.insertable || this.props.editable || this.props.deletable) && (
                  <CCol sm="12" md="auto" className="ms-auto" visible={this.state.openMenu}>
                    <CRow className='UiFlatTable__actions'>
                      {this.props.insertable && (
                        <CCol className="ms-auto nav-item">
                          <UiButton icon="cis-plus" label={t('Common.add')} onClick={this.props.onTableButtonClick.bind(this, 'Add')} />
                        </CCol>
                      )}
                      {(this.props.editable || this.props.watchable) && (
                        <CCol className="ms-auto nav-item">
                          <UiButton icon={this.props.editable ? 'cis-pencil' : 'cis-search'}
                            label={this.props.editable ? t('Common.edit') : t('Common.see')}
                            disabled={Object.values(this.state.selectedItems).filter(obj => obj === true).length !== 1}
                            onClick={this.props.onTableButtonClick.bind(this, 'Edit', this.state.selectedItems)} />
                        </CCol>
                      )}
                      {this.props.deletable && (
                        <CCol className="ms-auto nav-item">
                          <UiButton icon="cis-trash" label={t('Common.delete')}
                            disabled={Object.values(this.state.selectedItems).filter(obj => obj === true).length <= 0}
                            onClick={this.props.onTableButtonClick.bind(this, 'Delete', this.state.selectedItems)} />
                        </CCol>
                      )}
                      {this.props.taggable && (
                        <CCol className="ms-auto nav-item">
                          <UiButton icon="cis-tags" label={t('Common.assign_tag')}
                            disabled={Object.values(this.state.selectedItems).filter(obj => obj === true).length <= 0}
                            onClick={this.props.onTableButtonClick.bind(this, 'Tag', this.state.selectedItems)} />
                        </CCol>
                      )}
                    </CRow>
                  </CCol>
                )}
              </CRow>
            </CCardHeader>
            <CCardBody>
              {
                this.props.children &&
                <CRow className='table-filters mb-2'>
                  <CCol>
                    { this.props.children }
                  </CCol>
                </CRow>
              }
              <div className="table-wrapper">
                <CTable responsive hover>
                  <CTableHead>
                    <CTableRow>
                      {this.props.selectable && this.state.model && this.state.model.length > 0 && (
                        <CTableHeaderCell scope="col" className="select-all">
                          {/* <div className='badge bg-primary'>Seleziona tutti</div> */}
                          <UiCheckbox className="checkbox-table" name="selectAll" value={this.state.selectAll} onChange={this.selectCheckbox.bind(this)} />
                        </CTableHeaderCell>
                      )}
                      {this.renderHeader(t)}
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {this.renderBody()}
                  </CTableBody>
                </CTable>
                {
                  this.props.showFooter && (
                    <div className="flex-table-footer">
                      {this.renderTotalItems(t)}
                      <div className='pages-controller'>
                        <UiSelectClassic inline nosort alreadyTranslated className="UiFlatTable-footer__paginate__items-selector"
                          name="paginate"
                          label="Common.items_per_page" value={`${this.props.paginate}`} values={['10', '25', '50', '100']} onChange={this.setPaginateValue.bind(this)}
                        />
                        {this.renderPagination()}
                      </div>
                    </div>
                  )
                }
              </div>
            </CCardBody>
          </CCard>
        )}
      </I18n>
    );
  }
}

UiFlatTable.propTypes = propTypes;
export default UiFlatTable;
