import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { CContainer, CFooter, CHeader, CHeaderToggler, CHeaderDivider } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import i18n from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

// STORE
import ApplicationStore from '../../redux/store';

// UTILS
import withRouter from '../../utils/withNavigation';

// SERVICES
import GlobalService from '../../services/globalService';
import ConstantsService from '../../services/constantsService';

import ApiCallsQueueService from '../../services/apiCallsQueueService';
import JWTService from '../../services/jwtService';
import SiteMapService from '../../services/siteMapService';
import UsersService from '../../services/usersService';

// COMPONENT
import { mapStateToProps } from './AbstractComponent';

import DefaultAside from '../ui/DefaultLayout/DefaultAside';
import DefaultFooter from '../ui/DefaultLayout/DefaultFooter';
import DefaultHeader from '../ui/DefaultLayout/DefaultHeader';

import UiBreadcrumb from '../ui/UiBreadcrumb';
import UiSidebarMenu from '../ui/UiSidebarMenu';
import BlockerComponent from '../../utils/BlockerComponent';

/**
 * Layout base di tutte le viste autenticate della dashboard.
 * @category Components
 * @subcategory views
 * @extends React.Component
 * @param {{ location: { pathname: string }, history: string[] }} props - Le props del componente React.
*/
class DefaultLayout extends Component {
  constructor(props) {
    super(props);

    this.globalService = GlobalService.getInstance(ApplicationStore, localStorage, ConstantsService.defaultLanguage, SiteMapService.getSiteMap());
    this.oauthService = JWTService.getInstance(ApplicationStore, process.env.LOGIN_PATH, process.env.CLIENT_ID, process.env.CLIENT_SECRET, localStorage, process.env.BASENAME);
    this.apiCallsQueueService = ApiCallsQueueService.getInstance();

    const user = this.globalService.currentUser;
    if (props.location.pathname !== '/login') {
      if (!user || user.accessToken === null) {
        props.navigate('login');
      }
    }

    this.usersService = UsersService.getInstance(ApplicationStore);

    this.state = {
      currentLanguage: this.globalService.currentLanguage,
      currentUser: user,
      currentBreakpoint: undefined,
      queue: (this.globalService.queue) ? this.globalService.queue : [],
      isBlocking: false,
      showSidebar: true,
      showAside: false,
      hasAside: true,
      menuUnfoldable: false,
    };

    this.buildStateFromUrl(props.location);
    this.waitingUser = false;
    this.waitingQueue = false;

    //?used for brrakpoint in reducer
    this.timeoutid = null;
    window.addEventListener('resize', this.checkBreakpoint.bind(this));

  }

  componentDidMount() {
    //TODO: se ricarico la pagina smette di funzionare
    window.addEventListener('beforeunload', this.preventClosePage.bind(this));
  }

  setBlockingState(isBlocking) {
    this.setState({ isBlocking });
  }

  preventClosePage(event) {
    if (this.state.isBlocking) {
      if (!event) event = window.event;
      //event.cancelBubble is supported by IE - this will kill the bubbling process.
      event.cancelBubble = true;
      event.preventDefault();
      event.returnValue = '\o/'; //This is no longer displayed
      return event.returnValue;
    }
  }

  checkBreakpoint() {
    clearTimeout(this.timeoutId)
    this.timeoutId = setTimeout(() => {
      this.globalService.currentBreakpoint = window.innerWidth;
    }, 300);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.preventClosePage.bind(this));
    window.removeEventListener('resize', this.checkBreakpoint.bind(this));
    clearTimeout(this.timeoutId);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = cloneDeep(prevState);
    if (nextProps.globalServiceReducer && nextProps.globalServiceReducer.currentUser) {
      newState.currentLanguage = nextProps.globalServiceReducer.currentLanguage;
      newState.currentUser = nextProps.globalServiceReducer.currentUser;
      if (nextProps.globalServiceReducer.queue) {
        newState.queue = nextProps.globalServiceReducer.queue;
      }
    }
    return newState;
  }

  shouldComponentUpdate(nextProps, nextState) {
    // const { currentUser } = this.globalService;
    const currentUser = this.globalService.currentUser;

    if ((!nextProps.globalServiceReducer.currentUser || nextProps.globalServiceReducer.currentUser.userId === 0) && currentUser && currentUser.userId !== 0) {
      this.globalService.resumeUser();
      return false;
    } else if (nextProps.tokenReducer && nextProps.tokenReducer.status === 'NEED_TO_REFRESH') {
      this.oauthService.refreshToken();
      return false;
    } else if (nextProps.tokenReducer && nextProps.tokenReducer.status === 'NOT_AUTHENTICATED') {
      if (this.props.location.pathname !== '/login') {
        this.props.navigate('/login', { replace: true });
      }
      return false;
    } else if (this.waitingUser || this.waitingQueue) {
      return false;
    } else if (!this.waitingUser && nextProps.tokenReducer && (nextProps.tokenReducer.status === 'AUTHENTICATED' || !nextProps.tokenReducer.status) && currentUser) {
      if (currentUser.userId === 0) {
        function okCallback(res) {
          this.waitingUser = false;
          if (res && res.data) {
            let user = res.data;
            if (ConstantsService.defaultDBIdentifier !== 'id') {
              user.id = user[ConstantsService.defaultDBIdentifier];
            }
            this.globalService.currentUser = user;
            document.documentElement.style.setProperty('--primary', res.data.primary_color)
            document.documentElement.style.setProperty('--text-primary', res.data.font_color);
          }
        }

        function koCallback() {
          this.waitingUser = false;
          this.oauthService.logout();
        }

        this.waitingUser = true;
        this.usersService.getUserMe(this.globalService.currentLanguage, currentUser, okCallback.bind(this), koCallback.bind(this));
        return false;
      }
      while (this.apiCallsQueueService.queueHasPendingCall()) {
        const apiCall = this.apiCallsQueueService.removeFirstFromQueueApiCall();
        switch (apiCall.method) {
          case 'get':
            apiCall.originalService.get(apiCall.moduleName, apiCall.path, apiCall.lang, currentUser, apiCall.obj, apiCall.okCallBack, apiCall.koCallBack, apiCall.firstError, true);
            break;
          case 'post':
            apiCall.originalService.post(apiCall.moduleName, apiCall.path, apiCall.lang, currentUser, apiCall.obj, apiCall.okCallBack, apiCall.koCallBack, true);
            break;
          case 'postMultipartArray':
            apiCall.originalService.postMultipartArray(apiCall.moduleName, apiCall.path, apiCall.lang, currentUser, apiCall.obj, apiCall.fileContainerName, apiCall.okCallBack, apiCall.koCallBack, apiCall.progressCallback, true);
            break;
          case 'postMultipart':
            apiCall.originalService.postMultipart(apiCall.moduleName, apiCall.path, apiCall.lang, currentUser, apiCall.obj, apiCall.singlefileContainerName, apiCall.okCallBack, apiCall.koCallBack, apiCall.progressCallback, true);
            break;
          case 'put':
            apiCall.originalService.put(apiCall.moduleName, apiCall.path, apiCall.lang, currentUser, apiCall.obj, apiCall.okCallBack, apiCall.koCallBack, true);
            break;
          case 'delete':
            apiCall.originalService.delete(apiCall.moduleName, apiCall.path, apiCall.lang, currentUser, apiCall.okCallBack, apiCall.koCallBack, true);
            break;
        }
      }
    } else if (!nextState.queue && nextProps.tokenReducer && nextProps.tokenReducer.status === 'AUTHENTICATED' && currentUser && currentUser.userId !== 0) {
      this.waitingQueue = true;
      this.populateQueue(currentUser);
      return false;
    }

    if (this.props.location !== nextProps.location) {
      this.buildStateFromUrl(nextProps.location);
      return false;
    }

    return (!!nextProps.globalServiceReducer && nextProps.globalServiceReducer.status === 'SESSION_READY' && !!nextProps.globalServiceReducer.currentUser);
  }

  populateQueue(user) {
    function queueCallback(res) {
      if (res && res.data) {
        const queue = [];
        this.waitingQueue = false;
        localStorage.setItem('queueDate', JSON.stringify(new Date().getTime()));
        if (res.data.length > 0) {
          res.data.map(item => {
            let title = i18n.t(`Section.${item.type}`);
            let type = item.type;
            let progress = item.error_message ? 0 : 100;
            const doc = {
              id: item[ConstantsService.defaultDBIdentifier],
              job_type: item.job_type,
              title,
              subtitle: moment(item.created_at).format('DD/MM/YYYY HH:mm'),
              progress,
              error_message: item.error_message,
              type,
              url: item.file_path,
            };
            queue.push(doc);
          });
        }

        this.globalService.queue = queue;
      } else {
        this.globalService.queue = [];
      }
    }

    //this.usersService.getQueue(this.globalService.currentLanguage, user, queueCallback.bind(this), queueCallback.bind(this));
    this.waitingQueue = false;
    this.globalService.queue = [];
  }

  changeLanguage(lang) {
    this.globalService.changeLanguage(lang);
    i18n.changeLanguage(lang);
  }

  buildStateFromUrl(location) {
    const urlElements = location.pathname.split('/');
    if (urlElements.length > 1) {
      let parentName = urlElements[1].length > 0 ? urlElements[1] : 'home';
      let parentId = urlElements[2];

      if (parentName !== undefined && parentName.indexOf('?') >= 0) {
        parentName = parentName.split('?')[0];
      }

      if (parentId !== undefined && parentId.indexOf('?') >= 0) {
        parentId = parentId.split('?')[0];
      }

      let childName;

      if (urlElements.length > 3) {
        childName = urlElements[3];

        if (childName !== undefined && childName.indexOf('?') >= 0) {
          childName = childName.split('?')[0];
        }
      }

      this.globalService.changeSection(parentName, childName, parentId);
    }
  }

  onMenuAction(action) {
    if (action === 'export') {
      this.globalService.invokeExport();
    } else if (action === 'import') {
      this.globalService.invokeImport();
    }
  }

  toggleSidebar(val) {
    if (val) {
      const newEvent = new CustomEvent("sidebarIsOpen");
      document.dispatchEvent(newEvent);
    }
    this.setState({ showSidebar: val, menuUnfoldable: false });
  }

  toggleUnfoldable() {
    this.setState({ menuUnfoldable: !this.state.menuUnfoldable });
  }

  toggleAside() {
    this.setState({ showAside: !this.state.showAside });
  }

  render() {
    let navigation = [];
    let routes = [];

    /**
     * if user is logged
     * gets the routes and the navigation items according to user policy
     */
    if (this.state.currentUser && this.state.currentUser.userId !== 0) {
      navigation = this.globalService.getNavItems(this.state.currentUser, false);
      routes = this.globalService.getMainRoutes(this.state.currentUser);
      if (this.state.currentUser.primaryColor) {
        document.documentElement.style.setProperty('--primary', this.state.currentUser.primaryColor);
      }
      if (this.state.currentUser.fontColor) {
        document.documentElement.style.setProperty('--text-primary', this.state.currentUser.fontColor);
      }
    }

    return (
      <>
        {this.state.currentUser && this.state.currentUser.userId !== 0 && (
          <>
            {/*<Prompt when={this.state.isBlocking} message={location => i18n.t('Common.changes_not_saved')} />*/}
            <BlockerComponent isDirty={this.state.isBlocking} message={i18n.t('Common.changes_not_saved')} />
            <UiSidebarMenu {...this.props}  toggleSidebar={this.toggleSidebar.bind(this)}
              unfoldable={this.state.menuUnfoldable}
              navConfig={navigation} currentUser={this.state.currentUser} onAction={this.onMenuAction.bind(this)} routes={routes} logo={this.state.currentUser?.tenantLogo}
              showSidebar={this.state.showSidebar} toggleUnfoldable={() => this.toggleUnfoldable()}
            />
            <div className="wrapper bg-light dark:bg-transparent">
              <CHeader position="sticky">
                <CContainer fluid className='main-header'>
                  <DefaultHeader isBlocking={this.state.isBlocking} currentUser={this.state.currentUser}
                    currentLanguage={this.state.currentLanguage} changeLanguage={this.changeLanguage.bind(this)}
                    hasAside={this.state.hasAside} showSidebar={this.state.showSidebar}
                    unfoldable={this.state.menuUnfoldable} unfoldableToggler toggleUnfoldable={() => this.toggleUnfoldable()}
                    toggleAside={this.toggleAside.bind(this)} toggleSidebar={this.toggleSidebar.bind(this)} logo={this.state.currentUser?.tenantLogo} />
                </CContainer>
              </CHeader>
              <div className="body">
                <Routes>
                  {routes.map((route, idx) =>
                    route.component ? (
                      <Route key={idx} path={route.path} element={<route.component onSaveButtonEnabled={this.setBlockingState.bind(this)} />} />
                    ) : null
                  )}
                </Routes>
              </div>
            </div>
            {this.state.hasAside && (
              <DefaultAside queue={this.state.queue} show={this.state.showAside} toggleAside={this.toggleAside.bind(this)} />
            )}
          </>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps)(withRouter(DefaultLayout));
