import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CFormInput, CFormLabel, CTooltip } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { toast } from 'react-toastify';
import { I18n } from 'react-i18next';
import i18n from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

// STORE
import ApplicationStore from '../../redux/store';

// SERVICES
import SiteMapService from '../../services/siteMapService';
import TableMapService from '../../services/tableMapService';

// COMPONENTS
import UiLoading from './UiLoading';
import UiCreationsModal from './UiCreationsModal';

//TYPES
import { userType } from '../../types';

const propTypes = {
  route: PropTypes.string,
  fieldReturned: PropTypes.string,
  fieldDisplayed: PropTypes.string,
  currentUser: userType,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  reducer: PropTypes.func,
  values: PropTypes.array,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  title: PropTypes.string,
  onFocus: PropTypes.func,
  apiReducer: PropTypes.object,
  tooltipMsg: PropTypes.string,
  placeholder: PropTypes.string,
  onValueChanged: PropTypes.func,
  onSearchChange: PropTypes.func,
  alreadyTranslated: PropTypes.bool,
  onCreateSearchChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(['tel,', 'text', 'email', 'password', 'number', 'currency']),
};

/**
 * Componente select. Le props currentUser, apiReducer e onSearchChange servono solamente se è abilitata la creazione di nuovi componenti on the fly, ossia se è settata la proprietà route, in caso contrario vengono ignorati.
 * N.B: se route viene settata ricordarsi di richiamare populateReducers nella onChange della vista che la usa altrimenti il nuovo valore salvato non sarà in elenco.
 * @category Components
 * @subcategory ui
*/
class UiSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      creationModel: null,
      touched: false,
      currentSuggestion: props.required ? 0 : -1,
    };

    this.siteMap = SiteMapService.getSiteMap();
    this.crudPolicies = Object.values(this.siteMap).filter(obj => obj.sectionChild !== undefined && obj.sectionId !== undefined).map(c => c.sectionId);
    this.routePolicies = Object.values(this.siteMap).filter(obj => obj.sectionChild === undefined && obj.sectionId !== undefined).map(r => r.sectionId);
    this.settingsPolicies = (this.siteMap.settingsSection) ? this.siteMap.settingsSection.menuRoutePolicies : [];
    this.lastSearch = null;
    this.lastValue = undefined;

    this.UiAutocompleteInput = React.createRef();

    window.addEventListener('scroll', this.setAutocompleteOptionsPosition.bind(this));
    window.addEventListener('mousedown', this.handleCloseOnMouseOut.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.setAutocompleteOptionsPosition.bind(this));
    window.removeEventListener('mousedown', this.handleCloseOnMouseOut.bind(this));
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = cloneDeep(prevState);
    newState.forced = false;
    const oldVal = (prevState.currentValue && (prevState.currentValue.length > 0 || parseInt(prevState.currentValue) > 0)) ? prevState.currentValue : null;
    const newVal = (nextProps.value && (nextProps.value.length > 0 || parseInt(nextProps.value) > 0)) ? nextProps.value : null;
    //
    let values = null;
    if ((!newState.values && nextProps.values) || !isEqual(nextProps.values, newState.values) || newVal !== oldVal) {
      values = (nextProps.nosort && nextProps.values) ? nextProps.values : (nextProps.values) ? nextProps.values.sort((a, b) => {
        const val = (nextProps.alreadyTranslated)
          ? ((nextProps.fieldDisplayed) ? ((a[nextProps.fieldDisplayed] > b[nextProps.fieldDisplayed]) ? 1 : ((a[nextProps.fieldDisplayed] < b[nextProps.fieldDisplayed]) ? -1 : 0)) : ((a > b) ? 1 : ((a < b) ? -1 : 0)))
          : ((nextProps.fieldDisplayed) ? (
            (i18n.t(a[nextProps.fieldDisplayed]) > i18n.t(b[nextProps.fieldDisplayed])) ? 1
              : ((i18n.t(a[nextProps.fieldDisplayed]) < i18n.t(b[nextProps.fieldDisplayed])) ? -1 : 0))
            : ((String(a).indexOf('Table.') !== 0 && String(a).indexOf('Common.') !== 0) ? ((i18n.t(`SelectValues.${a}`) > i18n.t(`SelectValues.${b}`)) ? 1 : ((i18n.t(`SelectValues.${a}`) < i18n.t(`SelectValues.${b}`)) ? -1 : 0))
              : ((i18n.t(a) > i18n.t(b)) ? 1 : ((i18n.t(a) < i18n.t(b)) ? -1 : 0))));
        return val;
      }) : null;
      newState.values = values;
    }
    //
    if (newVal !== oldVal) {
      const { fieldDisplayed } = nextProps;
      const { fieldReturned } = nextProps;

      let currentValue = null;
      let currentDisplayedValue = '';
      let forced = newState.values !== null;
      if (nextProps.values && newVal) {
        if (nextProps.fieldDisplayed && nextProps.fieldReturned) {
          const objValue = nextProps.values.find(obj => obj[fieldReturned].toString() === newVal.toString());
          if (objValue) {
            currentValue = newVal;
            currentDisplayedValue = objValue[fieldDisplayed] ? objValue[fieldDisplayed] : '';
            forced = false;
          }
        } else {
          currentValue = newVal;
          currentDisplayedValue = i18n.t((String(newVal).indexOf('Table.') !== 0 && String(newVal).indexOf('Common.') !== 0 && !nextProps.alreadyTranslated) ? `SelectValues.${newVal}` : newVal);
          forced = false;
        }
      }

      newState = {
        oldCurrentDisplayedValue: currentDisplayedValue,
        currentDisplayedValue,
        currentValue,
        forced,
        values,
        showIt: false,
        modalAdd: false,
        errorsModal: null,
        currentSuggestion: 0,
        invalid: (nextProps.required ? (nextProps.invalid ? nextProps.invalid : (!newVal)) : null),
        loading: (nextProps.value && (!nextProps.values || nextProps.values.length === 0)) && (nextProps.route || nextProps.reducer),
      };
    }
    //
    return newState;
  }

  shouldComponentUpdate(nextProps, nextState) {
    const prevValue = (this.state.currentValue && this.state.currentValue.length > 0) ? this.state.currentValue : null;
    const nextValue = (nextState.currentValue && nextState.currentValue.length > 0) ? nextState.currentValue : null;
    const prevPValue = (this.props.value && this.props.value.length > 0) ? this.props.value : null;
    const nextPValue = (nextProps.value && nextProps.value.length > 0) ? nextProps.value : null;
    //
    if (prevPValue === this.lastSearch && nextState.forced && this.props.onValueChanged) {
      //
      const event = {
        target: {
          name: this.props.name,
          value: nextState.currentValue,
        },
      };

      if (this.props.onValueChanged) this.props.onValueChanged(event);
      return false;
    }
    return true;
  }

  onToggleModal(target) {
    this.setState({
      [target]: !this.state[target],
      errorsModal: null,
    });
  }

  setAutocompleteOptionsPosition() {
    let positionString = '';
    const elementHeight = 300;
    const bottomPosition = (this.UiAutocompleteOptions) ? this.UiAutocompleteField.getBoundingClientRect().top + elementHeight : 0;
    if (bottomPosition < window.innerHeight) {
      positionString = ' open-bottom';
    } else {
      positionString = ' open-top';
    }
    this.setState({ positionString });
  }

  handleOnChange(value) {
    if (!this.props.disabled) {
      this.setAutocompleteOptionsPosition();

      const event = {
        target: {
          name: this.props.name,
          value,
        },
      };

      if (this.props.onChange) this.props.onChange(event);

      if (this.UiAutocompleteInput && this.UiAutocompleteInput.current) this.UiAutocompleteInput.current.focus();

      let { currentDisplayedValue } = this.state;
      if (value && this.state.values) {
        if (this.props.fieldReturned) {
          const objValue = this.state.values.find((obj) => (obj[this.props.fieldReturned] === value || obj[this.props.fieldReturned] === parseInt(value)));
          if (objValue) {
            currentDisplayedValue = objValue[this.props.fieldDisplayed];
          }
        } else {
          currentDisplayedValue = i18n.t((String(value).indexOf('Table.') !== 0 && String(value).indexOf('Common.') !== 0 && !this.props.alreadyTranslated) ? `SelectValues.${value}` : value);
        }
      }

      this.setState({
        showIt: currentDisplayedValue === this.state.currentDisplayedValue,
        currentDisplayedValue,
        touched: true,
        invalid: !event.target.value && this.props.required,
      },
      // () => this.UiAutocompleteInput && this.UiAutocompleteInput.current && this.UiAutocompleteInput.current.blur()
      );

      if (this.props.onBlur) {
        setTimeout(() => {
          this.setState({ invalid: (!event.target.value && this.props.required) });
          this.props.onBlur(event);
        }, 500);
      }
    }
  }

  handleAutocompleteChange(event) {
    this.setAutocompleteOptionsPosition();

    const currentDisplayedValue = event.target.value ? event.target.value : '';

    if (this.UiAutocompleteList) {
      this.UiAutocompleteList.scrollTop = 0;
    }

    this.setState({
      showIt: true,
      currentDisplayedValue,
      currentSuggestion: this.defaultCurrentSuggestion(),
    });
  }

  setAutocompleteSelection(choice) {
    this.handleOnChange(choice);
  }

  defaultCurrentSuggestion() {
    if (!this.props.required && (this.state.currentValue >= 0 || !this.state.currentValue)) {
      return -1;
    }
    return 0;
  }

  evalOptionsNavigationKeyboard(event) {
    if (!this.props.disabled) {
      if (this.state.showIt) {
        this.handleKeyPress(event);
      } else if (document.activeElement === this.UiAutocompleteInput.current) {
        this.setState({ showIt: true, currentSuggestion: this.defaultCurrentSuggestion() });
      }
    }
  }

  handleKeyPress(event) {
    if (!this.props.disabled) {
      const { key } = event;
      let value = null;
      let maxSuggestionsIndex = 0;

      switch (key) {
        case 'Enter':
          event.preventDefault();
          if (this.state.showIt) {
            if (this.UiAutocompleteOption) {
              value = this.UiAutocompleteOption.getAttribute('value');
            } else {
              const objValue = this.state.values.find(obj => obj[this.props.fieldDisplayed] === event.target.value);
              if (objValue) {
                value = objValue[this.props.fieldReturned];
              }
            }
            this.setAutocompleteSelection(value);
          } else {
            this.setState({ showIt: true });
            if (this.UiAutocompleteInput.current) this.UiAutocompleteInput.current.focus();
          }
          break;

        case 'ArrowDown':
          event.preventDefault();
          if (this.UiAutocompleteOptions) {
            if (!this.props.required) {
              maxSuggestionsIndex = this.UiAutocompleteOptions.childElementCount - 2;
            } else {
              maxSuggestionsIndex = this.UiAutocompleteOptions.childElementCount - 1;
            }
          }
          this.setState((prevState) => ({
            showIt: true,
            currentSuggestion: (!prevState.showIt ? 0 : (this.state.currentSuggestion < maxSuggestionsIndex) ? prevState.currentSuggestion + 1 : prevState.currentSuggestion),
          }),
            () => {
              if (this.UiAutocompleteOption) {
                const div = this.UiAutocompleteList.getBoundingClientRect().bottom;
                const divChild = this.UiAutocompleteOption.getBoundingClientRect().bottom;

                if (div < divChild) {
                  this.UiAutocompleteList.scrollTop += 50;
                }
              }
            });
          break;

        case 'ArrowUp':
          event.preventDefault();
          this.setState((prevState) => ({
            showIt: true,
            currentSuggestion: (!prevState.showIt ? 0 : (this.state.currentSuggestion >= 0) ? this.state.currentSuggestion - 1 : prevState.currentSuggestion),
          }),
            () => {
              if (this.UiAutocompleteOption) {
                const div = this.UiAutocompleteList.getBoundingClientRect().top;
                const divChild = this.UiAutocompleteOption.getBoundingClientRect().top;

                if (div > divChild - 50) {
                  this.UiAutocompleteList.scrollTop -= 50;
                }
              }
            });
          break;

        case 'dropdownClick':
          this.setState((prevState) => ({
            showIt: !prevState.showIt,
            currentSuggestion: this.defaultCurrentSuggestion(),
            invalid: this.props.required ? this.state.touched && (!!(prevState.showIt && !prevState.currentValue)) : null,
          }));
          break;

        case 'Escape':
          this.setState({ showIt: false });
          break;

        default:
          break;
      }
    }
  }

  handleCloseOnMouseOut(event) {
    if (event && event.target && event.target.className && event.target.className.indexOf && event.target.className.indexOf('UiAutocompleteList') === 0 && event.target.className.indexOf('show') > 0) {
      event.preventDefault();
      return;
    }
    if (!this.props.disabled && this.UiAutocompleteField && !this.UiAutocompleteField.contains(event.target)) {
      const newState = cloneDeep(this.state);
      newState.showIt = false;
      newState.currentSuggestion = this.defaultCurrentSuggestion();
      newState.currentDisplayedValue = newState.oldCurrentDisplayedValue;
      newState.invalid = (this.props.required ? (newState.showIt ? (!newState.currentValue) : (!newState.currentValue)) : null);
      if (!this.state.touched && this.state.showIt) newState.touched = true;
      this.setState(newState);
    }
  }

  handleOnMouseDown(event) {
    if (event && event.target && event.target.className && event.target.className.indexOf && event.target.className.indexOf('UiAutocompleteList') === 0 && event.target.className.indexOf('show') > 0) {
      event.preventDefault();
      return;
    }
    if (event && event.target && event.target.className && event.target.className.indexOf && event.target.className.indexOf('UiSelectDropDownIcon') === 0) {
      event.preventDefault();
      if (this.UiAutocompleteInput.current) this.UiAutocompleteInput.current.focus();
      if (!!this.UiAutocompleteList && this.state.showIt === false) {
        this.UiAutocompleteList.scrollTop = 0;
      }
      this.setState(this.state.showIt ? { showIt: false, currentSuggestion: this.defaultCurrentSuggestion() } : { showIt: true });
      return;
    }
    const { target } = event;
    if (this.UiAutocompleteField && this.UiAutocompleteField.contains(target)) {
      if (!target.className.baseVal && target.className.includes('autocomplete-option')) {
        event.preventDefault();
        if (!target.className.includes('empty')) {
          if (target.className.includes('action')) {
            const creationModel = {};
            creationModel[this.props.fieldDisplayed] = this.state.currentDisplayedValue;
            this.setState({ creationModel });
            this.onToggleModal('modalAdd');
          } else {
            this.setAutocompleteSelection(target.getAttribute('value'));
          }
        }
      } else if (target.className.baseVal && target.className.baseVal.includes('UiAutocomplete_toggleDropDown')) {
        const eventKP = { key: 'dropdownClick' };
        this.handleKeyPress(eventKP);
      }
    }
  }

  handleOnBlur(event) {
    event.preventDefault();
    if (this.state.showIt && event && event.target && event.target.className && event.target.className.indexOf && event.target.className.indexOf('UiAutocompleteField') === 0 && event.target.className.indexOf('form-control') > 0) {
      return;
    }
    if (!this.props.disabled) {
      const newState = cloneDeep(this.state);
      newState.showIt = false;
      newState.currentDisplayedValue = newState.oldCurrentDisplayedValue;
      newState.invalid = (this.props.required ? (newState.showIt ? (!newState.currentValue) : (!newState.currentValue)) : null);
      if (!this.state.touched) newState.touched = true;
      this.setState(newState);
      // if (this.props.onBlur) this.props.onBlur(event);
    }
  }

  handleOnFocus(evt) {
    if (!this.props.disabled && this.props.onFocus) this.props.onFocus(evt);
  }

  onAddItem(clearRoute, creationModel) {
    const genericService = this.siteMap[this.siteMap[clearRoute].sectionParent].sectionService.getInstance(ApplicationStore);

    function okCallback(success) {
      this.onToggleModal('modalAdd');
      this.setAutocompleteSelection(success.data[this.props.fieldReturned]);
      toast.success(i18n.t('Common.success'));
    }

    function koCallback(error) {
      let errors = [];
      if (!!error && !!error.data && error.data.errors) {
        errors = error.data.errors;
      } else if (!!error && !!error.data && error.data.message) {
        errors.push(error.data.message);
      } else if (!!error && !!error.message) {
        errors.push(error.message);
      } else {
        errors.push(i18n.t('Common.generic_error'));
      }

      this.setState({ loadingModal: false, errorsModal: errors });
    }

    genericService.addItem(creationModel, '', this.props.currentUser, okCallback.bind(this), koCallback.bind(this));
  }

  renderCreateModal(t, clearRoute) {
    const fields = cloneDeep(TableMapService.getTableMapByGroup(this.props.currentUser.userGroup)[clearRoute]);
    Object.keys(this.state.creationModel).map((key) => {
      if (key !== this.props.fieldDisplayed) {
        fields.find((obj) => obj.name === key).readonly = true;
      }
    });

    return (
      <UiCreationsModal errorsModal={this.state.errorsModal} title={t(`Common.add_${this.props.route}`)}
        model={this.state.creationModel} isOpen={this.state.modalAdd} fields={fields}
        apiReducer={this.props.apiReducer} currentUser={this.props.currentUser}
        onSearchChange={this.props.onCreateSearchChange ? this.props.onCreateSearchChange.bind(this) : this.props.onSearchChange.bind(this)}
        onSubmit={this.onAddItem.bind(this, clearRoute)} onCancel={this.onToggleModal.bind(this, 'modalAdd')} />
    );
  }

  renderOptionList(t, clearRoute) {
    const displayedVal = this.state.currentDisplayedValue ? this.state.currentDisplayedValue : '';
    let optionList = [];

    if (this.state.values && this.state.values.length > 0) {
      optionList = this.state.values.filter(current => {
        if (String(current).indexOf('--') === 0) {
          return true;
        }
        if (!this.props.fieldDisplayed && t((String(current).indexOf('Table.') !== 0 && String(current).indexOf('Common.') !== 0 && !this.props.alreadyTranslated) ? `SelectValues.${current}` : current).toLowerCase().includes(displayedVal.toLowerCase())) {
          return true;
        } else if (current[this.props.fieldDisplayed] && current[this.props.fieldDisplayed].toLowerCase().includes(displayedVal.toLowerCase())) {
          return true;
        }
        return false;
      }).map((current, k) => (
        <li role="presentation" key={`select-${this.props.name}-${k}`} className={`autocomplete-option${this.state.currentSuggestion === k ? ' sugerence' : ''}`}
          ref={(UiAutocompleteOption) => { if (this.state.currentSuggestion === k) this.UiAutocompleteOption = UiAutocompleteOption; }}
          value={(this.props.fieldReturned) ? current[this.props.fieldReturned] : current} >
          {(this.props.fieldDisplayed) ? current[this.props.fieldDisplayed] : t((String(current).indexOf('Table.') !== 0 && String(current).indexOf('Common.') !== 0 && !this.props.alreadyTranslated) ? `SelectValues.${current}` : current)}
        </li>
      ));
    }

    const valueAlreadyIn = (this.state.values && this.state.values.length > 0)
      ? ((this.props.fieldDisplayed)
        ? (this.state.values.find((obj) => obj[this.props.fieldDisplayed] === displayedVal) !== undefined)
        : (this.state.values.indexOf(displayedVal) >= 0))
      : false;

    if (displayedVal && clearRoute && !valueAlreadyIn) {
      optionList.push(
        <li key={`nav-${this.props.name}--1`} role="presentation" action="add" className="autocomplete-option action">
          {i18n.t('SelectValues.add_new_type_suggestion', { type: i18n.t(`Section.${clearRoute}`), suggestion: displayedVal })}
        </li>
      );
    }

    return optionList;
  }

  render() {
    let clearRoute = (this.props.route && this.props.route !== 'filter') ? this.props.route : null;
    if (clearRoute && clearRoute.indexOf('_') > 0) {
      clearRoute = (clearRoute.indexOf('_related') > 0) ? clearRoute.substring(0, clearRoute.indexOf('_related')) : clearRoute;
      clearRoute = clearRoute.replace('_tmp', '');
    }

    return (
      <I18n ns="translations">
        {(t) => (
          <div role="presentation" className={`UiAutocomplete${this.state.invalid && this.state.touched ? ' is-invalid' : ''}`} onMouseDown={this.handleOnMouseDown.bind(this)}>
            {this.props.label ? (
              <CFormLabel htmlFor={this.props.name} className="UiMainLabel">
                {t(this.props.label)} {this.props.required ? '*' : ''}
                {this.props.tooltipMsg && (
                  <span>
                    <CTooltip placement="top" content={t(this.props.tooltipMsg)}>
                      <CIcon icon="cis-info-circle" size="sm" />
                    </CTooltip>
                  </span>
                )}
              </CFormLabel>
            ) : (
              ''
            )}
            {!!this.props.value && !!clearRoute && (
              <small className="text-muted ms-3">
                {this.props.currentUser && this.routePolicies.indexOf(clearRoute) >= 0 && this.props.currentUser.policies.indexOf(`${this.crudPolicies[this.routePolicies.indexOf(clearRoute)]}_view`) >= 0 && (
                  <Link to={`/${clearRoute}/${this.props.value}`}>
                    <CIcon icon="cis-link" />
                  </Link>
                )}
                {this.props.currentUser && this.settingsPolicies.indexOf(clearRoute) >= 0 && this.props.currentUser.policies.indexOf('settings_view') >= 0 && (
                  <Link to={`/${clearRoute}/${this.props.value}`}>
                    <CIcon icon="cis-link" />
                  </Link>
                )}
                {this.props.currentUser && this.routePolicies.indexOf(clearRoute) < 0 && this.settingsPolicies.indexOf(clearRoute) < 0 && this.props.currentUser.policies.indexOf('artwork_settings_view') >= 0 && (
                  <Link to={`/${clearRoute}/${this.props.value}`}>
                    <CIcon icon="cis-link" />
                  </Link>
                )}
              </small>
            )}
            {/* (this.state.values || this.state.currentDisplayedValue) && */}
            {!this.state.loading && (
              <div className="UiAutocompleteGroup" ref={UiAutocompleteField => (this.UiAutocompleteField = UiAutocompleteField)}>
                <CFormInput type={this.props.type} ref={this.UiAutocompleteInput}
                  id={this.props.name} name={this.props.name} title={this.props.title}
                  className={`UiAutocompleteField${(this.state.showIt && this.state.positionString) ? ` ${this.state.positionString}` : ''}${this.state.invalid && this.state.touched ? ' is-invalid' : ''}`}
                  disabled={this.props.disabled} required={this.props.required}
                  value={this.state.currentDisplayedValue ? this.state.currentDisplayedValue : ''}
                  autoComplete="off" placeholder={this.props.disabled ? '' : this.props.placeholder ? t(this.props.placeholder) : `${t('Common.start_typing')}...`}
                  onFocus={this.handleOnFocus.bind(this)} onChange={this.handleAutocompleteChange.bind(this)} onBlur={this.handleOnBlur.bind(this)}
                  onKeyDown={this.handleKeyPress.bind(this)} />

                {!this.props.disabled && (
                  <div role="presentation" className="UiSelectDropDownIcon" onClick={() => { this.setState({ showIt: true }); }}>
                    <span className="UiSelect_toggleDropDown fa py-1">
                      <CIcon icon="cis-chevron-bottom" />
                    </span>
                  </div>
                )}

                {!this.props.disabled && this.state.values && this.state.values.length > 0 && (
                  <div className={`UiAutocompleteList ${this.state.positionString ? this.state.positionString : ''} ${this.state.showIt ? 'show' : ''}`}
                    ref={UiAutocompleteList => (this.UiAutocompleteList = UiAutocompleteList)}>
                    <ul className="autocomplete-list" ref={UiAutocompleteOptions => (this.UiAutocompleteOptions = UiAutocompleteOptions)}>
                      {!this.props.required && (
                        <li ref={(UiAutocompleteOption) => { if (this.state.currentSuggestion === -1) this.UiAutocompleteOption = UiAutocompleteOption; }}
                          value="" role="presentation" className={`autocomplete-option${this.state.currentSuggestion === -1 ? ' sugerence' : ''}`} onClick={this.handleOnChange.bind(this, null)}>
                          &nbsp;
                        </li>
                      )}
                      {this.renderOptionList(t, clearRoute)}
                    </ul>
                  </div>
                )}
                {!!this.state.creationModel && clearRoute && this.renderCreateModal(t, clearRoute)}
              </div>
            )}
            {/*! this.state.values && !this.state.currentDisplayedValue && */}
            {this.state.loading && (
              <div className="UiAutocomplete_loader">
                <UiLoading type="bubbles" height={80} width={40} />
              </div>
            )}
            {this.state.invalid && this.state.touched && (
              <div className="invalid-feedback">{t('Common.field_invalid')}</div>
            )}
          </div>
        )}
      </I18n>
    );
  }
}

UiSelect.propTypes = propTypes;

export default UiSelect;
