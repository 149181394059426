import Oauth2Service from './oauthService';

// REDUCERS
import { tokenNeeded } from '../redux/tokenReducer';
import ObjectsUtils from '../utils/objectsUtils';

export default class JWTService extends Oauth2Service {

  static getInstance(store, baseUrl, client_id, client_secret, storage, basename) {
    if (!JWTService.instance) {
      JWTService.instance = new JWTService(store, baseUrl, client_id, client_secret, storage, basename);
    }
    return JWTService.instance;
  }

  getToken(username, password, okCallback, koCallback) {
    this.clearLocalStorage();

    // let path = '/signin';
    let path = '/login';
    let body = {
      email: username,
      password: password,
    };

    let getKoCallBack = (error) => {
			this.handleTokenError(error);
			if (koCallback) {
				koCallback(error);
			}
		}

    this.store.dispatch(tokenNeeded(username, password));

    return this.axiosInstance.post(path, body)
      .then(this.handleTokenSuccess.bind(this))
      .catch(getKoCallBack.bind(this));
  }

  refreshToken(okCallback, koCallback) {
    const auth = this.localStorage.getItem('auth');
    if (auth) {
      const cookie = JSON.parse(this.localStorage.getItem('auth'));
      this.localStorage.removeItem('auth');

      let path = '/refresh-token';
      let body = {
        refresh_token: cookie.refreshToken,
      };

      body = (process.env.API_RESPONSE_FORMAT === 'camelCase') ? ObjectsUtils.snakeCaseToCamelCase(body) : body;

      let koCallBack = (error) => {
        this.handleTokenError(error);
        if (koCallback) {
          koCallback(error);
        }
      }

      this.store.dispatch(tokenNeeded());

      return this.axiosInstance.post(path, body)
        .then(this.handleTokenSuccess.bind(this))
        .catch(koCallBack.bind(this));
    }
    return null;
  }

  resetPassword(email, okCallback, koCallback) {
    let path = '/reset-password/request';
    let body = {
      email: email,
    };

    return this.axiosInstance.post(path, body)
      .then(okCallback.bind(this))
      .catch(koCallback.bind(this));
  }

  renewPassword(code, password, okCallback, koCallback) {
    let path = '/reset-password/change';
    let body = {
      password: password,
      otp: code,
    };

    return this.axiosInstance.put(path, body)
      .then(okCallback.bind(this))
      .catch(koCallback.bind(this));
  }
}
