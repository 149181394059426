import ConstantsService from './constantsService';
import axios from 'axios';
import BaseService from './baseService';

export default class LambdaService extends BaseService {
  static instance = null;

  static getInstance() {
    if (!LambdaService.instance) {
      LambdaService.instance = new LambdaService();
    }
    return LambdaService.instance;
  }

  constructor(store) {
    const baseUrl = process.env.API_PATH;
    super(store, baseUrl, 'lambdas', 'lambda');
}

  async getFileLambda(filename, accessToken, okCallback, koCallback){
    const response = await this.getPresignedUrl("get", this.itemName, filename, accessToken, okCallback, koCallback);

    if (response.result.error) return;
    const presignedUrl = response.data.url;

    return await axios.get(presignedUrl)
      .then(res => console.log("RES: ", JSON.stringify(res.data)))
      .catch(err => this.manageError(err, 'get', null, this.itemName, endpoint, null, filename, okCallback, koCallback));
  }

  async uploadFileByLambda(filename, value, accessToken, okCallback, koCallback){
    const response = await this.getPresignedUrl("post", this.itemName, filename, accessToken, okCallback, koCallback);

    if (response.result.error) return;
    const presignedUrl = response.data.url;

    return await axios.put(presignedUrl, value)
      .then(res => res)
      .catch(err => this.manageError(err, 'put', null, moduleName, endpoint, null, value, okCallback, koCallback));
  }

  async deleteFile(filename, accessToken, deleteOkCallBack, deleteKoCallBack){
    const endpoint = "presigned/delete?filename=" + filename;

    this.axiosInstance.defaults.headers = {
      "Authorization": "Bearer " + accessToken,
    }
    
    const response = await this.axiosInstance.delete(endpoint)
      .then(res => res.data)
      .catch(err => this.manageError(err, 'delete', null, this.itemName, endpoint, null, filename, deleteOkCallBack, deleteKoCallBack));

    if (response && typeof response ==="string" && response.toLowerCase() === "file deleted successfully") return 200;
  }

  async getPresignedUrl(method, moduleName, filename, accessToken, okCallBack, koCallBack) {
    let endpoint = "";
    
    this.axiosInstance.defaults.headers = {
      "Authorization": "Bearer " + accessToken,
    }
    
    if (method === "get"){
      endpoint = "presigned/get?filename=" + filename;
      return await this.axiosInstance.get(endpoint)
      .then(res => res.data)
      .catch(err => this.manageError(err, 'get', null, moduleName, endpoint, null, filename, okCallBack, koCallBack));
    }

    if (method === "post"){
      endpoint = "presigned/save?filename=" + filename;
      return await this.axiosInstance.post(endpoint)
      .then(res => res.data)
      .catch(err => this.manageError(err, 'post', null, moduleName, endpoint, null, filename, okCallBack, koCallBack));
    }
  }
}