// GLOBAL
import React from 'react';
import { CRow, CCol, CFormLabel } from '@coreui/react-pro';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18next';
import i18n from 'i18next';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

// UTILS
import ComponentsUtils from '../../utils/componentsUtils';

// COMPONENTS
import UiButton from './UiButton';
import UiSelect from './UiSelect';
import UiSelectClassic from './UiSelectClassic';
import { useState } from 'react';
import { useEffect } from 'react';

const propTypes = {
  fields: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  apiReducer: PropTypes.object,
  onSearchChange: PropTypes.func,
  currentSectionId: PropTypes.string,
};

let compatibilityValues

function UiAdvancedGridFilters(props) {

  /**
   * UiAdvancedGridFilters
   * 
   * @category components
   * @subcategory ui
   * @classdesc UiAdvancedGridFilters
   * @extends React.Component
   * 
   * @param {object} props - The react component default properties
  */
  let settedFilters = JSON.parse(localStorage.getItem(`${props.currentSectionId}Filters`));
  const [state, setState] = useState({
    filters: [],
    settedFilters: (settedFilters) ? settedFilters.filter : [],
    newCriteria: 'AND',
    newCompatibility: 'equals',
    allFilters: [],
    sidebarIsOpen: false
  })

  useEffect(() => {
    handleEvent();
    let settedFilters = (props.currentSectionId) ? JSON.parse(localStorage.getItem(`${props.currentSectionId}Filters`)) : null;
    if (!settedFilters) {
      settedFilters = { filter: [] };
    }
    setState((prevState) => {
      const newState = cloneDeep(prevState);
      if (newState.currentSectionId === props.currentSectionId && isEqual(newState.oldPropsFields, props.fields) && isEqual(newState.oldPropsReducer, props.apiReducer) && isEqual(newState.settedFilters, settedFilters.filter)) {
        return newState;
      } else {
        newState.settedFilters = (settedFilters) ? settedFilters.filter : [];
      }
      newState.currentSectionId = props.currentSectionId;
      newState.oldPropsFields = props.fields;
      newState.oldPropsReducer = props.apiReducer;
      newState.filters = [];

      newState.allCriteria = ['AND', 'OR'];
      newState.allCompatibilities = ['equals', 'not_equals', 'like', 'not_like'];
      newState.allFilters = [];

      if (props.fields && props.fields.length > 0) {
        let globalFilters = props.fields.filter((obj) => obj.position > 0);
        globalFilters = globalFilters.sort((a, b) => ((a.position > b.position) ? 1 : ((a.position < b.position) ? -1
          : ((a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0)
        )));
        if (globalFilters.length > 0) {
          globalFilters.forEach((f) => {
            const field = f;
            if (field.reducer && props.apiReducer && props.apiReducer[field.reducer]) {
              field.values = cloneDeep(props.apiReducer[field.reducer].data);
            }
            if (!field.mandatory) {
              const nn = (field.alreadyTranslated) ? i18n.t('SelectValues.null') : 'null';
              const nullValue = (field.fieldReturned) ? {} : nn;
              if (field.fieldReturned) {
                nullValue[field.fieldReturned] = 'null';
                nullValue[field.fieldDisplayed] = i18n.t('SelectValues.null');
              }
              if (field.values) {
                if (field.values.length > 0) {
                  if ((field.fieldReturned && field.values.filter((obj) => obj[field.fieldReturned] === 'null').length === 0)
                    || (!field.fieldReturned && field.values.indexOf(nn) < 0)) field.values.unshift(nullValue);
                } else {
                  field.values = [nullValue];
                }
              }
            }
            newState.filters.push(field);
            // if (Object.keys(newState.settedFilters).indexOf(field.name) < 0) {
            newState.allFilters.push(field.label);
            // }
          });
        }
      }
      return newState;
    })
  }, [props])

  function handleEvent() {
    document.addEventListener("sidebarIsOpen", () => {
      ("event handled")
      setState({
        ...state,
        sidebarIsOpen: true
      })
    })
  }

  function toggleCollapse() {
    setState({ ...state, openCollapse: !state.openCollapse });
  }

  function handleOnNewFilterChange(event) {
    const newState = cloneDeep(state);
    newState[event.target.name] = event.target.value;

    if (event.target.name.indexOf('newCompatibility') === 0 && event.target.value.indexOf('is_null') === 0) {
      newState.newValue = 'null';
    } else if (event.target.name.indexOf('newCompatibility') === 0 && event.target.value.indexOf('is_not_null') === 0) {
      newState.newValue = '!!null';
    } else if (event.target.name.indexOf('newCompatibility') === 0 || event.target.name.indexOf('newFilter') === 0) {
      newState.newValue = undefined;
    }
    setState(newState);
  }

  function handleOnChange(settedIndex, event) {
    const settedFilters = cloneDeep(state.settedFilters);
    if (settedIndex && event.target.name.indexOf('settedFilters') < 0) {
      settedFilters[settedIndex].value = event.target.value;
    } else {
      eval(`${event.target.name} = event.target.value`);
      if (event.target.name.indexOf('compatibility') >= 0) {
        const valuename = event.target.name.replace('compatibility', 'value');
        eval(`${valuename} = null`);
      }
    }
    setState({ ...state, settedFilters: settedFilters });
    if (props.onChange) props.onChange(settedFilters);
  }

  function handleOnNewFilter() {
    const filter = cloneDeep(state.filters.find((obj) => obj.label === state.newFilter));
    const newState = cloneDeep(state);
    const newFilter = {};
    newFilter.key = filter.name;
    newFilter.like_name = filter.like_name;
    newFilter.criteria = newState.newCriteria;
    newFilter.compatibility = newState.newCompatibility;
    newFilter.value = newState.newValue;

    newState.settedFilters.push(newFilter);

    newState.newCriteria = 'AND';
    newState.newCompatibility = 'equals';
    newState.newFilter = null;
    newState.newValue = null;

    setState(newState);
    if (props.onChange) props.onChange(newState.settedFilters);
  }

  function handleOnRemoveFilter(settedIndex) {
    const settedFilters = cloneDeep(state.settedFilters);
    settedFilters.splice(settedIndex, 1);
    setState({ ...state, settedFilters: settedFilters });
    if (props.onChange) props.onChange(settedFilters);
  }

  function clearAllFilters() {
    setState({ ...state, settedFilters: [] });
    if (props.onChange) props.onChange('clear_all');
  }

  function buildCompatibility(filterLabel) {
    let compatibilityValues = state.allCompatibilities;
    if (filterLabel) {
      const filter = state.filters.find((obj) => obj.label === filterLabel);
      if (filter && filter.type === 'input') {
        compatibilityValues = ['equals', 'not_equals', 'is_null', 'is_not_null', 'like', 'not_like'];
      } else if (filter && filter.type === 'select') {
        compatibilityValues = ['equals', 'not_equals'];
      } else if (filter && filter.type === 'checkbox') {
        compatibilityValues = ['equals'];
      } else if (filter && (filter.type === 'input-number' || filter.type === 'date' || filter.type === 'datetime' || filter.type === 'currency')) {
        compatibilityValues = ['equals', 'not_equals', 'greater_than', 'less_than', 'gt_or_eq_than', 'lt_or_eq_than'];
      }
    }
    return compatibilityValues;
  }

  function renderFilterRow(t, settedIndex, index) {
    const currentFilter = state.filters.find((obj) => (obj.name === state.settedFilters[settedIndex].key || obj.like_name === state.settedFilters[settedIndex].key));
    const compatibilityValues = (currentFilter) ? buildCompatibility(currentFilter.label) : state.allCompatibilities;
    const filterValue = renderFilterValue(settedIndex, index, false, state.settedFilters[settedIndex].compatibility);
    if (!filterValue) return null;
    return (
      <CRow key={`grid-filter-${props.name}-${index}`} className="mb-3 grid-filter">
        <CCol sm="3" md="2" lg="2" xl="2">
          <UiSelectClassic name="settedFilters[settedIndex].criteria" label={t('Common.criteria')} disabled={props.disabled}
            value={state.settedFilters[settedIndex].criteria} values={state.allCriteria} nullable={false} alreadyTranslated
            onChange={handleOnChange.bind(this, settedIndex)} onValueChanged={handleOnChange.bind(this, settedIndex)} />
        </CCol>
        <CCol sm="4" md="2" lg="2" xl="2">
          <UiSelectClassic name="settedFilters[settedIndex].compatibility" label={t('Common.compatibility')} disabled={props.disabled}
            value={state.settedFilters[settedIndex].compatibility} values={compatibilityValues} nullable={false}
            onChange={handleOnChange.bind(this, settedIndex)} onValueChanged={handleOnChange.bind(this, settedIndex)} />
        </CCol>
        <CCol sm="5" md="6" lg="6" xl="6">
          {filterValue}
        </CCol>
        <CCol xs="12" sm="4" md="2" lg="2" xl="2" className="offset-0 offset-sm-8 offset-md-0 mt-sm-2 mt-md-0">
          <UiButton className="justify-content-center" classNameContainer="ButtonsContainerEnd ms-0 mt-2 mt-sm-0"
            icon="cis-minus" label={t('Common.remove')} disabled={props.disabled}
            onClick={handleOnRemoveFilter.bind(this, settedIndex)} />
        </CCol>
      </CRow>
    );
  }

  function renderFilterValue(settedIndex, index, isNew, compatibility) {
    let filter = null;
    const model = {};
    if (isNew) {
      filter = cloneDeep(state.filters.find((obj) => obj.label === settedIndex));
      filter.label = 'Common.search';
      filter.name = 'newValue';
      model.newValue = state.newValue;
    } else {
      if (compatibility === 'is_null') {
        model[state.settedFilters[settedIndex].key] = 'null';
      } else if (compatibility === 'is_not_null') {
        model[state.settedFilters[settedIndex].key] = '!!null';
      } else {
        model[state.settedFilters[settedIndex].key] = state.settedFilters[settedIndex].value;
      }
      filter = cloneDeep(state.filters.find((obj) => obj.name === state.settedFilters[settedIndex].key));
    }
    if (!filter) {
      return null;
    }
    if (compatibility === 'is_null' || compatibility === 'is_not_null') {
      if (!isNew) {
        return (<CRow key={`grid-filter-${props.name}-${index}`}><CFormLabel className="UiMainLabel mt-4-5 ml-2">{i18n.t('Table.' + filter.name)}</CFormLabel></CRow>);
      }
      return (<CRow key={`grid-filter-${props.name}-${index}`} />);
    }
    if (compatibility === 'like' || compatibility === 'not_like') {
      filter.type = 'input';
    }
    filter.readonly = props.disabled;

    if (filter.name) {
      return (
        <CRow key={`grid-filter-${props.name}-${index}`}>
          {ComponentsUtils.renderByType(true, props.disabled, false, filter, model, isNew ? handleOnNewFilterChange.bind(this) : handleOnChange.bind(this, settedIndex), props.onSearchChange.bind(this))}
        </CRow>
      );
    }
    return null;
  }

  useEffect(() => {
    compatibilityValues = buildCompatibility(state.newFilter);
  }, [state])

  return (
    <I18n ns="translations">
      {t => (
        <div>
          <CRow>
            <CCol sm="12" md="12" lg="12" xl="12">
              {Object.keys(state.settedFilters).map((key, index) => renderFilterRow(t, key, index))}
              {state.allFilters.length > 0 && (
                <>
                  <CRow className="mb-3">
                    <CCol sm="3" md="3" lg="2" xl="2">
                      <UiSelectClassic name="newCriteria" label={t('Common.criteria')} disabled={props.disabled}
                        value={state.newCriteria} values={state.allCriteria} nullable={false} alreadyTranslated
                        onChange={handleOnNewFilterChange.bind(this)} onValueChanged={handleOnNewFilterChange.bind(this)} />
                    </CCol>
                    <CCol sm="3" md="3" lg="3" xl="3">
                      <UiSelect name="newFilter" label={t('Common.new_filter')} disabled={props.disabled}
                        value={state.newFilter} values={state.allFilters} nullable={false}
                        onChange={handleOnNewFilterChange.bind(this)} onValueChanged={handleOnNewFilterChange.bind(this)} />
                    </CCol>
                    <CCol sm="6" md={state.sidebarIsOpen ? "6" : "2"} lg="2" xl="2">
                      <UiSelectClassic name="newCompatibility" label={t('Common.compatibility')} disabled={props.disabled}
                        value={state.newCompatibility} values={compatibilityValues} nullable={false}
                        onChange={handleOnNewFilterChange.bind(this)} onValueChanged={handleOnNewFilterChange.bind(this)} />
                    </CCol>
                    <CCol sm="3" md="3" lg="3" xl="3">
                      {state.newFilter && renderFilterValue(state.newFilter, 1000, true, state.newCompatibility)}
                    </CCol>

                  </CRow>
                  <CRow className='add-button-container'>
                    <CCol xs="12" sm="4" md="3" lg="3" xl="2" className="offset-0 offset-sm-8 offset-md-8  mt-sm-2 mt-md-2">
                      <UiButton className="justify-content-center" classNameContainer="ButtonsContainerEnd ms-0  mt-2 mt-sm-0"
                        icon="cis-plus" label={t('Common.add_filter')} disabled={props.disabled || !state.newValue}
                        onClick={handleOnNewFilter.bind(this)} />
                    </CCol>
                  </CRow>
                </>
              )}
            </CCol>
          </CRow>
          <CRow>
            <div className="col offset-0 col-sm-5 offset-sm-7 col-md-3 offset-md-9 ButtonsContainer mt-2 mb-2 me-2">
              <div className="me-0 me-sm-1 me-md-2">
                {state.filters.length > 0 && (<UiButton label={t('Common.clear_all')} icon="cis-minus" onClick={clearAllFilters.bind(this)} />)}
              </div>
            </div>
          </CRow>
        </div>
      )}
    </I18n>
  );
}


UiAdvancedGridFilters.propTypes = propTypes;
export default UiAdvancedGridFilters;
