// GLOBAL
import React from 'react'
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { I18n } from 'react-i18next';
import i18n from 'i18next';
import ApplicationStore from '../../redux/store';

// SERVICES
import ConstantsService from '../../services/constantsService';
import ProjectsService from '../../services/projectsService';

// UI
import { CCard, CCardBody, CCardHeader, CCardTitle, CCol, CRow } from '@coreui/react-pro';

// UTILS
import withRouter from '../../utils/withNavigation';

// COMPONENTS
import { mapStateToProps } from './AbstractComponent';
import AbstractDetail from './AbstractDetail';
import UiAutocomplete from '../ui/UiAutocomplete';
import UiButton from '../ui/UiButton';
import UiCard from '../ui/UiCard';
import UiProductShowcase from '../ui/UiProductShowcase';

class Try extends AbstractDetail {
  show3dRender = true;

  constructor(props) {
    super(props);

    this.projectsService = ProjectsService.getInstance(ApplicationStore);

    this.state.model = {
      tenant_id:  this.globalService.currentUser.tenantId ? this.globalService.currentUser.tenantId : null,
      project_id: this.globalService.currentUser.projects?.length === 1 ? this.globalService.currentUser.projects[0][ConstantsService.defaultDBIdentifier] : null,
    };

    this.state.selectedProduct = null;

    if (!this.globalService.currentUser.appApiKey || !this.globalService.currentUser.appEnvId){
      this.show3dRender = false;
    }
  }

  componentDidUpdate(prevProps, prevState){
    // Se cambio il tenant, il project_id torna null e l'apiReducer dei progetti correlati si svuota
    if (this.state.model.tenant_id !== prevState.model.tenant_id){
      const model = cloneDeep(this.state.model);
      model.project_id = null;
      this.projectsService.initList("project_related");
      this.setState({model});
    }
    
    // Se cambio il progetto, il selectedProduct torna null e l'apiReducer dei prodotti correlati si svuota
    if (this.state.model.project_id !== prevState.model.project_id){
      this.projectsService.initList("project_products");
      this.setState({selectedProduct: null});
    }
  }
  
  handleOnChange(event) {
    const model = cloneDeep(this.state.model);
    eval(`model.${event.target.name} = event.target.value`);
    this.setState({ model });
  }
  
  handleOnForcedChange(event) {
    const model = cloneDeep(this.state.model);

    if (model && eval(`model.${event.target.name} !== event.target.value`)) {
      eval(`model.${event.target.name} = event.target.value`);
    }

    this.setState({ model });
  }

  setProduct(event){
    const productId = Number(event.target.value);
    const products = cloneDeep(this.props.apiReducer.project_products.data);

    const newProduct = products.find((product) => {
      return product.id === productId
    })

    if (newProduct && newProduct.hotspot.length > 0) {
      newProduct.hotspot.forEach((hotspot) => {
        hotspot.targetPosition = hotspot.target_position;
        delete hotspot.target_position;
      });
    }

    this.setState({ selectedProduct: newProduct });
  }

  componentWillUnmount(){
    // Se non lo svuoto, come super_admin quando torno in questa vista autocomplete progetti è vuota
    this.projectsService.initList("project_related");
    this.projectsService.initList("project_products");
  }
    
  render() {
    let hasTenant = true;
    let hasSingleProject = false;
    let canViewProjects = false;
    
    if (!this.globalService.currentUser.tenantId){
      hasTenant = false;
    }

    if (this.globalService.currentUser.policies.includes("project_view")){
      canViewProjects = true;
    }

    if (this.globalService.currentUser?.projects && this.globalService.currentUser.projects.length === 1){
      hasSingleProject = true;
    }

    return (
      <I18n ns="translations">
        {t => (
          <>
            <CCard className="mb-4">
              <CCardHeader>
                <CRow>
                  <CCol sm="12" md="auto">
                    <CCardTitle className='fs-4 fw-semibold'>{t("Section.try_tool")}</CCardTitle>
                  </CCol>
                </CRow>
              </CCardHeader>
              <CCardBody>
                { !(hasTenant && hasSingleProject) &&
                  <CRow className='mb-2'>
                    { !hasTenant &&
                      <CCol sm="12" md="6" className='color-column'>
                        <UiAutocomplete type="text" name="tenant_id" reducer="tenants" label="Table.tenant_name" disabled={false}
                          value={this.state.model?.tenant_id} apiReducer={this.props.apiReducer}
                          values={this.props.apiReducer.tenants?.data} fieldDisplayed="business_name" fieldReturned={ConstantsService.defaultDBIdentifier}
                          onChange={this.handleOnChange.bind(this)}
                          onValueChanged={this.handleOnForcedChange.bind(this)} onSearchChange={this.handleOnSearchChange.bind(this)}
                          />
                      </CCol>
                    }

                    <CCol sm="12" md="6" className={`${(hasTenant && hasSingleProject) && "rightCol"} color-column`}>
                      { (!hasSingleProject && this.state.model.tenant_id && canViewProjects) &&
                        <UiAutocomplete type="text" name="project_id" reducer="project_related" label="Table.project_name" disabled={false}
                          value={this.state.model?.project_id} apiReducer={this.props.apiReducer} bindValue="tenant_id" model={this.state.model}
                          values={this.props.apiReducer.project_related?.data} fieldDisplayed="project_name" fieldReturned={ConstantsService.defaultDBIdentifier}
                          onFocus={this.handleOnFocus.bind(this)} onChange={this.handleOnChange.bind(this)}
                          onValueChanged={this.handleOnForcedChange.bind(this)} onSearchChange={this.handleOnSearchChange.bind(this)}
                          />
                      }
                      
                      { (!hasSingleProject && this.state.model.tenant_id && !canViewProjects) &&
                        <UiAutocomplete type="text" name="project_id" reducer="user_projects" label="Table.project_name" disabled={false}
                          value={this.state.model?.project_id} apiReducer={this.props.apiReducer} model={this.state.model}
                          values={this.props.apiReducer.user_projects?.data} fieldDisplayed="project_name" fieldReturned={ConstantsService.defaultDBIdentifier}
                          onFocus={this.handleOnFocus.bind(this)} onChange={this.handleOnChange.bind(this)}
                          onValueChanged={this.handleOnForcedChange.bind(this)} onSearchChange={this.handleOnSearchChange.bind(this)}
                        />
                      }
                    </CCol>
                  </CRow>
                }

                <CRow>
                  <CCol sm="12" md="6" className='color-column'>
                    { this.state.model.project_id &&
                        <UiAutocomplete type="text" label="Section.products" disabled={false}
                          value={this.state.selectedProduct?.id}
                          values={this.props.apiReducer.project_products?.data} fieldDisplayed="name" fieldReturned={ConstantsService.defaultDBIdentifier}
                          bindValue="project_id" model={this.state.model}
                          reducer="project_products" apiReducer={this.props.apiReducer}
                          onFocus={this.handleOnFocus.bind(this)}
                          onChange={this.setProduct.bind(this)}
                          onValueChanged={this.handleOnForcedChange.bind(this)}
                          onSearchChange={this.handleOnSearchChange.bind(this)}
                        />
                    }
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>

            {/* <CRow className='mb-4'>
              {this.state.model.products.map(item => <CCol sm="auto"><UiButton classNameContainer="UiButton_fit_content" label={item.name} onClick={this.setProduct.bind(this, item)} /></CCol>)}
            </CRow> */}

            { this.state.selectedProduct && this.show3dRender &&
              <CRow className='mt-4'>
                <UiProductShowcase
                  model={this.state.selectedProduct}
                  hotspots={this.state.selectedProduct.hotspot}
                  cmsView={false}
                  currentUser={this.globalService.currentUser}
                />
              </CRow>
            }
          </>
        )}
      </I18n>
    )
  }
}

export default connect(mapStateToProps)(withRouter(Try));