// SERVICES
import ConstantsService from './constantsService';
import GlobalService from './globalService';

// STORE
import ApplicationStore from '../redux/store';
import SiteMapService from './siteMapService';

/**
 * 
 * Servizio che ha lo scopo di mappare tutte le entità da mostrare nelle tabelle in un singolo punto di ingresso.
 * @category Services
 */

export default class TableMapService {
  /**
   * Metodo che restituisce la mappa delle tabelle sottoforma di oggetti JavaScript composti da:
   *
   * <pre>
   * grid: {Number} Indice della colonna del campo nella vista a griglia;
   * sortable: {Boolean} true se nella vista a griglia è possibile ordinare il contenuto su quel valore;
   * position: {Number} 1 se deve essere un filtro su quel valore nella sezione filtri nella vista a griglia, 0 se non lo è;
   * creation: {Numero} Indice di posizione del campo nella modale di creazione;
   * singlerow: {Boolean} true se quel campo deve occupare l'intera riga nella modale di creazione;
   * updatable: {Boolean} true se quel campo può essere aggiornato dopo la creazione;
   * mandatory: {Boolean} true se quel campo deve essere visualizzato come obbligatorio nella modale di creazione e nella vista dettagli;
   * readonly: {Boolean} true se quel campo è di sola lettura;
   * type: {String} Il tipo di componente che deve essere utilizzato per inserire il valore del componente;
   *       managed values:
   *       'autocomplete'
   *       'checkbox'
   *       'checkbox-group'
   *       'chip'
   *       'currency'
   *       'date'
   *       'datetime'
   *       'date-not-null'
   *       'file'
   *       'file-multiple'
   *       'image'
   *       'input'
   *       'input-number'
   *       'select'
   *       'textarea'
   *       'external-link'
   * name: {String} nome della proprietà nell'oggetto JSON;
   * like_name: {String} nome della proprietà da utilizzare come filtro di query nella ricerca backend quando la compatibilità del filtro è impostata su 'Like';
   * label: {String} nome dell'etichetta da utilizzare con quel campo nei file di traduzione (ad esempio: 'Table.name');
   * values: {String[]} array in cui cercare i valori statici disponibili (necessario solo per autocomplete, chip e select);
   * reducer: {String} nome del riduttore in cui cercare i valori disponibili tramite API (necessario solo per autocomplete, chip e select);
   * route: {String} percorso in cui trovare il valore selezionato (necessario solo per autocomplete, chip e select);
   * fieldDisplayed: {String} nome della proprietà del valore selezionato che deve essere visualizzata nel menu a discesa;
   * fieldReturned: {String} nome della proprietà del valore selezionato che deve essere restituita al backend come valore dell'oggetto;
   * extraordinaryCase {Array}
   * prefix {String} stringa visualizzata prima del valore nella vista a griglia
   * suffix {String} stringa visualizzata dopo il valore nella vista a griglia
   * </pre>
   * @static
   * @public
   * @return {Map} the tables map
   */
  static getTableMap() {
    const currentLanguage = localStorage.getItem('i18nextLng');
    this.globalService = GlobalService.getInstance(ApplicationStore, localStorage, ConstantsService.defaultLanguage, SiteMapService.getSiteMap());
    const tableMap = [];

    const canViewProjects = (this.globalService.currentUser.policies.includes("project_view"));
    const canViewTenants = (this.globalService.currentUser.policies.includes("tenant_view"));
    const hasSingleProject = (this.globalService.currentUser.projects?.length === 1);

    tableMap.user = [
      { grid: 1, sortable: false, position: 0, creation: 0, singlerow: false, updatable: false, mandatory: false, readonly: false, type: 'image', name: 'image_path', label: 'Table.pic_url' },
      { grid: 0, sortable: true, position: 1, creation: 3, singlerow: false, updatable: false, mandatory: true, readonly: false, type: 'input', name: 'email', label: 'Table.email' },
      { grid: 4, sortable: false, position: 0, creation: 0, singlerow: false, updatable: false, mandatory: true, readonly: false, type: 'input', name: 'tenant_business_name', label: 'Table.tenant_name', policy: "tenant_view" },
      { grid: 5, sortable: false, position: 0, creation: 0, singlerow: false, updatable: false, mandatory: true, readonly: false, type: 'input', name: 'role_name', label: 'Table.role' },
      { grid: 2, sortable: true, position: 0, creation: 1, singlerow: false, updatable: false, mandatory: true, readonly: false, type: 'input', name: 'first_name', label: 'Table.first_name' },
      { grid: 3, sortable: true, position: 0, creation: 2, singlerow: false, updatable: false, mandatory: true, readonly: false, type: 'input', name: 'last_name', label: 'Table.last_name' },
      { grid: 6, sortable: true, position: 0, creation: 0, singlerow: false, updatable: false, mandatory: false, readonly: false, type: 'checkbox', name: 'active', label: 'Table.active' },
      // { grid: 0, sortable: true, position: 0, creation: 4, singlerow: false, updatable: false, mandatory: true, readonly: false, type: 'input', name: 'password', label: 'Table.password' },
      { grid: 0, sortable: true, position: 2, creation: 4, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'autocomplete', name: 'tenant_id', label: 'Table.tenant', reducer: 'tenants', route: 'tenant', fieldDisplayed: 'business_name', fieldReturned: 'id', policy: "tenant_view" }, // extraordinaryCase: ['tenant_id = field.values && field.values.length > 0 ? field.values[0].id : -1'] }, //EXTRAORDINARY CASES PER I PROGETTI
      { grid: 0, sortable: false, position: 0, creation: 5, singlerow: false, updatable: false, mandatory: true, readonly: false, type: 'autocomplete', name: 'role_id', label: 'Table.role', reducer: 'roles', route: 'role', fieldDisplayed: 'role_name', fieldReturned: 'id', bindValue: 'tenant_id' },
      // { grid: 0, sortable: true, position: 0, creation: 6, singlerow: true, updatable: true, mandatory: true, readonly: false, type: 'checkbox-group', name: 'projects', label: 'Table.projects', columns: 4, fieldDisplayed: 'project_name', fieldReturned: 'id', reducer: 'projects' },
    ];

    tableMap.tenant = [
      { grid: 1, sortable: false, position: 0, creation: 0, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'image', name: 'image_path', label: 'Table.logo' },
      { grid: 3, sortable: true, position: 0, creation: 1, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'vat', label: 'Table.vat_number' },
      { grid: 2, sortable: true, position: 3, creation: 2, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'business_name', label: 'Table.business_name' },
      { grid: 4, sortable: false, position: 4, creation: 0, singlerow: false, updatable: true, mandatory: false, readonly: false, type: 'checkbox', name: 'active', label: 'Table.active' },
      { grid: 0, sortable: false, position: 0, creation: 3, singlerow: true, updatable: false, mandatory: true, readonly: false, type: 'input', name: 'email', label: 'Table.email' },
      // { grid: 0, sortable: false, position: 0, creation: 0, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'color', name: 'primary_color', label: 'Table.primary_color' },
      // { grid: 0, sortable: false, position: 0, creation: 0, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'external-link', name: 'website', label: 'Table.website' },
    ];

    tableMap.tenant_contacts = [
      { grid: 0, sortable: true, position: 1, creation: 1, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'select', name: 'contact_type', label: 'Table.contact_type', values: ConstantsService.contactType, fieldDisplayed: 'label', fieldReturned: 'value', forceTranslation: true  },
      { grid: 0, sortable: true, position: 2, creation: 2, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'first_name', label: 'Table.first_name' },
      { grid: 0, sortable: true, position: 3, creation: 3, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'last_name', label: 'Table.last_name' },
      { grid: 0, sortable: true, position: 4, creation: 4, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'email', label: 'Table.email' },
      { grid: 0, sortable: true, position: 5, creation: 5, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'phone_number', label: 'Table.phone_number' },
    ];

    tableMap.tenant_addresses = [
      { grid: 0, sortable: true, position: 1, creation: 1, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'select', name: 'address_type', label: 'Table.address_type', values: ConstantsService.addressType, fieldDisplayed: 'label', fieldReturned: 'value', forceTranslation: true  },
      { grid: 0, sortable: true, position: 2, creation: 2, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'street', label: 'Table.street' },
      { grid: 0, sortable: true, position: 3, creation: 3, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'state', label: 'Table.state' },
      { grid: 0, sortable: true, position: 4, creation: 4, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'country', label: 'Table.country' },
      { grid: 0, sortable: true, position: 5, creation: 5, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'zip_code', label: 'Table.zip_code' },
    ];

    tableMap.contract = [
      { grid: 1, sortable: true, position: 0, creation: 1, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'name', label: 'Table.name' },
      { grid: 2, sortable: true, position: 0, creation: 2, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'price', label: 'Table.price' },
    ];

    tableMap.project = [
      { grid: 1, sortable: true, position: 1, creation: 1, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'project_name', label: 'Table.project_name' },
      { grid: 0, sortable: true, position: 2, creation: 2, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'autocomplete', name: 'tenant_id', label: 'Table.tenant', reducer: 'tenants', route: 'tenant', fieldDisplayed: 'business_name', fieldReturned: 'id', policy: "tenant_view" }, //EXTRAORDINARY CASES PER I PROGETTI
      { grid: 2, sortable: false, position: 0, creation: 0, singlerow: false, updatable: true, mandatory: false, readonly: false, type: 'input', name: 'tenant_business_name', label: 'Table.tenant', policy: "tenant_view" },
      { grid: 3, sortable: false, position: 0, creation: 0, singlerow: false, updatable: true, mandatory: false, readonly: false, type: 'checkbox', name: 'active', label: 'Table.active' },
    ];

    tableMap.project_users = [
      { grid: 1, sortable: true, position: 0, creation: 0, singlerow: false, updatable: false, mandatory: true, readonly: false, type: 'input', name: 'first_name', label: 'Table.first_name' },
      { grid: 2, sortable: true, position: 0, creation: 0, singlerow: false, updatable: false, mandatory: true, readonly: false, type: 'input', name: 'last_name', label: 'Table.last_name' },
      { grid: 3, sortable: true, position: 0, creation: 0, singlerow: false, updatable: false, mandatory: true, readonly: false, type: 'input', name: 'email', label: 'Table.email' },
      { grid: 0, sortable: true, position: 0, creation: 1, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'autocomplete', name: 'user_id', label: 'Table.user', reducer: 'users', route: 'user', fieldDisplayed: 'email', fieldReturned: 'id', policy: "user_view", tenantAssociated: true }, //EXTRAORDINARY CASES PER I PROGETTI
    ];

    tableMap.product = [
      { grid: 1, sortable: true, position: 1, creation: 1, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'name', label: 'Table.product_name' },
      { grid: 0, sortable: true, position: 3, creation: 3, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'autocomplete', name: 'project_id', label: 'Table.project', reducer: canViewProjects ? 'projects' : "user_projects", route: 'project', fieldDisplayed: 'project_name', fieldReturned: 'id', bindValue: canViewTenants ? "tenant_id" : null, policy: hasSingleProject ? "project_view" : null },
      { grid: 0, sortable: true, position: 2, creation: 2, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'autocomplete', name: 'tenant_id', label: 'Table.tenant', reducer: 'tenants', route: 'tenant', fieldDisplayed: 'business_name', fieldReturned: 'id', policy: "tenant_view" },
      { grid: 2, sortable: false, position: 0, creation: 0, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'project_name', label: 'Table.project' },
      { grid: 0, sortable: true, position: 0, creation: 0, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'tenant_name', label: 'Table.tenant' },
      { grid: 4, sortable: true, position: 0, creation: 0, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'status', label: 'Table.status', forceTranslation: true },
      { grid: 5, sortable: true, position: 5, creation: 5, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'sku_number', label: 'Table.sku_number' },
    ];

    tableMap.role = [
      { grid: 1, sortable: false, position: 0, creation: 1, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'role_name', label: 'Table.role' },
      { grid: 2, sortable: false, position: 0, creation: 0, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'tenant_business_name', label: 'Table.tenant_name', policy: "tenant_view" },
      { grid: 0, sortable: true, position: 1, creation: 2, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'autocomplete', name: 'tenant_id', label: 'Table.tenant', reducer: 'tenants', route: 'tenant', fieldDisplayed: 'business_name', fieldReturned: 'id', policy: "tenant_view" }, //EXTRAORDINARY CASES PER I PROGETTI
      { grid: 0, sortable: true, position: 0, creation: 3, singlerow: true, updatable: true, mandatory: true, readonly: false, type: 'checkbox-group', name: 'policies', label: 'Table.policies', columns: 4, fieldDisplayed: 'policy_name', fieldReturned: 'id', reducer: 'policies', forceTranslation: true }, //EXTRAORDINARY CASES PER I PROGETTI
      { grid: 3, sortable: true, position: 0, creation: 0, singlerow: false, updatable: true, mandatory: false, readonly: false, type: 'checkbox', name: 'active', label: 'Table.active' },
    ];

    return tableMap;
  }

  /**
   * Metodo che ritorna una mappa delle tabelle specifica per gruppo di utenti.
   * @static
   * @public
   * @param {Number} groupIndex Index del gruppo.
   * @return {Map} Mappa delle tabelle.
   */
  static getTableMapByGroup(groupIndex) {
    const tableMap = TableMapService.getTableMap();

    switch (groupIndex) {
      default:
        break;
    }
    return tableMap;
  }
}
