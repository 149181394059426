
import React from 'react';
import PropTypes from 'prop-types';

//UI
import { CHeaderToggler, CHeaderBrand, CHeaderNav, CImage, } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';

//COMPONENTS
import DefaultHeaderDropdown from './DefaultHeaderDropdown';

import logo from '../../../../images/engineering-logo.png';
import logoMin from '../../../../images/engineering-logo-sm.png';

//TYPES
import { userType } from '../../../types';
import { useState, useEffect } from 'react';

const propTypes = {
  currentUser: userType,
  currentSection: PropTypes.object,
  toggleAside: PropTypes.func,
  toggleSidebar: PropTypes.func,
  showSidebar: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const defaultProps = {};
function DefaultHeader(props) {
  const [currentUser, setCurrentUser] = useState({})

  //this useEffect simulate getDerivedStateFromProps
  useEffect(() => {
    setCurrentUser(props.currentUser)
  }, [props])

  const toggleSidebar = () => {
    // const val = [true, 'responsive'].includes(this.props.showSidebar) ? false : 'responsive';
    props.toggleSidebar(!props.showSidebar);
  };

  // toggleSidebarMobile = () => {
  //   // const val = [false, 'responsive'].includes(this.props.showSidebar) ? true : 'responsive';
  //   this.props.toggleSidebar(!this.props.showSidebar);
  // };

  return (
    <>
      <CHeaderToggler className={`${props.showSidebar ? 'd-sm-none' : ''}`} onClick={() => toggleSidebar()}>
        <CIcon className="me-2" size="lg" icon="cis-hamburger-menu" />
      </CHeaderToggler>
      <CHeaderBrand className={`me-2 w-50 ${props.showSidebar ? 'd-sm-none' : ''}`}>
        <CImage className="mw-100" style={{maxHeight: 40}} src={props.logo ? process.env.API_PATH + props.logo : logo} alt="Logo"/>
      </CHeaderBrand>
      <CHeaderNav className="ms-lg-auto">
        {props.currentLanguage && (
          <DefaultHeaderDropdown
            langs
            currentUser={currentUser}
            currentLanguage={props.currentLanguage}
            changeLanguage={props.changeLanguage}
          />
        )}
        {currentUser && (
          <DefaultHeaderDropdown
            accnt
            currentUser={currentUser}
            isBlocking={props.isBlocking}
          />
        )}
      </CHeaderNav>
    </>
  );
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
