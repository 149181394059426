import BaseService from './baseService';

export default class TenantsContactsService extends BaseService {
  static instance = null;

  static getInstance(store) {
    if (!TenantsContactsService.instance) {
      TenantsContactsService.instance = new TenantsContactsService(store);
    }
    return TenantsContactsService.instance;
  }

  constructor(store) {
    const baseUrl = process.env.API_PATH;
    super(store, baseUrl, 'tenants', 'tenant');
  }

  addItem(tenantId, obj, lang, token, okCallBack, koCallBack) {
    return super.post(this.itemName, `${this.urlPath}/${tenantId}/contacts`, lang, token, obj, okCallBack, koCallBack);
  }

  updateItem(tenantId, obj, lang, token, okCallBack, koCallBack) {
    return super.put(this.itemName, `${this.urlPath}/${tenantId}/contacts/${obj.id}`, lang, token, obj, okCallBack);
  }

  deleteItem(tenantId, addressId, lang, token, okCallBack, koCallBack) {
    return super.delete(this.itemName, `${this.urlPath}/${tenantId}/contacts/${addressId}`, lang, token, okCallBack);
  }
}