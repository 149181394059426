// GLOBAL
import React, { Component } from 'react';
import { CButton, CCol, CRow } from '@coreui/react-pro';
import { toast, ToastContainer } from 'react-toastify';
import i18n from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import camelCase from 'lodash/camelCase';
import kebabCase from 'lodash/kebabCase';
import snakeCase from 'lodash/snakeCase';
import moment from 'moment';
import BackToUp from '@uiw/react-back-to-top';

// STORE
import ApplicationStore from '../../redux/store';

// SERVICES
import GlobalService from '../../services/globalService';

import WebsocketService from '../../services/websocketService';
import ConstantsService from '../../services/constantsService';
import SiteMapService from '../../services/siteMapService';
import TableMapService from '../../services/tableMapService';

// UTILS
import ObjectsUtils from '../../utils/objectsUtils';

// COMPONENTS
import UiModal from '../ui/UiModal';
import UiLoading from '../ui/UiLoading';

import UiSortableGrid from '../ui/UiSortableGrid';
import UiFilesUpload from '../ui/UiFilesUpload';
import UiRadioButton from '../ui/UiRadioButton';
import UiSelect from '../ui/UiSelect';

/**
 * Componente base per ogni vista del sito.
 * Il costruttore istanzia GlobalService, WebsocketService e il servizio di base della vista impostato in {@link SiteMapService}.
 * @category Components
 * @subcategory views
 * @extends React.Component
 * @param {object} props - Le props del componente React.
*/
export default class AbstractComponent extends Component {
  constructor(props) {
    super(props);

    this.globalService = GlobalService.getInstance(ApplicationStore, localStorage, ConstantsService.defaultLanguage, SiteMapService.getSiteMap());
    //this.websocketService = WebsocketService.getInstance(ApplicationStore, process.env.WEBSOCKET_PATH, process.env.WEBSOCKET_CHANNEL_TYPE, process.env.WEBSOCKET_CHANNEL, process.env.WEBSOCKET_CLASS);

    const date = JSON.parse(localStorage.getItem('msgQueueDate'));
    if (!date || new Date().getTime() - new Date(date).getTime() > 1000 * 60 * 60) {
      localStorage.removeItem('msgQueue');
      localStorage.removeItem('msgQueueDate');
    }

    this.reducersMapByRoute = SiteMapService.getReducersMapByRoute();
    if (this.globalService.currentUser && this.globalService.currentUser.userId !== 0) {
      this.populateServices();
    }
  }

  componentDidMount() {
    this.props.onSaveButtonEnabled(false);
  }

  /**
   * Metodo chiamato dal costruttore che, se l'utente attuale è impostato:
   * - Istanzia il servizio della sezione corrente.
   * - Calcola il currentUserGroup dai dati dell'utente.
   * - Istanzia e inizializza tutti i reducer correlati della sezione corrente dalla lista dei reducer impostata in {@link TableMapService}.
   * - Ottiene tutti gli export disponibili per l'utente corrente tramite chiamate API.
   * @public
  */
  populateServices() {
    if (!this.globalService.currentUser || !(this.globalService.currentUser.userId !== 0)) {
      return;
    }
    
    if (!this.sectionService) {
      this.sectionService = this.globalService.currentSection.sectionService.getInstance(ApplicationStore);
      const tableMap = (this.globalService.currentSection.sectionChild) ? TableMapService.getTableMapByGroup(this.globalService.currentUser.userGroup)[this.globalService.currentSection.sectionChild] : TableMapService.getTableMapByGroup(this.globalService.currentUser.userGroup)[this.globalService.currentSection.sectionId];
      const fieldsWithReducerList = (tableMap) ? tableMap.filter((obj) => obj.reducer !== undefined) : [];
      for (const field of fieldsWithReducerList) {
        const reducer = Object.values(this.reducersMapByRoute).find((obj) => obj.reducer_name === field.reducer);
        if (field.type === 'autocomplete') {
          this.initReducer(reducer.service, reducer.reducer_name, this.onReducerInitialized.bind(this), this.onReducerError.bind(this));
        } else {
          this.populateReducers(null, reducer.service, field.reducer, this.onReducerReady.bind(this), this.onReducerError.bind(this));
        }
      }
    }
  }

  /**
   * Metodo che istanzia un servizio e inizializza il suo reducer.
   * @param {object} service La classe del servizio da inizializzare.
   * @param {string} reducer Il nome del reducer.
   * @param {function} okCallBack Callback eseguita in caso di successo.
   * @param {function} koCallBack Callback eseguita in caso di errore.
   */
  initReducer(service, reducer, okCallBack, koCallBack) {
    service.getInstance(ApplicationStore).initList(reducer, okCallBack, koCallBack);
  }

  /**
   * Metodo che istanzia un servizio e inizializza e popola con la sua lista di reducer.
   * @param {object} obj Map dei filtri per per il metodo getList.
   * @param {object} service Il servizio da inizializzare.
   * @param {string} reducer Il nome del reducer.
   * @param {function} okCallBack Callback eseguita in caso di successo.
   * @param {function} koCallBack Callback eseguita in caso di errore.
   */
  populateReducers(obj, service, reducer, okCallBack, koCallBack) {
    if (this.props.apiReducer && (!this.props.apiReducer[reducer]
      || this.props.apiReducer[reducer] && !this.props.apiReducer[reducer].data
      || this.props.apiReducer[reducer] && !isEqual(this.props.apiReducer[reducer].filter, obj))) {
      const params = (obj) || { query_origin: 'select' };
      // We need to show all policies into role creation modal/detail
      if (reducer === "policies") params.all_pages = true;
      if (!reducer.includes('_related')) {
        service.getInstance(ApplicationStore).getList(params, this.globalService.currentLanguage, this.globalService.currentUser, (okCallBack) ? okCallBack.bind(this, reducer) : undefined, (koCallBack) ? koCallBack.bind(this, reducer) : undefined);
      } else {
        service.getInstance(ApplicationStore).getRelatedList(params, this.globalService.currentLanguage, this.globalService.currentUser, (okCallBack) ? okCallBack.bind(this, reducer) : undefined, (koCallBack) ? koCallBack.bind(this, reducer) : undefined);
      }
    }
  }

  onReducerInitialized(reducer, response) { }

  onReducerReady(reducer, response) { }

  onReducerError(reducer, response) { }

  /**
   * Metodo per calcolare se il componente deve o meno renderizzarsi, restituendo false in caso di:
   * - Cambio dell'utente attuale (e chiamata al metodo populateServices).
   * - Il servizio della sezione è in stato di caricamento o in stato di errore (in caso di errore, reindirizzare alla pagina 500).
   * In caso contrario, prima di restituire true, verifica se è necessario renderizzare il modal di esportazione o importazione.
   * o se è necessario renderizzare un nuovo messaggio WebSocket.
   * @override
   * @return {boolean} Restituisce true se il componente deve renderizzarsi.
  */
  shouldComponentUpdate(nextProps) {
    if (nextProps.globalServiceReducer) {
      if (!isEqual(nextProps.globalServiceReducer.currentUser, this.props.globalServiceReducer.currentUser) && this.globalService.currentUser.userId === nextProps.globalServiceReducer.currentUser.userId) {
        this.populateServices();
        return false;
      }
    }
    if (this.sectionService) {
      if (nextProps.globalServiceReducer) {
        if (nextProps.globalServiceReducer.currentSection && nextProps.apiReducer && !isEqual(this.props.apiReducer, nextProps.apiReducer)) {
          let target = nextProps.globalServiceReducer.currentSection.sectionId === 'dashboard' ? nextProps.globalServiceReducer.currentSection.sectionReducerId : nextProps.globalServiceReducer.currentSection.sectionId;
          // Nella sezione Try, il target varia in base al ruolo e quindi non basta qui sopra
          // Se non si imposta un target coerente con l'apiReducer, la vista non si rirenderizza e bisogna forzare un rerender per avere i dati nell'apiReducer
          if (nextProps.globalServiceReducer.currentSection.sectionId === 'try'){
            if (this.state.model.tenant_id) {
              target = "user_projects";
              if (this.globalService.currentUser.policies.includes("project_view")){
                target = "project_related";
              }
              if (this.state.model.project_id) target = "project_products";
            } else {
              target = "tenants";
            }
          }
          if (!nextProps.apiReducer[target] || nextProps.apiReducer[target].status === 'LOADING') {
            return false;
          }
          if (nextProps.apiReducer[target] && nextProps.apiReducer[target].status === 'SERVER_INVALID') {
            this.props.navigate('/500');
          }
        }
        if (nextProps.globalServiceReducer.exportTime !== this.props.globalServiceReducer.exportTime) {
          this.toggleModal('modalExport');
        }
        if (nextProps.globalServiceReducer.importTime !== this.props.globalServiceReducer.importTime) {
          this.toggleModal('modalImport');
        }
        if (nextProps.globalServiceReducer.websocketLastMessage !== this.props.globalServiceReducer.websocketLastMessage) {
          this.renderWebsocket(nextProps.globalServiceReducer.websocketLastMessage);
        }
      }
    }
    return true;
  }

  renderWebsocket(message) {
    if (message) {
      if (message.isAlive) {
        // return toast.success('Alive');
      } else if (message.user_id === this.globalService.currentUser.userId) {
        let msgQueue = this.globalService.msgQueue.filter((obj) => obj[ConstantsService.defaultDBIdentifier] !== message[ConstantsService.defaultDBIdentifier]);
        if (msgQueue) {
          let title = null;
          let type = null;
          let progress = null;
          if (message.job_type === 'export') {
            if (message.request_body) {
              type = message.request_body.type;
              title = `${i18n.t(`Section.${message.request_body.type}`)} - ${i18n.t(`SelectValues.${message.request_body.export_type}`)}`;
            }
            progress = message.file_path ? 100 : message.datetime_processing === null ? 10 : 0;
          } else {
            title = i18n.t(`Section.${message.export_type}`);
            type = message.export_type;
            progress = message.error_message ? 100 : 0;
          }
          if (title) {
            const doc = {
              id: message[ConstantsService.defaultDBIdentifier],
              job_type: message.job_type,
              title,
              subtitle: moment(message.created_at).format('DD/MM/YYYY HH:mm'),
              progress,
              error_message: message.error_message,
              type,
              url: message.file_path,
            };
            msgQueue.unshift(doc);
          }
        } else {
          msgQueue = [];
        }
        this.globalService.msgQueue = msgQueue;

        return toast.success(i18n.t('Common.document_ready'));
      }
    }
    return null;
  }

  koCallback(error) {
    let errors = [];
    errors.push(ObjectsUtils.buildError(error));
    this.setState({ loadingModal: false, errorsModal: errors });
  }

  handleOnSearchChange(event) {
    if (event && event.target && event.target.fieldname && event.target.search && (event.target.search.length > 2 || !Number.isNaN(parseInt(event.target.search)))) {
      let reducerName = null;
      let service = null;
      let staticFilter = {};
      const basicFilter = (this.state.basicFilter && this.state.basicFilter.filter) ? this.state.basicFilter.filter : [];
      const { route } = event.target;
      if (route && this.reducersMapByRoute[route]) {
        reducerName = this.reducersMapByRoute[route].reducer_name;
        service = this.reducersMapByRoute[route].service.getInstance(ApplicationStore);
        staticFilter = (this.reducersMapByRoute[route].static_filter) ? this.reducersMapByRoute[route].static_filter : {};
        if (!this.props.apiReducer[reducerName]) {
          service.initList(reducerName);
          //return;
        }
        if (this.props.apiReducer[reducerName].status === 'LOADING') {
          return;
        }
      }
      if (!route || route === 'filter') {
        reducerName = event.target.reducer;
        if (reducerName) {
          const reducer = Object.values(this.reducersMapByRoute).filter((obj) => obj.reducer_name === reducerName);
          service = (reducer && reducer.length > 0) ? reducer[0].service.getInstance(ApplicationStore) : null;
          staticFilter = (reducer && reducer.length > 0 && reducer[0].static_filter) ? reducer[0].static_filter : {};
          if (!this.props.apiReducer[reducerName]) {
            service.initList(reducerName);
            //return;
          }
          if (this.props.apiReducer[reducerName].status === 'LOADING') {
            return;
          }
        }
      }
      if (reducerName && service) {
        if (event.target.search) {
          if (event.target.search !== -1) {
            staticFilter[event.target.fieldname] = event.target.search;
          } else {
            staticFilter.paginate = ConstantsService.pageForAutocomplete;
          }
          if (event.target.bindValue && event.target.model && event.target.model[event.target.bindValue]){
            staticFilter[event.target.bindValue] = event.target.model[event.target.bindValue];
          }
          staticFilter.sort = (process.env.API_URL_FORMAT === 'kebabCase') ? kebabCase(event.target.fieldname) : (process.env.API_URL_FORMAT === 'snakeCase') ? snakeCase(event.target.fieldname) : (process.env.API_URL_FORMAT === 'camelCase') ? camelCase(event.target.fieldname) : event.target.fieldname;
          staticFilter.order = 'asc';
        }
        staticFilter.query_origin = 'select';
        if (!staticFilter.tenant_id){
          staticFilter.tenant_id = event.target.tenant_id;
        }

        if (!staticFilter.filter) {
          staticFilter.filter = [];
        }

        if (reducerName.indexOf('_related') > 0) {
          staticFilter.reducer_name = reducerName;
          service.getRelatedList(staticFilter, this.globalService.currentLanguage, this.globalService.currentUser);
        } else {
          service.getList(staticFilter, this.globalService.currentLanguage, this.globalService.currentUser);
        }
      }
    }
  }

  handleOnSearchCityChange(country, event) {
    if (event && event.target && event.target.fieldname && event.target.search && (event.target.search.length > 2 || !Number.isNaN(parseInt(event.target.search)))) {
      if (this.props.apiReducer.cities.status === 'LOADING') {
        return;
      }
      const service = this.reducersMapByRoute.city.service.getInstance(ApplicationStore);
      let filter = { [event.target.fieldname]: event.target.search };
      if (country) {
        filter = { /* paginate: ConstantsService.pageForAutocomplete, */[event.target.fieldname]: event.target.search, query_origin: 'select' };
      }
      service.getList(filter, this.globalService.currentLanguage, this.globalService.currentUser);
    }
  }

  toggleModal(target) {
    this.setState({
      [target]: !this.state[target],
      errorsModal: null,
    });
  }

  renderSpinner() {
    return <UiLoading height={80} width={80} />;
  }

  renderToast() {
    return <ToastContainer position="top-right" autoClose={1500} />;
  }

  renderScrollUpButton() {
    return (
      <BackToUp>Top</BackToUp>
    );
  }

  handleOnForcedChange(event) {
    const model = cloneDeep(this.state.model);
    let changed = false;
    eval(`changed = (model.${event.target.name} !== event.target.value)`);
    if (changed) {
      eval(`model.${event.target.name} = event.target.value`);

      this.setState({ model, changing: true }, () => { this.props.onSaveButtonEnabled(this.state.changing); this.handleOnBlur(event); });
    }
  }

  handleOnExportChange(event) {
    let exportData = cloneDeep(this.state.exportData);
    if (event.target.name === 'type') {
      exportData = {
        locale: 'it'
      };
    }
    if (!exportData.locale) {
      exportData.locale = 'it';
    }
    eval(`exportData.${event.target.name} = event.target.value`);

    this.setState({ loadingModal: false, errorsModal: null, exportData });
  }

  renderExportModalContent(t) {
    const lastSelected = JSON.parse(localStorage.getItem('lastSelected'));
    const lastSearches = JSON.parse(localStorage.getItem('lastSearches'));

    const values = [];

    /*if (this.props.apiReducer['artwork-available-exports'] && this.props.apiReducer['artwork-available-exports'].status === 'VALID') {
      if (lastSelected && lastSelected.artwork && lastSelected.artwork.length > 0) {
        values.push({ label: t('Common.artwork_detail'), id: 'artwork' });
      }
    }*/

    return (
      <CRow>
        <CCol sm="12" md="3" lg="3" xl="3">
          {this.state.exportData && values.length > 0 && (
            <UiRadioButton name="type" label="Common.export_subject"
              required values={values}
              value={this.state.exportData.type} fieldDisplayed="label" fieldReturned={ConstantsService.defaultDBIdentifier}
              onChange={this.handleOnExportChange.bind(this)} />
          )}
        </CCol>
        <CCol sm="12" md="9" lg="9" xl="9">
          {this.state.exportData && this.state.exportData.type && (
            <UiSelect name="export_type" label="Common.export_type"
              required values={this.props.apiReducer[(this.state.exportData.type.indexOf('artworks') === 0) ? 'artworks-available-exports' : `${this.state.exportData.type}-available-exports`].data}
              value={this.state.exportData.type}
              onChange={this.handleOnExportChange.bind(this)} />
          )}
        </CCol>
      </CRow>
    );
  }

  isExportModelValid() {
    if (this.state.exportData !== undefined) {
      if (this.state.exportData.export_type !== undefined) {
        return true;
      }
    }
    return false;
  }

  renderExportModal(t) {
    return (
      <UiModal className="ExportModal" title={t('Common.export')} okLabel={(this.state.exportData && this.state.exportData.url) ? t('Common.download') : t('Common.ok')}
        errorsModal={this.state.errorsModal} loadingModal={this.state.loadingModal} valid={this.isExportModelValid()} isOpen={this.state.modalExport}
        onSubmit={(this.state.exportData && this.state.exportData.success) ? this.onDownload.bind(this) : this.onExport.bind(this)} onCancel={this.onCancelExport.bind(this)}>

        {this.state.exportData && !this.state.exportData.success
          && (
            <div>
              {this.renderExportModalContent(t)}
            </div>
          )}
        {this.state.exportData && this.state.exportData.success
          && <p>{t('Common.export_success')}</p>}
      </UiModal>
    );
  }

  onExport() {
    this.setState({ loadingModal: true });

    function okCallback() {
      const exportData = this.state.exportData ? cloneDeep(this.state.exportData) : {};
      exportData.success = true;
      this.setState({ loadingModal: false, errorsModal: null, exportData });
      // this.setState({loadingModal: false, errorsModal:null, exportData: {}});
      // this.toggleModal('modalExport');
    }

    const body = cloneDeep(this.state.exportData);
    const lastSelected = JSON.parse(localStorage.getItem('lastSelected'));
    const lastSearches = JSON.parse(localStorage.getItem('lastSearches'));
    let ids = (lastSelected && ObjectsUtils.isArrayValid(lastSelected[body.type]) && lastSelected[body.type].length > 0) ? lastSelected[body.type] : null;
    let filter = (lastSearches && lastSearches[body.type] && lastSearches[body.type].total > 0) ? lastSearches[body.type].filter : null;
    let exportFilter = null;

    if (filter) {
      exportFilter = this.sectionService.buildQueryParams({ filter: Object.values(filter) });
    }

    if (!ids && (!exportFilter || exportFilter.length === 0) && body.type === 'exhibitions') {
      ids = ['*'];
    }

    if (!ids && (!exportFilter || exportFilter.length === 0)) {
      const errors = [];
      errors.push(i18n.t('Common.nothing_to_export'));
      this.setState({ loadingModal: false, errorsModal: errors });
    }

    if (ids) {
      body.ids = ids;
    } else if (exportFilter) {
      //filter.query_origin = 'table';
      body.filter = exportFilter.substring(1);
    }

    this.genericService.export(body, this.globalService.currentLanguage, this.globalService.currentUser, okCallback.bind(this), this.koCallback.bind(this));
  }

  onDownload() {
    this.toggleModal('modalExport');
    /* if (Array.isArray(this.state.exportData.url)) {
      for (let url of this.state.exportData.url) {
        if (url) {
          window.open(url, '_blank');
        }
      }
    } else {
      window.open(this.state.exportData.url, '_blank');
    } */
    // this.props.navigate(this.state.exportData.url);
    this.setState({ exportData: {} });
  }

  onCancelExport() {
    this.toggleModal('modalExport');
    this.setState({ exportData: {} });
  }

  handleOnImportChange(event) {
    let importData = cloneDeep(this.state.importData);
    if (event.target.name === 'target') {
      importData = {};
    }
    eval(`importData.${event.target.name} = event.target.value`);

    this.setState({ importData });
  }

  handleGetTemplate() {
    this.setState({ loadingModal: true });

    function okCallback(success) {
      const importData = this.state.importData ? cloneDeep(this.state.importData) : {};
      if (success.data && success.data[this.state.importData.target]) {
        importData.template_urls = success.data;
        this.setState({ loadingModal: false, errorsModal: null, importData });
        window.open(success.data[this.state.importData.target], '_blank');
      } else {
        this.setState({ loadingModal: false, errorsModal: [i18n.t('Common.missing_template')] });
      }
    }

    if (!this.state.importData.template_urls || !this.state.importData.template_urls[this.state.importData.target]) {
      this.genericService.getImportTemplate(this.globalService.currentLanguage, this.globalService.currentUser, okCallback.bind(this), this.koCallback.bind(this));
    } else {
      window.open(this.state.importData.template_urls[this.state.importData.target], '_blank');
      this.setState({ loadingModal: false, errorsModal: null });
    }
  }

  renderImportModalContent(t) {
    return (
      <CRow>
        {this.state.importData && (
          <CCol sm="12" md="12" lg="12" xl="12">
            <UiRadioButton name="target" label="Common.import_type"
              value={this.state.importData.target} values={['customers']} onChange={this.handleOnImportChange.bind(this)} />
          </CCol>
        )}
        {this.state.importData && this.state.importData.target && (
          <CCol sm="12" md="12" lg="12" xl="12">
            <CButton id="template" variant="ghost" color="primary" onClick={this.handleGetTemplate.bind(this)}>
              {t('Common.template')}
            </CButton>
          </CCol>
        )}
        {this.state.importData && this.state.importData.target && (
          <CCol sm="12" md="12" lg="12" xl="12">
            <UiFilesUpload name="file" label="Common.import_file" onChange={this.handleOnImportChange.bind(this)} />
          </CCol>
        )}
      </CRow>
    );
  }

  isImportModelValid() {
    return true;
  }

  renderImportModal(t) {
    return (
      <UiModal title={t('Common.import')} okLabel={(this.state.importData && this.state.importData.message) ? t('Common.ok') : t('Common.upload')}
        errorsModal={this.state.errorsModal} loadingModal={this.state.loadingModal} valid={this.isImportModelValid()} isOpen={this.state.modalImport}
        onSubmit={(this.state.importData && this.state.importData.message) ? this.onImport.bind(this) : this.onUpload.bind(this)} onCancel={this.onCancelImport.bind(this)}>

        {this.state.importData && !this.state.importData.message
          && (
            <div>
              {this.renderImportModalContent(t)}
            </div>
          )}
        {this.state.importData && this.state.importData.message
          && <p>{this.state.importData.message}</p>}
      </UiModal>
    );
  }

  onUpload() {
    this.setState({ loadingModal: true });

    function okCallback(success) {
      const importData = this.state.importData ? cloneDeep(this.state.importData) : {};
      if (success.data && success.data.message) {
        importData.message = success.data.message;
        this.setState({ loadingModal: false, errorsModal: null, importData });
      } else {
        this.setState({ loadingModal: false, errorsModal: [i18n.t('Common.nothing_to_import')] });
      }
    }

    this.genericService.import(this.state.importData, this.globalService.currentLanguage, this.globalService.currentUser, okCallback.bind(this), this.koCallback.bind(this));
  }

  onImport() {
    this.toggleModal('modalImport');
    this.setState({ importData: {} });
  }

  onCancelImport() {
    this.toggleModal('modalImport');
    this.setState({ importData: {} });
  }

  render() {
    return super.render();
  }
}

export const mapStateToProps = state => (
  {
    tokenReducer: state.tokenReducer,
    globalServiceReducer: state.globalServiceReducer,
    apiReducer: state.apiReducer
  }
);
