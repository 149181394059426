import React, { useEffect } from 'react';
import { CRow, CFormLabel, CFormInput, CButton, CTooltip } from '@coreui/react-pro';
import { I18n } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';

import UiButton from './UiButton';
import { useState } from 'react';
import { useRef } from 'react';
import CIcon from '@coreui/icons-react';
import UiModal from './UiModal';

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  required: PropTypes.bool,
  filename: PropTypes.string
};

function UiSingleFileUpload(props) {
  const [isDragging, setIsDragging] = useState(false);
  const [files, setFiles] = useState(props.filename ? [{name: props.filename}] : []);
  const [showModal, setShowModal] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();

  let UiInputFile = useRef(null);

  useEffect(() => {
    document.addEventListener('resetFile', resetFile);

    return () => {
      document.removeEventListener('resetFile', resetFile);
    }
  }, [])

  useEffect(() => {
    if (props.filename === null && files && files.length >= 1){
      resetFile();
    }

    if (props.filename && (!files || files.length === 0)){
      setFiles([{name: props.filename}]);
    }
  }, [props.filename])

  function resetFile() {
    setFiles([])
  }

  function handleImageChange(ev) {
    ev.preventDefault();
    if (files && files.length > 0) return;
    const tmpfiles = cloneDeep(files);

    if (ev.target.files && ev.target.files.length > 0) {
      if (props.multiple) {
        for (let i = 0; i < ev.target.files.length; i++) {
          tmpfiles[tmpfiles.length] = ev.target.files[i];
        }
      } else {
        tmpfiles[0] = ev.target.files[0];
        setPreviewUrl(URL.createObjectURL(tmpfiles[0]))
      }
    }
    // setFiles(tmpfiles);
    dispatchChange(tmpfiles);
  }

  function handleDragOver(ev) {
    ev.preventDefault();
    if (files && files.length > 0) return;

    if (!isDragging) {
      setIsDragging(true);
    }
  }

  function handleDrop(ev) {
    ev.preventDefault();
    if (files && files.length > 0) return;

    const tmpfiles = cloneDeep(files);
    if (ev.dataTransfer.files && ev.dataTransfer.files.length > 0) {
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        tmpfiles[tmpfiles.length] = ev.dataTransfer.files[i];
      }
    }
    // setFiles(tmpfiles);
    dispatchChange(tmpfiles);
  }

  function handleDragLeave() {
    if (files && files.length > 0) return;

    if (isDragging) {
      setIsDragging(false);
    }
  }

  function dispatchChange(files) {
    if (!props.multiple && props.filename) return;

    const event = {
      target: {
        name: props.name,
        value: (props.multiple) ? files : files[0],
      },
    };
    if (props.onChange) {
      props.onChange(event);
    }
  }

  function deleteFile(){
    setShowModal(false);
    if (props.onDelete && typeof props.onDelete === "function"){
      props.onDelete(files[0]);
    }
  }

  return (
    <I18n ns="translations">
      {t => (
        <div className="UiSingleFileUpload">
          <UiModal title={t(`Common.handle_tenant_status`)} okLabel={t('Common.yes')} koLabel={t('Common.no')}
            isOpen={showModal}
            onSubmit={deleteFile}
            onCancel={() => setShowModal(false)}
          >
            <p>{t(`Common.confirm_${props.name}_delete`)}</p>
          </UiModal>
          <div>
            {props.label ? (
              <>
                <CFormLabel className='me-1 fw-bold'>
                  {t(props.label)} {props.required ? '*' : ''}
                </CFormLabel>
              </>
            ) : (
              ''
              )}
            { props.tooltipMsg &&
              <CTooltip placement="right" content={t(`Tooltips.${props.tooltipMsg}`)}>
                <CIcon icon="cis-info-circle" size="sm" />
              </CTooltip>
            }

            {
              props.description && <div className='UiSingleFileUpload__subtitle'>{t(props.description)}</div>
            }
          </div>

          {
            !(files && files.length > 0) ?
            <div className={`UiPictureUploadPreview ${isDragging ? ' dragInFile' : ''}`}
              draggable onDragEnter={handleDragOver.bind(this)} onDragOver={handleDragOver.bind(this)}
              onDragLeave={handleDragLeave.bind(this)} onDrop={handleDrop.bind(this)}>
              <div className={`placeholderContent`}>
                <div>{t(props.multiple ? 'Common.dnd_files_here' : 'Common.dnd_file_here')}</div>
                <UiButton disabled={files && files.length > 0} className="UiAddFileButton" label={t('Common.change_file')} onClick={() => (UiInputFile.click())} />
              </div>

              <CRow>
                <div>
                  <CFormInput type="file" ref={node => {
                    if (node) {
                      UiInputFile = node;
                    }
                  }}
                  id="UiInputFile" className="UiInputFile" name="UiInputFile" onChange={e => handleImageChange(e)} multiple={props.multiple} key={Date.now()} />
                </div>
              </CRow>
            </div>
            :
            <div className='files-container'>
              {
                files.map((file, index) => (
                  <div key={index + "-" + Math.random()} className='uploaded-file'>
                    <span key={`file-upload-${props.name}-${index}`} className="filename">{file.name}</span>
                    <CButton variant="ghost" color="danger" disabled={props.disabled}
                      title={t('Common.delete_file')}
                      onClick={() => setShowModal(true)}
                      >
                      <CIcon icon="cis-trash" />
                    </CButton>
                  </div>
              ))}
            </div>
          }
        </div>
      )}
    </I18n>
  );
}

UiSingleFileUpload.propTypes = propTypes;

export default UiSingleFileUpload;