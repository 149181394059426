import React, { useState } from 'react';
import { CFormTextarea, CFormLabel, CTooltip } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { I18n } from 'react-i18next';
import PropTypes from 'prop-types';

const propTypes = {
  onBlur: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  tooltipMsg: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

function UiTextArea(props) {
  const [touched,setTouched] = useState(false);
  const [invalid,setInvalid] = useState(props.required ? false : null);

  function handleOnChange(evt) {
    if (!props.disabled) {
      const event = {
        target: {
          name: evt.target.name,
          value: evt.target.value && evt.target.value.length > 0 ? evt.target.value : null,
        },
      };

      if (props.onChange) props.onChange(event);
      setInvalid(props.required ? !evt.target.value : null);
      // setState({ invalid: props.required ? !evt.target.value : null });
    }
  }

  function handleOnFocus(evt) {
    if (!props.disabled) {
      setInvalid(props.required ? false : null);
      setTouched(true);
      // setState({ invalid: props.required ? false : null, touched: true });
      if (props.onFocus) props.onFocus(evt);
    }
  }

  function handleOnBlur() {
    if (!props.disabled) {
      const event = {
        target: {
          name: props.name,
          value: props.value && props.value.length > 0 ? props.value : null,
        },
      };
      setInvalid(props.required ? !props.value : null);
      // setState({ invalid: props.required ? !props.value : null });

      if (props.onBlur) props.onBlur(event);
    }
  }

  return (
    <I18n ns="translations">
      {t => (
        <div className={`UiTextArea${invalid && touched ? ' is-invalid' : ''}`}>
          {props.label ? (
            <CFormLabel htmlFor={props.name} className="UiMainLabel">
              {t(props.label)} {props.required ? '*' : ''}
              {props.tooltipMsg && (
                <span>
                  <CTooltip placement="top" content={t(props.tooltipMsg)}>
                    <CIcon icon="cis-info-circle" size="sm" />
                  </CTooltip>
                </span>
              )}
            </CFormLabel>
          ) : (
            ''
          )}
          <CFormTextarea id={props.name} className={`form-group${invalid && touched ? ' is-invalid' : ''}`}
            name={props.name} disabled={props.disabled}
            rows={props.rows ? props.rows : 5} placeholder={(props.disabled) ? '' : (props.placeholder) ? t(props.placeholder) : t('Common.some_text')}
            required={props.required} value={props.value ? props.value : ''}
            onFocus={handleOnFocus.bind(this)} onChange={handleOnChange.bind(this)} onBlur={handleOnBlur.bind(this)} />

          {invalid && touched && (
            <div className="invalid-feedback">{t('Common.field_invalid')}</div>
          )}
        </div>
      )}
    </I18n>
  );
}

UiTextArea.propTypes = propTypes;

export default UiTextArea;
