import React from 'react';
import PropTypes from 'prop-types';
import { CFormCheck, CFormLabel, CTooltip } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { I18n } from 'react-i18next';

const propTypes = {
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  tooltipMsg: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
};

function UiCheckbox(props) {

  function handleOnChange(evt) {
    if (!props.disabled) {
      const event = {
        target: {
          name: evt.target.name,
          value: evt.target.checked,
        },
      };
      if (props.onChange) props.onChange(event);

      if (props.onBlur) {
        setTimeout(() => {
          props.onBlur(event);
        }, 500);
      }
    }
  }

  function handleOnFocus(evt) {
    if (!props.disabled && props.onFocus) {
      props.onFocus(evt);
    }
  }

  return (
    <I18n ns="translations">
      {t => (
        <>
          <CFormCheck id={`${props.name}`} name={props.name} disabled={props.disabled}
            required={props.required} checked={props.value === true}
            label={t(props.label)}
            onFocus={handleOnFocus.bind(this)} onChange={handleOnChange.bind(this)}
          />
          {props.tooltipMsg && (
            <span>
              <CTooltip placement="right" content={t(props.tooltipMsg)}>
                <CIcon icon="cis-info-circle" size="sm" />
              </CTooltip>
            </span>
          )}
        </>
      )}
    </I18n>
  );
}

UiCheckbox.propTypes = propTypes;

export default UiCheckbox;
