import BaseService from './baseService';

export default class PoliciesService extends BaseService {
    static instance = null;

    static getInstance(store) {
        if (!PoliciesService.instance) {
            PoliciesService.instance = new PoliciesService(store);
        }
        return PoliciesService.instance;
    }

    constructor(store) {
        const baseUrl = process.env.API_PATH;
        super(store, baseUrl, 'policies', 'policy');
    }
}
