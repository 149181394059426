import React, { useEffect, useState, useRef } from 'react';
import { I18n } from 'react-i18next';
import i18n from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import { Chips, Chip } from 'react-chips-input'

//UI
import { CFormInput, CFormLabel } from '@coreui/react-pro';


const propTypes = {
  onBlur: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.array,
  values: PropTypes.array,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

function UiInputChip(props) {
  const [tags, setTags] = useState({});
  const [val, setVal] = useState();
  const [fieldDisplayed, setFieldDisplayed] = useState();
  const [fieldReturned, setFieldReturned] = useState();
  const [currentSelected, setCurrentSelected] = useState();
  const [currentSelectedValues, setCurrentSelectedValues] = useState();
  const [suggestions, setSuggestions] = useState();
  const [values, setValues] = useState();
  const UiInputChipComp = useRef()

  useEffect(() => {
    const suggestions = [];
    const localCurrentSelected = [];
    const localCurrentSelectedValues = ['ciao', 'come'];
    let values = cloneDeep(props.values);

    if (props.nullable === true && props.values && props.values.length > 0) {
      values.push("null");
    }

    if (values && values.length > 0) {
      values.forEach((suggestion) => {
        let sugg = (props.fieldDisplayed) ? suggestion[props.fieldDisplayed] : suggestion;
        sugg = (!props.alreadyTranslated) ? i18n.t(`SelectValues.${sugg}`) : sugg;
        if (sugg) {
          suggestions.push(sugg);
        }
        if (props.value && ((props.fieldReturned && props.value.indexOf(suggestion[props.fieldReturned]) > -1) || props.value.indexOf(suggestion)) > -1) {
          localCurrentSelected.push(sugg);
          localCurrentSelectedValues.push(props.fieldReturned ? suggestion[props.fieldReturned] : suggestion);
        }
      });
    }

    setFieldDisplayed(props.fieldDisplayed);
    setFieldReturned(props.fieldReturned);
    setCurrentSelected(localCurrentSelected);
    setCurrentSelectedValues(localCurrentSelectedValues);
    setValues(values);

  }, [props])


  function handleOnChange(evt) {
    if (!props.disabled) {
      setCurrentSelectedValues(evt); 
      
      const event = {
        target: {
          name: props.name,
          currentSelectedValues,
        },
      };

      if (props.onChange) props.onChange(event);
      if (props.onBlur) props.onBlur(event);
    }
  }

  function removeTag(value) {
  }

  function handleOnFocus(evt) {
    if (!props.disabled && props.onFocus) {
      props.onFocus(evt);
    }
  }

  function handleOnBlur() {
    if (!props.disabled && props.onBlur) {
      props.onBlur();
    }
  }

  return (
    <I18n ns="translations">
      {t => (
        <div className="UiInputChip" ref={UiInputChipComp}>
          {props.label ? (
            <CFormLabel htmlFor={props.name} className="UiMainLabel">
              {t(props.label)} {props.required ? '*' : ''}
            </CFormLabel>
          ) : (
            ''
          )}
          {props.disabled && (
            <CFormInput type="text" className="UiInputChipDisabledPlaceholder" disabled
              value={props.placeholder ? t(props.placeholder) : `${t('Common.write')}...`} 
              onFocus={handleOnFocus.bind(this)}/>
          )}
          {!props.disabled && (
            <div className="UiContainerRelative">
              <Chips
                value={currentSelectedValues}
                onChange={handleOnChange.bind(this)}
                suggestions={suggestions}
                renderChip={value => <div style={{
                  display: 'none'
                }} >{value}</div>}
                uniqueChips={true}
                onFocus={handleOnFocus.bind(this)}
              />
              {!!currentSelectedValues && currentSelectedValues.length > 0 && currentSelectedValues.map((tag, key) => {
                return (
                  <Chip onRemove={removeTag.bind(this)} key={`input-chip-${this.props.name}-${key}`} onFocus={handleOnFocus.bind(this)}>
                    {tag}
                  </Chip>
                )
              })}
            </div>
          )
          }
        </div>
      )}
    </I18n>
  );
}

UiInputChip.propTypes = propTypes;

export default UiInputChip;
